import { useState, useRef, useEffect } from "react";

import { Transition } from "@headlessui/react";

import { ReactComponent as CogIcon } from "../icons/cog.svg";
import { ReactComponent as ChevronIcon } from "../icons/chevron.svg";
import useToken from "../hooks/useToken";

export function DropdownMenu() {
  const [activeMenu, setActiveMenu] = useState("main");
  const [menuHeight, setMenuHeight] = useState(null);
  const dropdownRef = useRef(null);

  const { removeToken } = useToken();

  useEffect(() => {
    setMenuHeight(dropdownRef.current?.firstChild.offsetHeight);
  }, []);

  function calcHeight(el) {
    const height = el.offsetHeight;
    setMenuHeight(height);
  }

  function DropdownItem(props) {
    return (
      <div
        className="menu-item h-[50px] flex items-center rounded-lg transition-all filter-none hover:bg-[#525357] cursor-pointer w-60 mx-4 text-[#dadce1] decoration-0"
        onClick={props.onClick}
      >
        <span className="icon-button mr-2 hover:filter-none">
          {props.leftIcon}
        </span>
        {props.children}
        {/* <span className="icon-right ml-auto">{props.rightIcon}</span> */}
      </div>
    );
  }

  return (
    <div
      className="dropdown absolute flex justify-center items-center top-20 right-12 w-64 h-32 box-border transform bg-[#242526] border border-[#474a4d] rounded-lg"
      // style={{ height: menuHeight }}
      ref={dropdownRef}
    >
      <Transition
        show={activeMenu === "main"}
        timeout={500}
        // classNames="menu-primary "
        unmountOnExit
        // onEnter={calcHeight}
        onClick={calcHeight}
        // enter={"bg-white"}
      >
        <div className="menu w-full">
          <DropdownItem>My Profile</DropdownItem>
          <DropdownItem
            leftIcon={<CogIcon />}
            rightIcon={<ChevronIcon />}
            // goToMenu="settings"
            onClick={() => {
              removeToken();
              window.location.reload();
            }}
          >
            Sign Out
          </DropdownItem>
        </div>
      </Transition>

      {/* <Transition
        show={activeMenu === "settings"}
        timeout={500}
        // classNames="menu-secondary"
        enter="absolute transform translate-x-10"
        unmountOnExit
        onClick={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="main" leftIcon={<ArrowIcon />}>
            <h2>My Tutorial</h2>
          </DropdownItem>
          <DropdownItem leftIcon={<BoltIcon />}>HTML</DropdownItem>
          <DropdownItem leftIcon={<BoltIcon />}>CSS</DropdownItem>
          <DropdownItem leftIcon={<BoltIcon />}>JavaScript</DropdownItem>
          <DropdownItem leftIcon={<BoltIcon />}>Awesome!</DropdownItem>
        </div>
      </Transition> */}
    </div>
  );
}
