import React from "react";
import Lottie from "react-lottie";

import whiteLoader from "../../images/white-loader.json";

const RegisterButton = ({ className, text, loading }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: whiteLoader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div
      className={`flex justify-center register-button ${
        loading ? "bg-gray-600" : ""
      } ${className}`}
    >
      {loading ? <Lottie options={defaultOptions} width={100} /> : text}
    </div>
  );
};
export default RegisterButton;
