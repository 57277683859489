import React from "react";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import ShadowButton from "../components/Buttons/ShadowButton";
import RegisterButton from "../components/Buttons/RegisterButton";
import authApi from "../api/auth";
import useApi from "../hooks/useApi";
import useToken from "../hooks/useToken";
import { useContext } from "react";
import AuthContext from "../utilities/context";
import { useEffect } from "react";

const Login = () => {
  const validate = Yup.object({
    email: Yup.string()
      .email("Email is invalid!")
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be of atleast 6 characters.")
      .required("Required"),
  });

  const { setToken } = useToken();

  const navigate = useNavigate();

  const { setUserData } = useContext(AuthContext);

  const { data, error, loading, request: login } = useApi(authApi.login);

  const submitHandler = (val) => {
    login(val);
    console.log(data);
  };

  useEffect(() => {
    if (!error && !loading && data) {
      setToken(data.token);
      setUserData(data.user._doc);
      // if (location.state?.from) {
      //   navigate(location.state.from);
      // } else
      navigate("/selectProfile", { replace: true });
    } else if (error && !loading) {
      alert(data);
    }
  }, [data, loading]);

  return (
    <>
      <div className="w-screen h-screen pb-4 background-image">
        <div className="h-[64px] flex justify-between">
          <Link to={"/"}>
            <div className="flex w-full h-8 gap-2 mx-3 my-6 text-2xl font-bold text-yellow-500 sm:gap-1 sm:my-4 sm:h-10 sm:text-3xl sm:mx-8 font-heading">
              <img
                src={require("../images/logo.png")}
                alt=""
                className="h-full text-yellow-500 "
              />
              PREMIUMCORES
            </div>
          </Link>
          {/* <button>
            <ShadowButton
              className="mx-8 my-4 text-2xl text-white bg-yellow-700 w-[188px] h-9"
              text="Get Started"
            />
          </button> */}
        </div>
        <div>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={validate}
            onSubmit={submitHandler}
          >
            {(formik) => (
              <div className="sm:w-[408px] xs:mx-10 mx-5 sm:mx-auto text-white signup-box my-10 xs:my-[20vh] sm:my-[100px] rounded p-4">
                <p className="text-2xl font-bold tracking-tighter lg:text-5xl sm:text-3xl md:text-4xl font-heading">
                  LOGIN{" "}
                </p>
                <Form className="field-group">
                  <div className="mt-5">
                    <label htmlFor="email" className="h-4 ">
                      Email
                    </label>
                  </div>
                  <div className="">
                    <Field
                      className="w-[100%] h-[56px] input-field"
                      type="email"
                      name="email"
                      placeholder="Enter your Email"
                    />
                  </div>
                  <ErrorMessage
                    name="email"
                    className="text-xs text-red-400 sm:text-sm "
                    component="div"
                  />
                  <div>
                    <label htmlFor="password" className="h-4">
                      Password
                    </label>
                  </div>
                  <div>
                    <Field
                      className="w-[100%] h-[56px] input-field"
                      name="password"
                      type="password"
                      placeholder="Enter your password"
                    />
                  </div>
                  <ErrorMessage
                    name="password"
                    className="text-xs text-red-400 sm:text-sm "
                    component="div"
                  />
                  {error && (
                    <p className="text-xs text-red-600 sm:text-sm">{data}</p>
                  )}
                  <div className="flex justify-end ">
                    <Link className="mx-2 mt-1 text-sm" to={"/reset-password"}>
                      Forgot password ?
                    </Link>
                  </div>
                  <button
                    type="Submit"
                    className="w-[100%] mt-4  hover:scale-100 duration-200"
                    disabled={loading}
                    onClick={() => {
                      if (formik.errors.email) {
                        alert("please enter a valid email");
                      } else if (formik.errors.password) {
                        alert("please enter password");
                      }
                    }}
                  >
                    <RegisterButton
                      className="h-12 p-2 text-lg font-semibold"
                      text="LOG IN"
                      loading={loading}
                    />
                  </button>

                  <div className="mt-4 text-center">
                    Don't have an account?{" "}
                    <Link to="/signup" className="text-[#FFA800]">
                      Sign up
                    </Link>
                  </div>

                  <div className="text-center">
                    <p className="my-2">Or</p>
                    <Link
                      to="/guest"
                      className="px-3 py-1 text-sm rounded-md bg-dark-600"
                    >
                      Login as a <span> Guest</span>
                    </Link>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default Login;
