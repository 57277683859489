import logo from "../images/logo.png";

export async function displayRazorpay({
  orderData,
  userData,
  currentStep,
  setCurrentStep,
  setLoading,
  sendBuyerDepositStatus,
}) {
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  // const __DEV__ = document.domain === "localhost";

  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }

  const options = {
    key: "rzp_test_fhWDSrgVbCx5gs",
    currency: orderData.currency,
    amount: orderData.amount.toString(),
    order_id: orderData.id,
    name: "Premium Cores",
    description: "Thank you for nothing. Please give us some money",
    image: { logo },
    handler: function (response) {
      setCurrentStep(currentStep + 1);
      setLoading(true);
      sendBuyerDepositStatus();
    },
    prefill: {
      name: userData.firstName + " " + userData.lastName,
      email: userData.email,
      // contact: userData.phoneNumber,
    },
    theme: {
      color: "#FFA800",
    },
  };
  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
}
