import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdKeyboardBackspace } from "react-icons/md";
import { ImUserTie } from "react-icons/im";
import { useContext } from "react";

import AuthContext from "../utilities/context";
import RegisterButton from "../components/Buttons/RegisterButton";

const SelectProfile = () => {
  const user = [
    { num: 0, profile: "BUYER", path: "/explore" },
    { num: 1, profile: "SELLER", path: "/sellerdashboard" },
    // { num: 2, profile: "Visitor", path: "/BXRoom" },
  ];
  const [number, setNumber] = useState();

  const { setIsSeller } = useContext(AuthContext);

  const Continue = ({ user }) => {
    if (user.profile === "SELLER") {
      localStorage.setItem("isSeller", true);
      setIsSeller(true);
    } else if (user.profile === "BUYER") {
      localStorage.setItem("isSeller", false);
    }
  };

  return (
    <>
      <div className="w-screen min-h-screen px-8 h-fit background-image">
        {/* <div className=" body bg-gradient-to-l from-[#290000] bg-[#0c0000] dark:background-image w-full h-screen px-8"> */}
        {/* <div className="">
          <Link className="font-light text-white logo font-body" to="/">
            Premium<span className="font-bold">Core</span>
          </Link>
        </div> */}
        <div className="h-[64px] flex justify-between">
          <div className="flex w-full h-8 gap-2 my-4 text-2xl font-bold text-yellow-500 sm:gap-1 sm:h-10 sm:text-3xl font-heading">
            <img
              src={require("../images/logo.png")}
              alt=""
              className="h-full text-yellow-500 "
            />
            <p className="scale-x-95 scale-y-125 ">PREMIUMCORES</p>
          </div>
        </div>
        <div className=" sm:mt-[5vh]">
          {/* <div className="flex text-white ">
            <button>
              <MdKeyboardBackspace className="relative left-0 text-2xl" />
            </button>
            <p className="m-auto text-3xl text-center"> SELECT YOUR PROFILE</p>
          </div> */}
          <div className="px-3 py-10 text-center sm:mt-10 text-white-grey">
            <div className="grid items-center mx-auto sm:grid-cols-2 lg:w-4/5 xl:w-4/6 gap-x-5 md:gap-x-10 lg:gap-x-20 gap-y-6 ">
              {user.map((user) => {
                return (
                  // <div key={user.num} className="text-center">
                  //   <button
                  //     className={`p-5 border-8 border-yellow-800 hover:border-yellow-600 ${
                  //       number === user.num
                  //         ? "border-yellow-600 text-yellow-600"
                  //         : ""
                  //     }`}
                  //     onClick={() => {
                  //       setNumber(user.num);
                  //       setUpdate(!updated);
                  //     }}
                  //   >
                  //     <ImUserTie
                  //       size={120}
                  //       className={`m-auto text-yellow-800 text-8xl hover:text-yellow-600 ${
                  //         number === user.num
                  //           ? "border-yellow-600 text-yellow-600"
                  //           : ""
                  //       }`}
                  //     />
                  //   </button>

                  //   <h2>{user.profile}</h2>
                  // </div>
                  <div
                    key={user.num}
                    className="p-4 duration-200 sm:p-6 md:p-8 lg:p-10 login-div hover:scale-95"
                  >
                    <div className="flex justify-center py-6 sm:py-10 ">
                      <img
                        src={require("../images/Icon.png")}
                        alt=""
                        className="w-24 h-24 lg:w-36 lg:h-36"
                      />
                    </div>
                    <h2 className="text-2xl font-extrabold text-left uppercase scale-y-125 sm:text-3xl md:text-4xl font-heading">
                      {user.profile}
                    </h2>
                    <p className="px-1 py-2 text-sm text-left sm:text-base ">
                      A hub for all gaming accounts with secure and reliable
                      marketplace for selling and buying game accounts.
                    </p>
                    <Link to={user.path}>
                      <button
                        className="w-full mt-3 duration-200 hover:scale-100"
                        onClick={() => {
                          setNumber(user.num);
                          Continue({ user });
                        }}
                      >
                        <RegisterButton
                          text={`Proceed as ${user.profile}`}
                          className="py-2 font-medium duration-300 bg-transparent border-2 border-yellow-500 border-solid hover:bg-yellow-500"
                        />
                      </button>
                    </Link>
                  </div>
                );
              })}
            </div>
            {/* <button
              className="p-3 mt-16 border-4 border-yellow-800 hover:border-yellow-600 rounded-3xl"
              onClick={() => Continue({ user, number })}
            >
              Continue
            </button> */}
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default SelectProfile;
