import client from "./client";

const sendOTP = (data) => client.post("/agent/sendOtp", data);

const signup = (data) => client.post("/agent/register", data);

const login = (data) => client.post("/agent/login", data);

const getAgent = () => client.get("/agent/auth");

const getReferralCode = () => client.get("/agent/referral");

const verifyReferral = (data) => client.post("/agent/verifyReferral", data);

const getEarnings = () => client.get("/agent/earnings");

export default {
  sendOTP,
  signup,
  login,
  getAgent,
  getReferralCode,
  verifyReferral,
  getEarnings,
};
