import React, { useState, useRef, useEffect, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import { MdOutlineNotifications, MdContentCopy } from "react-icons/md";
import { io } from "socket.io-client";

import ChangeID from "./ChangeID";
import SDepositing from "./SDepositing";
import STermsOfService from "./STermsOfService";
import FetchID from "./FetchID";
import BuyerStep from "./BuyerStep";
import PaymentRecieved from "./PaymentRecieved";
import Stepper from "../../screens/AccountModal/Stepper";
import Notification from "../../screens/components/Notification";
import AuthContext from "../../utilities/context";
import useApi from "../../hooks/useApi";
import userDetailsApi from "../../api/userDetails";
import Loader from "../../screens/components/Loader";
import RegisterButton from "../../components/Buttons/RegisterButton";
import roomApi from "../../api/room";
import GuestDetails from "../../screens/Guest/GuestDetails";

const SXRoom = () => {
  const { id } = useParams();
  const [seller, setSeller] = useState({});
  const [guestId, setGuestId] = useState();
  const [isCopied, setIsCopied] = useState(false);
  const [referral, setReferral] = useState();

  const handleCopylink = async () => {
    try {
      await navigator.clipboard.writeText(
        state.link
          ? state.link
          : __DEV__
          ? `http://localhost:3000/sxroom/${room._id}`
          : `https://pc-website.onrender.com/sxroom/${room._id}`
      );
      setIsCopied(true);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  const [showNotification, setShowNotification] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const steps = [
    "Sign contracts",
    "Buyer depositing funds",
    "Change ID & password",
    "Fetch and link credentials",
    "Buyer step",
    "Payment released",
  ];
  const [agree, setAgree] = useState(false);
  let [loading, setLoading] = useState(false);
  const [onlineXroomMembers, setOnlineXroomMembers] = useState();

  const { userData } = useContext(AuthContext);

  const { state } = useLocation();

  const socket = useRef();

  const { data: secondUser, request: getSecondUser } = useApi(
    userDetailsApi.getUserDetails
  );

  const {
    data: room,
    error,
    loading: fetching,
    request: getRoom,
  } = useApi(roomApi.getRoom);

  useEffect(() => {
    if (id) {
      getRoom(id);
    }
  }, []);
  useEffect(() => {
    setSeller(room?.seller);
    if (room?.guestId) {
      setGuestId(room.guestId);
    }
  }, [room?.seller]);

  const [time, setTime] = useState(0);

  const __DEV__ = process.env.NODE_ENV === "development";

  useEffect(() => {
    if (userData) {
      // socket.current = io("http://localhost:8000");
      // socket.current = io("https://pc-web-server.onrender.com");
      socket.current = io(
        __DEV__ ? "http://localhost:8000" : "https://pc-web-server.onrender.com"
      );
      socket.current.on("connect", () =>
        console.log("Connected to " + socket.current.id)
      );

      socket.current.emit("new-xroom-member-add", userData._id);
      socket.current.on("get-xroom-members", (users) => {
        setOnlineXroomMembers(users);
        console.log(users);
      });
      socket.current.on("receive-buyer-deposit-status", (data) => {
        if (data.status === "money_deposited") {
          setCurrentStep(3);
          setLoading(false);
        }
      });
      // socket.current.on("receive-bid-proposal", (data) => {
      //   setReceiveBidProposal(data);
      // });
    }
    if (state?.room !== undefined || room) {
      // socket.current = io("http://localhost:8000");
      // socket.current = io("https://pc-web-server.onrender.com");
      socket.current = io(
        __DEV__ ? "http://localhost:8000" : "https://pc-web-server.onrender.com"
      );
      socket.current.on("connect", () =>
        console.log("Connected to " + socket.current.id)
      );
      if (seller || room?.seller) {
        socket.current.emit(
          "new-xroom-member-add",
          state ? state.room.seller.sellerId : room.seller.sellerId
        );
      }
      socket.current.on("get-xroom-members", (users) => {
        setOnlineXroomMembers(users);
        console.log(users);
      });
      socket.current.on("receive-buyer-deposit-status", (data) => {
        if (data.status === "money_deposited") {
          setCurrentStep(3);
          setLoading(false);
        }
      });
      socket.current.on("receive-guest-details", (data) => {
        console.log("guest recieved : ", data);
        setGuestId(data.data);
      });
      // socket.current.on("receive-bid-proposal", (data) => {
      //   setReceiveBidProposal(data);
      // });
    }
  }, [userData, room, seller]);
  const sendSellerData = ({ xroom }) => {
    setSeller(xroom.seller);
    console.log("sending seller data", seller);

    socket.current.emit("new-xroom-member-add", xroom.sellerId);

    socket.current.emit("send-seller-details", {
      data: xroom,
      receiverId: room.guestId,
    });
  };

  useEffect(() => {
    let interval = null;

    interval = setInterval(() => {
      setTime((time) => time + 10);
    }, 10);

    return () => {
      clearInterval(interval);
    };
  }, []);

  function Timer(props) {
    return (
      <div className="timer">
        <span className="digits">
          {("0" + Math.floor((props.time / 60000) % 60)).slice(-2)}:
        </span>
        <span className="digits">
          {("0" + Math.floor((props.time / 1000) % 60)).slice(-2)}
        </span>
      </div>
    );
  }

  useEffect(() => {
    if (state && !state.room?.seller) {
      getSecondUser(state._doc.senderId);
    }
  }, []);

  const checkOnlineStatus = (secondUserId) => {
    const online = onlineXroomMembers?.find(
      (user) => user.userId === secondUserId
    );
    return online ? true : false;
  };

  const displayStep = (step) => {
    switch (step) {
      case 1:
        return (
          <STermsOfService
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            setAgree={setAgree}
            setLoading={setLoading}
          />
        );

      case 2:
        return (
          <SDepositing
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            loading={loading}
            setLoading={setLoading}
            price={state?.price ? state.price : room.amount}
          />
        );

      case 3:
        return (
          <ChangeID currentStep={currentStep} setCurrentStep={setCurrentStep} />
        );

      case 4:
        return (
          <FetchID currentStep={currentStep} setCurrentStep={setCurrentStep} />
        );

      case 5:
        return (
          <BuyerStep
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        );

      case 6:
        return (
          <PaymentRecieved
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        );

      default:
    }
  };

  if (!secondUser && !room) return <Loader />;

  if (!secondUser && !room.seller) {
    return <GuestDetails room={room} sendSellerData={sendSellerData} />;
  }

  return (
    <div className="relative ">
      <div className="mx-5 text-white md:mx-20">
        <div className="flex justify-between h-16 py-4">
          <div>
            <img
              src={require("../../images/logo.png")}
              alt="premiumcores"
              className="w-10 "
            />
          </div>
          <div>
            <button
              onClick={() => {
                setShowNotification(!showNotification);
              }}
            >
              <MdOutlineNotifications className="relative w-8 h-8" />
            </button>
          </div>
        </div>
        {showNotification && (
          <div className="absolute top-0 z-50 -ml-5 text-white sm:relative">
            <Notification setShowNotification={setShowNotification} />
          </div>
        )}
        <div className="mt-4">
          {state?.link && (
            <>
              <div className="text-sm text-yellow-600 ">
                copy link below and share to buyer
              </div>
              <div className="flex justify-between px-3 py-1 bg-gray-900">
                <p className="w-[60vw] overflow-x-scroll sm:w-full scrollbar-hide">
                  {state.link}
                </p>
                <button onClick={handleCopylink} className="flex">
                  <span className="p-1 ">
                    <MdContentCopy />
                  </span>
                  {isCopied ? "Copied!" : "Copy"}
                </button>
              </div>
            </>
          )}
          <h1 className="text-3xl font-semibold text-center sm:text-4xl">
            X-room
          </h1>
          {room === "undefined" && (
            <div>
              <label htmlFor="referral">Referral Code : </label>
              <input
                type="text"
                name="referral"
                value={referral}
                placeholder="Referral code if any"
                className="input-field"
                onChange={(e) => {
                  setReferral(e.target.value);
                }}
              />
            </div>
          )}
          <div className="mt-4 lg:flex lg:flex-row gap-x-7">
            <div className="w-full p-3 mb-2 schedule-div lg:w-1/4">
              <p className="font-semibold ">Attendees</p>
              <div className="flex w-full overflow-x-scroll scrollbar gap-x-2 lg:block ">
                <div className="p-3 mt-3 border-2 rounded-lg bg-black-800 border-black-700 solid">
                  <div className="flex flex-row space-x-1">
                    <p>Time elapsed - </p>
                    <Timer time={time} />
                  </div>

                  <div className="flex justify-center mt-1 sm:mt-7">
                    <div className="w-20 h-20 mb-2 bg-white rounded-full lg:w-32 md:w-24 md:h-24 lg:h-32 ">
                      <img
                        src={require("../../images/seller.png")}
                        alt="image"
                        className="rounded-full"
                      />
                    </div>
                  </div>
                  <div className="flex flex-row justify-between">
                    {!room && userData ? (
                      <p className="pb-1">
                        {userData.firstName + " " + userData.lastName}
                      </p>
                    ) : (
                      <p>{seller?.sellerId}</p>
                    )}
                    <div className="flex flex-row w-24 h-8 gap-1 px-3 my-auto ml-2 text-success online">
                      <div className="w-2 h-2 my-auto rounded-full bg-success"></div>
                      <p className="pt-1 text-sm">Online</p>
                    </div>
                  </div>
                </div>
                <div className="p-3 min-w-[180px] mt-3 border-2 rounded-lg bg-black-800 border-black-700 solid ">
                  <div className="flex flex-row space-x-1">
                    <p>Time elapsed - </p>
                    <Timer time={time} />
                  </div>
                  <div className="flex justify-center mt-1 sm:mt-7">
                    <div className="w-20 h-20 mb-2 bg-white rounded-full lg:w-32 md:w-24 md:h-24 lg:h-32 ">
                      <img
                        src={require("../../images/seller.png")}
                        alt="image"
                        className="rounded-full"
                      />
                    </div>
                  </div>
                  <div className="flex flex-row justify-between">
                    {!room && userData ? (
                      <>
                        <p className="pb-1">{secondUser.name}</p>
                        {checkOnlineStatus(state._doc.senderId) && (
                          <div className="flex flex-row gap-1 px-3 ml-2 text-success online">
                            <div className="w-2 h-2 my-auto rounded-full bg-success"></div>
                            <p>Online</p>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {!guestId && !room.guestId && (
                          <p className="w-full text-center text-red-600 ">
                            ask buyer to join
                          </p>
                        )}

                        {guestId && guestId}
                        {checkOnlineStatus(
                          guestId ? guestId : room.guestId
                        ) && (
                          <div className="flex flex-row gap-1 px-3 ml-2 text-success online">
                            <div className="w-2 h-2 my-auto rounded-full bg-success"></div>
                            <p>Online</p>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full p-3 mb-2 lg:w-3/4 schedule-div">
              <div className="w-full pb-2 mt-4 sm:px-5 md:px-10">
                <Stepper steps={steps} currentStep={currentStep} />
              </div>

              <div className="px-3 py-2 border-2 rounded-lg lg:py-4 sm:mt-16 bg-black-800 border-dark-700 md:h-fit solid h-96">
                {displayStep(currentStep)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SXRoom;
