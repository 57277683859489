import { useLocation, Navigate, Outlet, useParams } from "react-router-dom";

import useToken from "../hooks/useToken";

export function ProtectedRoutes2({ children }) {
  const { token } = useToken();
  const { id } = useParams();
  console.log(id);

  const location = useLocation();
  console.log(token);

  return token || id ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  );
}
