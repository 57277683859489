import client from "./client";

const getSchedules = (isSeller) =>
  client.get(`/schedule`, { isSeller: isSeller });

const proposeSchedule = (data) => client.post("/schedule", data);

const acceptSchedule = (scheduleId) =>
  client.put(`/schedule/accept/${scheduleId}`);

const withdrawSchedule = (scheduleId, isSeller) =>
  client.delete(`/schedule/withdraw/${scheduleId}/${isSeller}`);

const updateSchedule = ({ scheduleId, isSeller, proposedSchedule }) =>
  client.put(`/schedule/reschedule/${isSeller}`, {
    proposedSchedule,
    scheduleId,
  });

export default {
  getSchedules,
  proposeSchedule,
  acceptSchedule,
  withdrawSchedule,
  updateSchedule,
};
