import React, { useState } from "react";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import ShadowButton from "../components/Buttons/ShadowButton";
import RegisterButton from "../components/Buttons/RegisterButton";
import authApi from "../api/auth";
import useApi from "../hooks/useApi";
import useToken from "../hooks/useToken";
import { useContext } from "react";
import AuthContext from "../utilities/context";
import { useEffect } from "react";

const Login = () => {
  const [otp, setOtp] = useState();

  const { setToken } = useToken();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { setUserData } = useContext(AuthContext);

  const {
    data,
    error,
    loading,
    request: verifyOtp,
  } = useApi(authApi.verifyOtp);

  const submitHandler = () => {
    verifyOtp({ otp, email: state.email });
    console.log({ otp, email: state.email });
  };

  useEffect(() => {
    if (!error && !loading && data) {
      console.log(data);
      setToken(data.token);
      setUserData(data.user);
      if (state?.from) {
        navigate(state.from, { replace: true });
      } else navigate("/selectProfile", { replace: true });
    }
  }, [data]);

  // useEffect(() => {
  //   if (!state) return navigate("/", { replace: true });
  // }, []);

  return (
    <>
      <div className="w-screen h-screen pb-4 background-image">
        <div className="h-[64px] flex justify-between">
          <div className="flex w-full h-8 gap-2 mx-3 my-6 text-2xl font-bold text-yellow-500 sm:gap-1 sm:my-4 sm:h-10 sm:text-3xl sm:mx-8 font-heading">
            <img
              src={require("../images/logo.png")}
              alt=""
              className="h-full text-yellow-500 "
            />
            PREMIUMCORES
          </div>
        </div>
        <div>
          <div className="sm:w-[408px] xs:mx-10 mx-5 sm:mx-auto text-white signup-box my-10 xs:my-[20vh] sm:my-[100px] rounded p-4">
            <p className="text-2xl font-bold tracking-tighter lg:text-5xl sm:text-3xl md:text-4xl font-heading">
              VERIFY OTP{" "}
            </p>
            <div className="mt-5 mb-10">
              <label htmlFor="email" className="h-4">
                Enter OTP sent to your email{" "}
                <span className="text-yellow-500">{state.email}</span>
              </label>
            </div>

            <div>
              <label htmlFor="password" className="h-4">
                OTP
              </label>
            </div>
            <div>
              <input
                className="w-[100%] h-[56px] input-field"
                name="otp"
                // type="password"
                placeholder="Enter OTP"
                onChange={(e) => setOtp(e.target.value)}
                value={otp}
              />
            </div>
            {error && (
              <p className="text-xs text-red-600 sm:text-sm">{data.message}</p>
            )}
            <button
              type="Submit"
              className="w-[100%] mt-4  hover:scale-100 duration-200"
              disabled={loading}
              onClick={submitHandler}
            >
              <RegisterButton
                className="h-12 p-2 text-lg font-semibold"
                text="Verify"
                loading={loading}
              />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
