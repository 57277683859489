import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import accountApi from "../../api/account";
import useApi from "../../hooks/useApi";
import agentApi from "../../api/agent";

const Confirmation = ({
  referral,
  sellerId,
  scheduleId,
  room,
  userData,
  data,
}) => {
  const [confirm, setConfirm] = useState(false);
  const [done, setDone] = useState(false);

  const {
    data: account,
    error,
    loading,
    request: transferAccount,
  } = useApi(accountApi.transferAccount);
  const {
    data: message,
    error: err,
    loading: verifying,
    request: verifyReferral,
  } = useApi(agentApi.verifyReferral);
  const confirmHandler = () => {
    if (!room._id && userData) {
      transferAccount({ sellerId, scheduleId });
      if (!loading && !error) {
        setConfirm(true);
        if (referral) {
          verifyReferral({
            referral,
            customer: {
              id: userData._id,
              amount: data?.price,
              name: userData.firtName + " " + userData.lastName,
              role: "Buyer",
            },
          });
        }
      } else return alert("Something went wrong");
    }
    if (room._id) {
      setConfirm(true);
    }
  };

  return (
    <div className="font-semibold ">
      <h3 className="hidden text-2xl text-center sm:block">
        {done ? "Done" : "Confirm and Release payment"}
      </h3>
      <div className="flex flex-col justify-center mt-1 text-center rounded md:h-[34vh] lg:h-80 h-[354px] px-2 sm:h-80 bg-dark-700">
        {!confirm && (
          <>
            <p>Click confirm if the account is working properly.</p>
            <div>
              <button
                className="px-10 py-2 mt-3 bg-yellow-500 rounded"
                onClick={confirmHandler}
              >
                Confirm
              </button>
            </div>
          </>
        )}
        {confirm && !done && (
          <>
            <p>Click here and release payment</p>
            <div>
              <button
                className="px-10 py-2 mt-3 bg-yellow-500 rounded"
                onClick={() => {
                  setDone(true);
                }}
              >
                Release payment
              </button>
            </div>
          </>
        )}
        {done && (
          <>
            <div>
              <Link to="/profile">
                <button
                  className="px-6 py-2 mt-3 bg-yellow-500 rounded"
                  type="button"
                >
                  Return to profile page
                </button>
              </Link>
            </div>
            <p className="mt-3 ">
              Payment released to the seller. Thanks for working with us. Click{" "}
            </p>
            <p>
              {" "}
              <a href="#" className="text-yellow-500 ">
                here
              </a>{" "}
              to download receipt
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default Confirmation;
