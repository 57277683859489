import client from "./client";

const sendOtp = (phoneNumber) => client.post("/auth/sendOTP", { phoneNumber });

const verifyOtp = (data) => client.post("/auth/verifyOTP", data);

const signup = (data) => client.post("/auth/register", data);

const login = (data) => client.post("/auth/login", data);

const getUser = () => client.get("/auth/user");

const resetPassword = (email) =>
  client.post("/auth/reset-password-link", { email });

const verifyResetLink = (data) => client.post("/auth/verify-reset-link", data);

const changePassword = (data) => client.put("/auth/change-password", data);

export default {
  sendOtp,
  verifyOtp,
  signup,
  login,
  getUser,
  resetPassword,
  verifyResetLink,
  changePassword,
};
