import React from "react";
import useApi from "../../../hooks/useApi";
import SelectField from "../../../utilities/SelectField";
import TextInput from "../../../utilities/TextInput";
import accountApi from "../../../api/account";
import { useEffect } from "react";
import { useState } from "react";
import { ErrorMessage, Field } from "formik";

const AccountDetails = ({ formik, selected }) => {
  const dropDownOptions = [
    { key: "Select a game", value: "" },
    { key: "Battle Grounds Mobile India", value: "BGMI" },
    { key: "PUBG", value: "PUBG" },
    // { key: "Free Fire", value: "FF" },
    // { key: "Valorant", value: "Valorant" },
  ];

  const [playerId, setPlayerId] = useState();
  const [playerName, setPlayerName] = useState();
  const [isFbTc, setIsFbTc] = useState(formik.values.fbtc);
  const [isTwTc, setIsTwTc] = useState(formik.values.twtc);
  const [isEmTc, setIsEmTc] = useState(formik.values.emtc);
  const [isMoTc, setIsMoTc] = useState(formik.values.motc);

  const {
    data,
    loading,
    error: playerNameError,
    request,
  } = useApi(accountApi.verifyId);

  const verifyPlayerIdHandler = () => {
    console.log(playerId);
    request({ playerId });
    console.log(data);
    if (loading) setPlayerName("loading...");
    if (!loading && !playerNameError) setPlayerName(data);
    else alert(playerNameError);
  };
  const handleKeyDown = (e) => {
    const { key } = e;
    if ((key >= 0 && key <= 9) || key === "Enter" || key === "Backspace") {
      formik.handleChange(e);
      if (key === "Enter") {
        const form = e.target.form;
        const index = Array.prototype.indexOf.call(form, e.target);
        console.log(index);
        form.elements[index + 1].focus();
      }
    } else {
      e.preventDefault();
    }
  };

  useEffect(() => {
    setPlayerId(formik.values.playerID);
  }, [formik.values.playerID]);

  return (
    <div className="overflow-y-scroll h-80 scrollbar xs:h-96">
      <div className="grid gap-x-3 sm:gap-y-6 gap-y-1 sm:grid-cols-2 xs:gap-x-3 sm:gap-x-7">
        <div>
          <SelectField label="Game" options={dropDownOptions} name="game" />
          <ErrorMessage
            className="text-red-400 error-message"
            name="game"
            component="div"
          />
        </div>
        <div>
          <label htmlFor="price">Price</label>
          <div className="flex flex-row input-field rounded-[2px]">
            <Field
              name="price"
              className="w-full bg-transparent"
              placeholder="Enter price"
              onKeyDown={handleKeyDown}
              value={formik.values.price}
            />
            <p className="px-3 text-no-new-message">INR</p>
          </div>
          <ErrorMessage
            className="text-red-400 error-message"
            name="price"
            component="div"
          />
        </div>
        <div>
          <label htmlFor="player ID">Player ID</label>
          <div className="flex flex-row input-field rounded-[2px]">
            <Field
              label="Player ID"
              name="playerID"
              placeholder="Enter Player ID"
              className="w-full bg-transparent"
              // onChange={handleKeyDown}
              onKeyDown={handleKeyDown}
              value={formik.values.playerID}
            />
            <button type="button" onClick={verifyPlayerIdHandler}>
              Verify
            </button>
          </div>
          <ErrorMessage
            className="text-red-400 error-message"
            name="playerID"
            component="div"
          />
        </div>
        <TextInput
          label="Ingame name"
          name="ingameName"
          placeholder="Enter Ingame name"
          value={playerName}
          disabled
        />
      </div>

      <div
        className={`${!selected ? "xl:grid xl:grid-cols-2" : ""} text-[14px]`}
      >
        <div>
          <div
            className={`flex flex-row justify-between ${
              !selected ? "xl:grid xl:grid-cols-2" : ""
            }`}
          >
            <div className="flex flex-row gap-2">
              <input
                type="checkbox"
                name="fbtc"
                checked={isFbTc}
                onChange={() => {
                  formik.values.fbtc = !formik.values.fbtc;
                  setIsFbTc(!isFbTc);
                }}
              />

              <div>
                <label htmlFor="fbtc">
                  <p>Facebook</p>
                  <p>
                    Read Our{" "}
                    <a href="#" className="text-[#04D8D8] underline">
                      Terms & Condition
                    </a>
                  </p>
                </label>
              </div>
            </div>
            <div className="flex flex-row gap-2">
              <input
                type="checkbox"
                name="twtc"
                checked={formik.values.twtc}
                onChange={() => {
                  formik.values.twtc = !formik.values.twtc;
                  setIsTwTc(!isTwTc);
                }}
              />
              <div>
                <label htmlFor="twtc">
                  <p>Twitter</p>
                  <p>
                    Read Our{" "}
                    <a href="#" className="text-[#04D8D8] underline">
                      Terms & Condition
                    </a>
                  </p>
                </label>
              </div>
            </div>
          </div>
          <div
            className={`flex flex-row justify-between ${
              !selected ? "xl:grid xl:grid-cols-2" : ""
            }`}
          >
            <div className="flex flex-row gap-2 mt-2 sm:mt-6">
              <input
                type="checkbox"
                name="emtc"
                checked={isEmTc}
                onChange={() => {
                  formik.values.emtc = !formik.values.emtc;
                  setIsEmTc(!isEmTc);
                }}
              />
              <div>
                <label htmlFor="emtc">
                  <p>Email</p>
                  <p>
                    Read Our{" "}
                    <a href="#" className="text-[#04D8D8] underline">
                      Terms & Condition
                    </a>
                  </p>
                </label>
              </div>
            </div>
            <div className="flex flex-row gap-2 mt-2 sm:mt-6">
              <input
                type="checkbox"
                name="motc"
                checked={isMoTc}
                onChange={() => {
                  formik.values.motc = !formik.values.motc;
                  setIsMoTc(!isMoTc);
                }}
              />
              <div>
                <label htmlFor="motc">
                  <p>Mobile</p>
                  <p>
                    Read Our{" "}
                    <a href="#" className="text-[#04D8D8] underline">
                      Terms & Condition
                    </a>
                  </p>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountDetails;
