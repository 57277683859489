import React from "react";
import { MdOutlineRemoveRedEye, MdArrowBack } from "react-icons/md";
import { HiOutlineClock } from "react-icons/hi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SimpleBar from "simplebar-react";

import useApi from "../../hooks/useApi";
import Loader from "../components/Loader";
import accountApi from "../../api/account";
import { useEffect } from "react";
import { useState } from "react";

const ScheduleAccountBuy = ({ id }) => {
  const [account, setAccount] = useState();

  const navigate = useNavigate();
  const { state } = useLocation();

  const {
    loading,
    data,
    request: getAccount,
  } = useApi(accountApi.getOneAccount);

  useEffect(() => {
    if (state) setAccount(state);
    else if (!state) getAccount(id);
  }, []);

  useEffect(() => {
    setAccount(data);
  }, [data]);

  useEffect(() => {
    setAccount(state);
  }, [state]);

  if (!account || loading) return <Loader />;
  return (
    <>
      <div className="mx-8 font-semibold text-white sm:mx-14 lg:mx-20 mt-7">
        <div className="flex justify-between ">
          <button
            type="button"
            className="px-3 py-3 mt-1 text-xl rounded-lg bg-neutral-700"
            onClick={() => {
              navigate(-1);
            }}
          >
            <MdArrowBack />
          </button>
          {/* <Link to={`/sellerdashboard/editaccount/${id}`}>
            <button className="px-10 py-3 bg-yellow-500 rounded" type="button">
              Edit details
            </button>
          </Link> */}
        </div>
        <div className="gap-8 mt-8 md:flex">
          <div className=" md:w-1/3">
            <div className="w-full h-60 sm:h-96">
              <img className="h-96" src={account.accountThumbnail} />
            </div>
          </div>

          <div className="sm:px-4 md:w-2/3 ">
            <div className="flex justify-between text-2xl ">
              <div className="flex gap-x-2">
                <p>{account.game}</p>
                <p className="flex text-xl text-dark-100">
                  21{" "}
                  <span className="mt-1 text-2xl">
                    <MdOutlineRemoveRedEye className="my-auto " />
                  </span>
                </p>
              </div>
              <div>
                <p>₹{account.price}</p>
              </div>
            </div>

            <SimpleBar
              className="pr-2 mt-3 mb-20 text-base scrollbar"
              autoHide={false}
            >
              <div>
                <h3 className="text-lg">Account details</h3>
                <div className="grid mt-3 lg:grid-cols-2 gap-x-20 gap-y-3">
                  <div className="grid grid-cols-2 gap-x-7">
                    <p className="font-normal text-neutral-500">Player ID </p>
                    <p className="overflow-x-scroll scrollbar-hide">
                      {account.playerID}
                    </p>
                  </div>
                  <div className="grid grid-cols-2 gap-x-7">
                    <p className="font-normal text-neutral-500">Ingame name </p>
                    <p className="overflow-x-scroll scrollbar-hide ">
                      {account.inGameName}
                    </p>
                  </div>
                  <div className="grid grid-cols-2 gap-x-7">
                    <p className="font-normal text-neutral-500">
                      Tenure of ownership{" "}
                    </p>
                    <p>
                      {account.ownershipTenure.years} years{" "}
                      {account.ownershipTenure.months} months
                    </p>
                  </div>
                  <div className="grid grid-cols-2 gap-x-7">
                    <p className="font-normal text-neutral-500">
                      Account level
                    </p>
                    <p>{account.accountLevel} level</p>
                  </div>
                  <div className="grid grid-cols-2 gap-x-7">
                    <p className="font-normal text-neutral-500">
                      Memorable frames
                    </p>
                    <div>
                      {account.memorableFrames.map((frame, index) => (
                        <>
                          <div>
                            {frame}
                            {index + 1 !== account.memorableFrames.length && (
                              <>,</>
                            )}{" "}
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-x-7">
                    <p className="font-normal text-neutral-500">
                      Memorable tiles
                    </p>
                    <div>
                      {account.memorableTitles.map((title, index) => (
                        <>
                          <div>
                            {title}
                            {index + 1 !== account.memorableTitles.length && (
                              <>,</>
                            )}{" "}
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <div className="flex flex-row gap-x-8">
                    <p className="font-normal text-neutral-500">
                      Inventory details{" "}
                    </p>
                    <p>{account.inventoryDetails}</p>
                  </div>
                  <div className="flex flex-row mt-3 gap-x-8">
                    <p className="font-normal text-neutral-500">
                      Special mentions
                    </p>
                    <p>{account.specialMention}</p>
                  </div>
                </div>
              </div>

              <div className="mt-3">
                <h3 className="mb-3 text-lg">Royal pass details</h3>
                {account.royalPassDetails.map((pass) => {
                  if (pass.title === "Levels") return;
                  return (
                    <div className="mb-1 sm:flex md:block lg:flex sm:flex-row">
                      <div className="sm:w-1/4 md:w-full lg:w-1/4 ">
                        <p className="font-normal text-neutral-500">
                          {pass.title}
                        </p>
                      </div>
                      <div className="flex min-h-[28px] flex-row overflow-x-scroll sm:w-3/4 gap-x-3 scrollbar-hide">
                        {pass.items.map((season, index) => {
                          return (
                            <div className="w-48 rounded-full levels-season px-14">
                              <div className="relative text-center">
                                {" "}
                                <img
                                  src={require("../../images/season.png")}
                                  className="absolute mt-2 -left-5"
                                />
                                <p className="w-20 pb-1 ">Season {season}</p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>

              {/* <div className="mt-3">
                <h3 className="mb-3 text-lg">Social linked</h3>
                <div className="w-full overflow-x-scroll">
                  <div className="flex flex-row md:block lg:flex gap-x-5">
                    <div className="w-1/4 ">
                      <p className="font-normal text-neutral-500">Facebook</p>
                    </div>
                    <div className="flex grid-cols-2 sm:grid sm:w-3/4 md:block md:w-full lg:w-3/4 lg:grid gap-x-3">
                      <div className="flex font-normal text-neutral-500">
                        Email ID -{" "}
                        <p className="ml-2 font-semibold text-white sm:overflow-x-scroll">
                          Premium23@gmail.com
                        </p>
                      </div>
                      <div className="flex ml-3 font-normal md:ml-0 text-neutral-500">
                        Password -{" "}
                        <p className="ml-2 font-semibold text-white">
                          password
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-row mt-3 lg:flex md:block gap-x-5">
                    <div className="w-1/4 md:w-full lg:w-1/4 ">
                      <p className="font-normal text-neutral-500">Twitter</p>
                    </div>
                    <div className="flex grid-cols-2 sm:grid sm:w-3/4 md:block md:w-full lg:w-3/4 lg:grid gap-x-3">
                      <div className="flex font-normal text-neutral-500">
                        Twitter ID -{" "}
                        <p className="ml-2 font-semibold text-white">Premium</p>
                      </div>
                      <div className="flex ml-3 font-normal md:ml-0 text-neutral-500">
                        Password -{" "}
                        <p className="ml-2 font-semibold text-white sm:overflow-x-scroll">
                          password
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </SimpleBar>
            {/* {!isSeller && (
              <div className="z-30 p-3 mt-1 text-center rounded-lg schedule-btn">
                <Link to={`/seller/schedule/schedulebuy/${id}`}>
                  <button className="flex justify-center w-full py-3 bg-yellow-500 rounded-lg">
                    Schedule the buy{" "}
                    <span className="py-1 text-xl">
                      {" "}
                      <HiOutlineClock />
                    </span>
                  </button>
                </Link>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ScheduleAccountBuy;
