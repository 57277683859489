import React, { useState } from "react";
import { Field } from "formik";
import { useDropzone } from "react-dropzone";
import * as Yup from "yup";
import { MdOutlineAddAPhoto, MdOutlineCancel } from "react-icons/md";

import Form from "../../components/forms/Form";
import Navbar from "../../components/Navbar";
import accountApi from "../../api/account";
import useApi from "../../hooks/useApi";
import { useNavigate } from "react-router-dom";
// import SocialAccounts from "../../components/SocialAccounts";
import { useRef } from "react";
import DetailsField from "../../components/forms/DetailsField";
import { DropMenu, Option } from "../../components/DropMenu/DropMenu";
import ArrayInput from "../../components/ArrayInput";
import Modal from "../../components/Modal";

const seasonArray = [
  { num: 1, isSelected: false },
  { num: 2, isSelected: false },
  { num: 3, isSelected: false },
  { num: 4, isSelected: false },
  { num: 5, isSelected: false },
  { num: 6, isSelected: false },
  { num: 7, isSelected: false },
];

const Accounts = () => {
  const accountValidationSchema = Yup.object().shape({
    alias: Yup.string().min(3).required(),
    // playerId: Yup.string().required("Please enter player ID."),
    playerName: Yup.string().required("Please enter player ID."),
    price: Yup.number().required("Please enter price."),
    months: Yup.number().required("Please enter number of months."),
    years: Yup.number().required("Please enter number of years."),
    accLevel: Yup.number().required("Please enter account level."),
    memorableFrames: Yup.array().required(),
    memorableTitles: Yup.array().required(),
    overallOverview: Yup.string().required(),
    specialMentions: Yup.string().required(),
  });

  const navigate = useNavigate();
  const fileInput = useRef();

  const [playerId, setPlayerId] = useState();
  const [playerName, setPlayerName] = useState();
  const [regularRpModalOpen, setRegularRpModalOpen] = useState(false);
  const [eliteRpModalOpen, setEliteRpModalOpen] = useState(false);
  const [elitePlusRpModalOpen, setElitePlusRpModalOpen] = useState(false);
  const [regularRpArray, setRegularRpArray] = useState([]);
  const [eliteRpArray, setEliteRpArray] = useState([]);
  const [elitePlusRpArray, setElitePlusRpArray] = useState([]);
  const [socialAccount, setSocialAccount] = useState(false);
  const [image1, setImage1] = useState();
  const [image2, setImage2] = useState();
  const [video, setVideo] = useState();
  const [isNextValid, setIsNextValid] = useState(false);
  const [game, setGame] = useState();
  const [titlesInput, setTitlesInput] = useState("");
  const [titlesArr, setTitlesArr] = useState([]);
  const [isTitlesKeyReleased, setIsTitlesKeyReleased] = useState(false);
  const [framesInput, setFramesInput] = useState("");
  const [framesArray, setFramesArray] = useState([]);
  const [isFramesKeyReleased, setIsFramesKeyReleased] = useState(false);
  const [updated, setupdate] = useState(false);

  // Deleting seasons from rpArray and adding to seasonArray

  //finding right position in season array to add season
  function findPos(value) {
    let pos;
    if (seasonArray.length > 1)
      pos = seasonArray.findIndex((current) => value < current.num);
    else {
      pos = seasonArray.length;
    }
    if (pos === -1) {
      return seasonArray.length;
    }
    return pos;
  }
  // positon found

  // season removal
  const removeFromArray = (num) => {
    let index = findPos(num);

    const newRegularArray = regularRpArray.filter(
      (current) => current.num !== num
    );
    setRegularRpArray(newRegularArray);

    const newEliteRpArray = eliteRpArray.filter(
      (current) => current.num !== num
    );
    setEliteRpArray(newEliteRpArray);

    const newElitePlusRpArray = elitePlusRpArray.filter(
      (current) => current.num !== num
    );
    setElitePlusRpArray(newElitePlusRpArray);

    seasonArray.splice(index, 0, { num: num, isSelected: false });
    setupdate(!updated);
  };
  //             end of deleton code

  const RpSeason = ({ seasonNum }) => {
    return (
      <div className="bg-[#4c0d0d] bg-opacity-80 shadow-md drop-shadow-md rounded py-1 text-[#e1e1e1] mt-2 flex flex-row justify-around">
        <p>Season {seasonNum}</p>
        <button
          type="button"
          onClick={() => {
            removeFromArray(seasonNum);
          }}
        >
          <MdOutlineCancel />
        </button>
      </div>
    );
  };

  const { getRootProps: getImg1RootProps, getInputProps: getImg1InputProps } =
    useDropzone({
      accept: "image/*",
      onDrop: (acceptedFiles) => {
        if (acceptedFiles[0].type.split("/")[0] !== "image")
          return alert("Please select an image.");
        else if (acceptedFiles[0].size > 5250000)
          return alert("Please select an image of size less than 5MB");

        setImage1(
          ...acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
    });

  const { getRootProps: getImg2RootProps, getInputProps: getImg2InputProps } =
    useDropzone({
      accept: "image/*",
      onDrop: (acceptedFiles) => {
        if (acceptedFiles[0].type.split("/")[0] !== "image")
          return alert("Please select an image.");
        else if (acceptedFiles[0].size > 5250000)
          return alert("Please select an image of size less than 5MB");

        setImage2(
          ...acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
    });

  const { getRootProps: getVidRootProps, getInputProps: getVidInputProps } =
    useDropzone({
      accept: "video/*",
      onDrop: (acceptedFiles) => {
        if (acceptedFiles[0].type.split("/")[0] !== "video")
          return alert("Please select a video.");
        else if (acceptedFiles[0].size > 10500000)
          return alert("Please select a video of size upto 10MB");

        setVideo(
          ...acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
    });

  const { error, request: addAccount } = useApi(accountApi.postAccount);

  const {
    data,
    loading,
    error: playerNameError,
    request,
  } = useApi(accountApi.verifyId);

  const verifyPlayerIdHandler = () => {
    request({ playerId });
    console.log(data);
    if (loading) setPlayerName("loading...");
    if (!loading && !playerNameError) setPlayerName(data);
    else alert(playerNameError);
  };

  const submitHandler = ({
    alias,
    // playerId,
    // playerName,
    price,
    fb,
    twitter,
    email,
    phone,
    months,
    years,
    accLevel,
    memorableFrames,
    memorableTitles,
    overallOverview,
    specialMentions,
  }) => {
    console.log(game);
    if (!game || game === "") return;

    if (!socialAccount) {
      // setIsNextValid(true);
      return setSocialAccount(true);
    }

    const data = {
      game,
      alias,
      playerId,
      playerName,
      price,
      fb,
      twitter,
      email,
      phone,
      ownershipTenure: {
        years,
        months,
      },
      royalPassDetails: {
        regular: regularRpArray,
        elite: eliteRpArray,
        elitePlus: elitePlusRpArray,
      },
      accLevel,
      memorableFrames,
      memorableTitles,
      overallOverview,
      specialMentions,
    };

    addAccount(data);
    if (!error) return navigate("/");
  };

  return (
    <>
      <div className="h-screen">
        <Navbar isSeller />
        <div className="body overflow-hidden justify-between w-full md:box-border bg-gradient-to-l from-[#290000] bg-[#0c0000] h-full px-6 md:px-12 pb-10 font-body ">
          <Form
            initialValues={{
              // playerId: "",
              // playerName: "",
              price: "",
              fb: false,
              twitter: false,
              email: false,
              phone: false,
              months: "",
              years: "",
              accLevel: "",
              overallOverview: "",
              specialMentions: "",
            }}
            validationSchema={accountValidationSchema}
            onSubmit={submitHandler}
          >
            <div className="flex items-center justify-between w-full px-4 py-2 my-4 text-white bg-white rounded bg-opacity-20">
              <div className="flex w-full border-none">
                <div
                  className="mr-5 duration-300 hover-underline-animation hover:cursor-pointer hover:scale-105"
                  onClick={() => setSocialAccount(false)}
                >
                  <p className={`mb-1`}>Account Details</p>
                  {!socialAccount && (
                    <hr className="w-[110%] h-[2px] left-[-5%] bottom-0 absolute bg-white" />
                  )}
                </div>
                <div>
                  <p className="mb-1 ">Social Accounts</p>
                  {socialAccount && <hr className="w-full h-[2px] bg-white" />}
                  {/* fix the underline width */}
                </div>
              </div>
              <button type="submit" className="px-3 py-2 bg-green-800 rounded">
                {isNextValid && socialAccount ? "Save" : "Next"}
              </button>
            </div>
            {socialAccount ? (
              <div className="flex w-full h-[73.2vh]">
                <div className="w-[50%] mr-4 ">
                  <div className="h-[48.5%] rounded bg-white bg-opacity-20 p-4">
                    <h1 className="mb-2 text-lg font-medium text-white">
                      Facebook
                    </h1>
                    <DetailsField
                      label="Username"
                      fullWidth
                      name="fbUsername"
                    />
                    <DetailsField
                      label="Password"
                      fullWidth
                      name="fbPassword"
                    />
                    <div className="flex justify-between mt-5">
                      <div>
                        <div className="flex label-text-gradient">
                          <Field
                            type="checkbox"
                            className="mr-2 "
                            name="fbTFA"
                            id=""
                          />
                          <p>Second Factor Authentication</p>
                        </div>
                        <p className="text-sm text-white opacity-50 font-regular">
                          (We will remind you) Need to disable while
                          transactions happens.
                        </p>
                      </div>
                      <button
                        type="submit"
                        className="px-3 py-2 text-white bg-green-600 rounded h-fit"
                      >
                        Verify
                      </button>
                    </div>
                  </div>
                  <div className="h-[48.5%] rounded mt-5 bg-white bg-opacity-20 p-4">
                    <h1 className="mb-2 text-lg font-medium text-white">
                      Twitter
                    </h1>
                    <DetailsField
                      label="Username"
                      fullWidth
                      name="twitterUsername"
                    />
                    <DetailsField
                      label="Password"
                      fullWidth
                      name="twitterPassword"
                    />
                    <div className="flex justify-between mt-5">
                      <div>
                        <div className="flex label-text-gradient">
                          <Field
                            type="checkbox"
                            className="mr-2 "
                            name="twitterTFA"
                            id=""
                          />
                          <p>Second Factor Authentication</p>
                        </div>
                        <p className="text-sm text-white opacity-50 font-regular">
                          (We will remind you) Need to disable while
                          transactions happens.
                        </p>
                      </div>
                      <button
                        type="submit"
                        className="px-3 py-2 text-white bg-green-600 rounded h-fit"
                      >
                        Verify
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-center w-[50%] text-white bg-white bg-opacity-20 rounded px-10">
                  <h1 className="text-3xl font-semibold text-center">
                    Summary
                  </h1>
                  <div className="mt-10">
                    <h2 className="mb-2 text-lg font-medium underline ">
                      Facebook
                    </h2>
                    <div className="flex ">
                      <div className="mr-2 space-y-3">
                        <p>Username</p>
                        <p>Password</p>
                        <p>Status</p>
                      </div>
                      <div className="space-y-3">
                        <p>: someusername</p>
                        <p>: somepassword</p>

                        <p className="">
                          : <span className="text-red-600">Pending</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-10">
                    <h2 className="mb-2 text-lg font-medium underline ">
                      Twitter
                    </h2>
                    <div className="flex ">
                      <div className="mr-2 space-y-3">
                        <p>Username</p>
                        <p>Password</p>
                        <p>Status</p>
                      </div>
                      <div className="space-y-3">
                        <p>: someusername</p>
                        <p>: somepassword</p>
                        <p className="">
                          : <span className="text-green-500">Verified</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex w-full">
                <div className="w-[50%] mr-4 overflow-y-scroll p-4 bg-white bg-opacity-20 rounded h-[73.2vh]">
                  <h1 className="mb-2 text-lg font-medium text-white">
                    Account Details
                  </h1>
                  <DropMenu
                    formLabel="Game"
                    onChange={(e) => setGame(e.target.value)}
                  >
                    <Option value="PUBG" />
                    <Option value="BGMI" />
                  </DropMenu>
                  <DetailsField name="alias" label="Alias" />
                  <div className="flex items-end">
                    <DetailsField
                      name="playerId"
                      label="Player ID"
                      onChange={(e) => {
                        setPlayerId(e.target.value);
                      }}
                      value={playerId}
                      fullWidth
                    />
                    <button
                      type="button"
                      className="px-2 py-1 text-white bg-green-800 rounded h-fit ml-3 hover:brightness-125 duration-200"
                      onClick={verifyPlayerIdHandler}
                    >
                      Verify
                    </button>
                  </div>
                  <DetailsField
                    name="playerName"
                    label="Player Name"
                    value={playerName}
                    fullWidth
                    disabled
                  />
                  <DetailsField name="price" price label="Price" />

                  <div className="mt-10 text-white">
                    <h1 className="mb-2 text-lg font-medium text-white">
                      Linked Accounts
                    </h1>
                    <div className="space-y-3">
                      <div className="flex ">
                        <Field type="checkbox" name="fb" id="" />
                        <div className="ml-3 mr-6">
                          <h2>Facebook</h2>
                          <p>
                            Read our{" "}
                            <span className="text-blue-700 underline hover:cursor-pointer">
                              Terms and Conditions
                            </span>
                          </p>
                        </div>
                        <Field type="checkbox" name="email" id="" />
                        <div className="ml-3">
                          <h2>Email</h2>
                          <p>
                            Read our{" "}
                            <span className="text-blue-700 underline hover:cursor-pointer">
                              Terms and Conditions
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="flex">
                        <Field type="checkbox" name="twitter" id="" />
                        <div className="ml-3 mr-6">
                          <h2>Twitter</h2>
                          <p>
                            Read our{" "}
                            <span className="text-blue-700 underline hover:cursor-pointer">
                              Terms and Conditions
                            </span>
                          </p>
                        </div>
                        <Field type="checkbox" name="phone" id="" />
                        <div className="ml-3">
                          <h2>Phone</h2>
                          <p>
                            Read our{" "}
                            <span className="text-blue-700 underline hover:cursor-pointer">
                              Terms and Conditions
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h1 className="mb-2 text-lg font-medium text-white mt-9">
                      Inventory Details
                    </h1>
                    <div className="flex ">
                      <div>
                        <h2 className="text-[#e2e2e2]">Tenure of Ownership</h2>
                        <div className="flex items-center justify-center space-x-3">
                          <DetailsField name="years" num />
                          <span className="mt-4 ml-2 text-white">Years</span>
                          <DetailsField name="months" num />
                          <span className="mt-4 ml-2 text-white">Months</span>
                        </div>
                      </div>
                      <div className="ml-16">
                        <h2 className="text-[#e2e2e2]">Account Level</h2>
                        <div className="flex items-center justify-center space-x-3">
                          <DetailsField name="accLevel" num />
                          <span className="mt-4 ml-2 text-white">Lvl</span>
                        </div>
                      </div>
                    </div>

                    <ArrayInput
                      label="Memorable Frames"
                      input={framesInput}
                      setInput={setFramesInput}
                      tags={framesArray}
                      setTags={setFramesArray}
                      isKeyReleased={isFramesKeyReleased}
                      setIsKeyReleased={setIsFramesKeyReleased}
                    />
                    <ArrayInput
                      label="Memorable Titles"
                      input={titlesInput}
                      setInput={setTitlesInput}
                      tags={titlesArr}
                      setTags={setTitlesArr}
                      isKeyReleased={isTitlesKeyReleased}
                      setIsKeyReleased={setIsTitlesKeyReleased}
                    />
                  </div>

                  <div className="mb-10">
                    <h1 className="mb-4 text-lg font-medium text-white mt-9">
                      Royal Pass Details
                    </h1>
                    <div className="flex flex-wrap justify-around text-white h-fit">
                      <div className="w-[32%]">
                        <h2 className="text-center rp-regular-gradient">
                          Regular
                        </h2>
                        <div className="p-4 text-center glassmorphism">
                          <button
                            type="button"
                            className="w-full py-1 border-none glassmorphism"
                            onClick={() => {
                              setRegularRpModalOpen(true);
                            }}
                          >
                            Select Season
                          </button>
                          {regularRpArray.map((rp) => (
                            <RpSeason seasonNum={rp.num} />
                          ))}
                        </div>
                      </div>
                      <div className="w-[32%] justify-center items-center">
                        <h2 className="text-center rp-elite-gradient">Elite</h2>
                        <div className="p-4 text-center border-yellow-700 glassmorphism ">
                          <button
                            type="button"
                            className="w-full py-1 border-none glassmorphism"
                            onClick={() => {
                              setEliteRpModalOpen(true);
                            }}
                          >
                            Select Season
                          </button>
                          {eliteRpArray.map((rp) => (
                            <RpSeason seasonNum={rp.num} />
                          ))}
                        </div>
                      </div>
                      <div className="w-[32%]">
                        <h2 className="text-center rp-elite-plus-gradient">
                          Elite Plus
                        </h2>
                        <div className="p-4 text-center border-red-500 glassmorphism h-fit">
                          <button
                            type="button"
                            className="w-full py-1 border-none glassmorphism"
                            onClick={() => {
                              setElitePlusRpModalOpen(true);
                            }}
                          >
                            Select Season
                          </button>
                          {elitePlusRpArray.map((rp) => (
                            <RpSeason seasonNum={rp.num} />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <DetailsField
                    label="Overall Inventory Overview"
                    name="overallOverview"
                    fullWidth
                    textarea
                    maxLength={10000}
                    rows={12}
                  />
                  <DetailsField
                    label="Special Inventory Mentions (100 words)"
                    name="specialMentions"
                    fullWidth
                    textarea
                    maxLength={500}
                    rows={8}
                  />
                </div>
                <div className="w-[50%] bg-white bg-opacity-20 rounded text-white p-4 ">
                  <h1 className="text-lg font-medium text-white">
                    Upload Pictures of your game profile and screenshot of game
                    settings
                  </h1>

                  <div className="flex justify-center items-center h-[40%] bg-white bg-opacity-10 shadow-lg rounded p-4 mt-3 mb-5 ">
                    <div
                      className="flex flex-col items-center justify-center w-1/2 h-full mr-2 bg-white rounded bg-opacity-10 hover:cursor-pointer"
                      onClick={() => fileInput.current.click()}
                      {...getImg1RootProps()}
                    >
                      {!image1 && (
                        <>
                          <MdOutlineAddAPhoto size={25} />
                          <input
                            style={{ display: "none" }}
                            type="file"
                            ref={fileInput}
                            {...getImg1InputProps()}
                          />
                          <span>Choose a file or drag and drop</span>
                          <p>PNG, JPG upto 5MB</p>
                        </>
                      )}
                      {image1 && (
                        <div className="flex items-center">
                          <img
                            src={image1.preview}
                            alt="preview"
                            className="object-contain h-36"
                          />
                          <button
                            className="flex justify-center items-center ml-2 h-2 w-2 self-start text-red-200 font-medium rounded-full bg-white bg-opacity-20 p-3"
                            onClick={() => setImage1()}
                          >
                            x
                          </button>
                        </div>
                      )}
                    </div>
                    <div
                      className="flex flex-col items-center justify-center w-1/2 h-full bg-white rounded bg-opacity-10 hover:cursor-pointer"
                      onClick={() => fileInput.current.click()}
                      {...getImg2RootProps()}
                    >
                      {!image2 && (
                        <>
                          <MdOutlineAddAPhoto size={25} />
                          <input
                            style={{ display: "none" }}
                            type="file"
                            ref={fileInput}
                            {...getImg2InputProps()}
                          />
                          <span>Choose a file or drag and drop</span>
                          <p>PNG, JPG upto 5MB</p>
                        </>
                      )}
                      {image2 && (
                        <div className="flex items-center">
                          <img
                            src={image2.preview}
                            alt="preview"
                            className="object-contain h-36"
                          />
                          <button
                            className="flex justify-center items-center ml-2 h-2 w-2 self-start text-red-200 font-medium rounded-full bg-white bg-opacity-20 p-3"
                            onClick={() => setImage2()}
                          >
                            x
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <h1 className="text-lg font-medium text-white">
                    Upload One Video related to your Account
                  </h1>
                  <div
                    className="flex flex-col space-2 justify-center items-center h-[40%] bg-white bg-opacity-10 shadow-lg rounded p-4 mt-3 mb-5 hover:cursor-pointer"
                    {...getVidRootProps()}
                  >
                    {!video && (
                      <>
                        <h1>X</h1>
                        <input
                          style={{ display: "none" }}
                          type="file"
                          ref={fileInput}
                          {...getVidInputProps()}
                        />
                        <span>Choose a file or drag and drop</span>
                        <p>MP4 upto 10MB</p>
                      </>
                    )}
                    {video && (
                      <div className="flex items-center">
                        <video
                          src={video.preview}
                          alt="preview"
                          className="object-contain h-40"
                        />
                        <button
                          className="flex justify-center items-center ml-2 h-2 w-2 self-start text-red-200 font-medium rounded-full bg-white bg-opacity-20 p-3"
                          onClick={() => setVideo()}
                        >
                          x
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Form>
        </div>
      </div>

      {regularRpModalOpen && (
        <Modal
          setOpenModal={setRegularRpModalOpen}
          seasonArray={seasonArray}
          rpArray={regularRpArray}
        />
      )}
      {eliteRpModalOpen && (
        <Modal
          setOpenModal={setEliteRpModalOpen}
          seasonArray={seasonArray}
          rpType="elite"
          rpArray={eliteRpArray}
        />
      )}
      {elitePlusRpModalOpen && (
        <Modal
          setOpenModal={setElitePlusRpModalOpen}
          seasonArray={seasonArray}
          rpType="elitePlus"
          rpArray={elitePlusRpArray}
        />
      )}
    </>
  );
};

export default Accounts;
