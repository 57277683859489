import React from "react";
import { useState } from "react";

const FetchPhone = ({ currentStep, setCurrentStep }) => {
  const [verify, setVerify] = useState({ phonenumber: null, otp: null });
  const [revealph, setRevealPh] = useState(false);
  const [send, setSend] = useState(false);
  return (
    <div className="font-semibold">
      <h3 className="hidden text-2xl font-semibold text-center sm:block">
        Fetch ID & Password
      </h3>
      <div className="px-4 py-3 mt-2 bg-dark-700 sm:h-80 h-[354px] overflow-y-scroll scrollbar">
        <p>Get phone no.</p>

        <div className="grid mt-3 gap-y-2 sm:grid-cols-2 gap-x-4">
          <div className="flex-row sm:flex gap-x-2">
            <input
              type="tel"
              name="phonenumber"
              onChange={(e) => {
                setVerify({ phonenumber: e.target.value, otp: verify.otp });
              }}
              className="w-full h-12 px-2 rounded-sm sm:w-3/5 bg-light-grey"
              placeholder="phone no."
            />
            <button
              className={`px-4 py-1 mt-1 sm:mt-0 sm:w-2/5 rounded  ${
                revealph ? "bg-light-silver" : "bg-yellow-500"
              }`}
              onClick={() => {
                setRevealPh(true);
              }}
            >
              {revealph ? "Revealed" : "Reveal phone no."}
            </button>
          </div>
          <div className="flex flex-row gap-x-2">
            <input
              type="tel"
              name="otp"
              onChange={(e) => {
                setVerify({
                  phonenumber: verify.phonenumber,
                  otp: e.target.value,
                });
              }}
              className="w-3/5 h-12 px-2 rounded-sm bg-light-grey"
              placeholder="OTP"
            />

            <button
              className={`px-4 w-2/5 rounded ${
                revealph ? "bg-yellow-500" : "bg-light-silver"
              }`}
              onClick={() => {
                setSend(true);
              }}
            >
              {send ? "Resend OTP" : "Fetch OTP"}
            </button>
          </div>
        </div>

        <div className="mt-6 text-center sm:mt-24">
          <p>Connect the phone no. provided to your acount listed for sale.</p>

          <button
            className={`py-2 mt-3 rounded px-14 ${
              send ? "bg-yellow-500" : "bg-light-silver"
            }`}
            type="button"
            onClick={() => {
              setCurrentStep(currentStep + 1);
            }}
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
};

export default FetchPhone;
