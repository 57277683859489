import React from "react";
import { MdOutlineRemoveRedEye, MdArrowBack } from "react-icons/md";
import { HiOutlineClock } from "react-icons/hi";
import { IoChatboxOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import SimpleBar from "simplebar-react";

const AccountDetails = ({ accountDetails, setShowDetails }) => {
  const navigate = useNavigate();

  return (
    <div className="relative mx-8 mt-5 font-semibold text-white xs:my-7 sm:mb-0 sm:mx-14 lg:mx-20 ">
      <div>
        <button
          type="button"
          className="px-3 py-2 mt-1 text-xl rounded-lg bg-neutral-700"
          onClick={() => {
            setShowDetails(false);
          }}
        >
          <MdArrowBack />
        </button>
      </div>

      <div className="gap-8 mt-3 md:flex">
        <div className="md:w-1/3">
          <img className="w-full" src={accountDetails.accountThumbnail} />
        </div>

        <div className="md:w-2/3 ">
          <div className="flex justify-between text-2xl ">
            <div className="flex gap-x-2">
              <p>{accountDetails.game}</p>
              <p className="flex text-xl text-dark-100">
                21{" "}
                <span className="mt-1 text-2xl">
                  <MdOutlineRemoveRedEye className="my-auto " />
                </span>
              </p>
            </div>
            <div>
              <p>₹{accountDetails.price}</p>
            </div>
          </div>

          <div className="mt-3 text-base">
            <div>
              <h3 className="text-lg text-neutral-500">Account details</h3>
              <div className="grid mt-3 font-medium sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-x-7 md:gap-x-20 gap-y-3">
                <div className="grid grid-cols-2 gap-x-7">
                  <p className=" text-neutral-500">Player ID </p>
                  <p>{accountDetails.playerID}</p>
                </div>
                <div className="grid grid-cols-2 gap-x-7">
                  <p className=" text-neutral-500">Ingame name </p>
                  <p>{accountDetails.ingameName}</p>
                </div>
                <div className="grid grid-cols-2 gap-x-7">
                  <p className=" text-neutral-500">Tenure of ownership </p>
                  <p>
                    {accountDetails.ownershipTenure.months} years{" "}
                    {accountDetails.ownershipTenure.years} months
                  </p>
                </div>
                <div className="grid grid-cols-2 gap-x-7">
                  <p className=" text-neutral-500">Account level</p>
                  <p>{accountDetails.accountLevel} level</p>
                </div>
                <div className="grid grid-cols-2 gap-x-7">
                  <p className=" text-neutral-500">Memorable frames</p>
                  <SimpleBar className="h-20">
                    {accountDetails.memorableFrames.map((frame, index) => (
                      <div className="flex ">
                        <p>{frame}</p>
                        {index + 1 !==
                          accountDetails.memorableFrames.length && <>,</>}
                      </div>
                    ))}
                  </SimpleBar>
                </div>
                <div className="grid grid-cols-2 gap-x-7">
                  <p className=" text-neutral-500">Memorable titles</p>
                  <SimpleBar className="h-20">
                    {accountDetails.memorableTitles.map((title, index) => (
                      <div className="flex ">
                        <p>{title}</p>
                        {index + 1 !==
                          accountDetails.memorableTitles.length && <>,</>}
                      </div>
                    ))}
                  </SimpleBar>
                </div>
              </div>
              <div className="grid grid-cols-2 mt-8 mb-3 font-medium lg:flex gap-x-16">
                <p className=" text-neutral-500">Special mentions</p>
                <p>{accountDetails.specialMention}</p>
              </div>
            </div>
            <div className="mt-3">
              <h3 className="mb-3 text-lg">Royal pass details</h3>
              {accountDetails.royalPassDetails.map((pass) => {
                if (pass.title === "Levels") return;
                return (
                  <div className="flex-row mb-1 font-medium sm:flex md:block lg:flex">
                    <div className="sm:w-1/4 md:w-full lg:w-1/4 ">
                      <p className=" text-neutral-500">{pass.title}</p>
                    </div>
                    <div className="flex flex-row overflow-x-scroll sm:w-3/4 md:w-full lg:w-3/4 gap-x-3 scrollbar-hide">
                      {pass.items.map((season, index) => {
                        return (
                          <div className="relative w-40 h-8 px-3 py-1 text-center rounded-3xl bg-dark-gray-200">
                            <img
                              src={require("../../images/season.png")}
                              className="absolute mt-2 left-5"
                            />
                            <p className="w-36">Season {season}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col-reverse my-3 font-medium sm:mt-9 gap-y-1 sm:grid sm:grid-cols-2 gap-x-8">
        <div className="flex-row-reverse sm:flex">
          <button
            className="flex justify-center w-full py-1 border-2 border-yellow-500 rounded-xl sm:rounded-sm sm:py-3 sm:w-fit gap-x-1 bg-neutral-700 solid px-7"
            type="button"
            onClick={() =>
              navigate("/chats", {
                state: {
                  receiverId: accountDetails.sellerId,
                  gameId: accountDetails._id,
                },
              })
            }
          >
            Chat with owner{" "}
            <span className="py-1 text-xl">
              <IoChatboxOutline />
            </span>
          </button>
        </div>
        <div>
          <Link to="/schedule" state={accountDetails}>
            <button
              type="button"
              className="flex justify-center w-full py-1 bg-yellow-500 rounded-xl sm:rounded-sm sm:py-3 sm:w-fit gap-x-1 px-7"
            >
              Schedule the buy
              <span className="py-1 text-xl">
                <HiOutlineClock />
              </span>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AccountDetails;
