import { Field } from "formik";

const DetailsField = ({
  name,
  label,
  fullWidth,
  num,
  price,
  textarea,
  maxLength,
  rows,
  onChange,
  value,
  disabled,
}) => {
  let style;

  if (fullWidth)
    style =
      "rounded px-2 py-1 w-full bg-white bg-opacity-20 shadow-lg outline-0 text-gray-300";
  else if (num)
    style =
      "rounded px-2 py-1 w-10 bg-white bg-opacity-20 shadow-lg outline-0 text-gray-300";
  else
    style =
      "rounded px-2 py-1 bg-white bg-opacity-20 shadow-lg outline-0 text-gray-300";

  return (
    <div className="mt-4">
      <h2 className="label-text-gradient">{label}</h2>
      {!price && (
        <Field
          component={textarea ? "textarea" : ""}
          name={name}
          className={style}
          maxLength={maxLength}
          rows={rows}
          onChange={onChange}
          value={value}
          disabled={disabled}
        />
      )}
      {price && (
        <div className="flex text-gray-300">
          <span className="bg-white bg-opacity-20 shadow-lg rounded-l p-1 pl-2">
            ₹
          </span>
          <Field
            name={name}
            className=" px-2 py-1 bg-white bg-opacity-20 shadow-lg outline-0 text-gray-300 w-32"
          />
          <span className="bg-white bg-opacity-20 shadow-lg rounded-r p-1 px-2">
            INR
          </span>
        </div>
      )}
    </div>
  );
};

export default DetailsField;
