import React from "react";
import { useEffect } from "react";
import { useState } from "react";

function Modal({ setOpenModal, seasonArray, rpType, rpArray }) {
  let borderColor = "border-red-900";
  let bgColor = "bg-red-900";
  let textColor = "text-[#8E3E3E]";

  if (rpType === "elite") {
    borderColor = "border-yellow-700";
    bgColor = "bg-yellow-800";
    textColor = "text-yellow-900";
  } else if (rpType === "elitePlus") {
    borderColor = "border-red-500";
    bgColor = "bg-red-500";
    textColor = "text-red-500";
  }
  let temp = [];
  const [isSelect, select] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [updated, setupdate] = useState(1);
  const [selectedArray, setArray] = useState([]);
  let arr;
  useEffect(() => {
    arr = isSelect;
    temp = selectedArray;
  });

  const Save = () => {
    selectedArray.map((ele) => {
      rpArray.push({ num: ele.num });
    });
    rpArray.map((ele) => {
      if (seasonArray.length <= 0) {
        return;
      }
      let pos = seasonArray.findIndex((current) => current.num == ele.num);
      seasonArray.splice(pos, 1);
      console.log(seasonArray);
      setOpenModal(false);
    });
  };
  const Cancel = () => {
    rpArray = rpArray.filter((current) => current.num !== seasonArray.num);

    setOpenModal(false);
  };
  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center w-full h-screen transition duration-300 modalBackground backdrop-blur ">
      <div
        className={`modalContainer w-80 h-auto bg-[#dcdcdc] bg-opacity-80 shadow-lg duration-300 glassmorphism ${borderColor} border-[3px] rounded-lg `}
      >
        <div className="p-8 mt-5 overflow-hidden overflow-y-scroll h-72 scrollbar-hide ">
          {seasonArray.map((ele, index) => {
            const addToRpArray = (number) => {
              console.log(index);
              arr.splice(ele.num - 1, 1, !arr[ele.num - 1]);
              select(arr);

              if (arr[ele.num - 1]) {
                temp.push({ num: ele.num });
              } else {
                let pos = temp.findIndex((current) => current.num == ele.num);
                if (pos >= 0) {
                  temp.splice(pos, 1);
                }
              }
              setArray(temp);
              setupdate(!updated);
            };

            return (
              <div
                className={`flex ${textColor} font-body font-medium justify-between items-center mb-5 hover:cursor-pointer hover:brightness-150`}
                onClick={(ele) => addToRpArray(ele.num)}
              >
                <p>
                  Season <span>{ele.num}</span>
                </p>
                <div
                  className={`flex justify-center items-center rounded-full border ${borderColor} h-4 w-4`}
                >
                  {/* {ele.isSelected && (
                                        <div
                                            className={`rounded-full ${bgColor} h-2 w-2 `}
                                        ></div>
                                    )} */}

                  {isSelect[ele.num - 1] && (
                    <div className={`rounded-full ${bgColor} h-2 w-2 `}></div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex justify-between px-8 my-4 font-body text-md">
          <button
            className={`${bgColor} text-white py-1 rounded w-[50%] mr-2 hover:brightness-125 hover:scale-105 duration-200`}
            onClick={Save}
          >
            Save
          </button>
          <button
            className={`py-1 rounded w-[50%] ${textColor} ${
              borderColor ? borderColor : "border-[#8E3E3E]"
            } border-opacity-75 border-2 hover:brightness-125 hover:scale-105 duration-200`}
            onClick={Cancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default Modal;
