import React, { useState, useEffect } from "react";
import authApi from "../api/auth";
import useApi from "../hooks/useApi";
import { Link, useLocation } from "react-router-dom";
import RegisterButton from "../components/Buttons/RegisterButton";
import { IoMdEyeOff, IoMdEye } from "react-icons/io";
const ResetPassword = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams?.get("token");

  const [email, setEmail] = useState("");
  const [invalid, setValidation] = useState("");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [passwordLenError, setPasswordLenError] = useState(false);
  const [eye, setEye] = useState(false);
  const [eyeOpen, setEyeOpen] = useState(false);
  const show = () => {
    setEye(!eye);
  };
  const showConfirmPassword = () => {
    setEyeOpen(!eyeOpen);
  };

  const {
    data,
    error,
    loading,
    request: resetPassword,
  } = useApi(authApi.resetPassword);

  const {
    data: res,
    error: err,
    loading: load,
    request: changePassword,
  } = useApi(authApi.changePassword);

  const {
    data: verified,
    error: errV,
    loading: getting,
    request: verifyResetLink,
  } = useApi(authApi.verifyResetLink);

  const handleSubmit = async (event) => {
    event.preventDefault();
    resetPassword(email);
  };

  const handleReset = async (e) => {
    e.preventDefault();
    if (password.length < 6) {
      return alert("password must be more than six characters");
    }
    if (!passwordMatch) {
      return alert("confirm password and password is not matching");
    }
    changePassword({ token: token, password: password });
  };

  const emailValidation = () => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email) {
      setValidation("email is required");
    } else if (regex.test(email) === false) {
      setValidation("email is invalid");
    } else {
      setValidation("");
    }
  };
  useEffect(() => {
    if (email) {
      emailValidation(email);
    }
  }, [email]);

  useEffect(() => {
    if (error && !loading) {
      alert(data);
    } else if (!error && !loading && data) {
      console.log(data);
      alert(data);
    }
  }, [error, loading]);
  useEffect(() => {
    if (token) {
      verifyResetLink({ token });
    }
    if (errV && token && !getting) {
      alert(verified);
      window.location.replace("https://www.premiumcores.com");
    }
  }, [token, errV]);

  useEffect(() => {
    if (res && !load && !err) {
      alert(res);
    } else if (err && !load) {
      window.location.reload();
    }
  }, [res, load]);
  useEffect(() => {
    if (password !== confirmPassword) setPasswordMatch(false);
    else if (password === confirmPassword) setPasswordMatch(true);
  }, [confirmPassword]);
  return (
    <div className="w-screen h-screen pb-4 background-image">
      <div className="h-[64px] flex justify-between">
        <Link to={"/"}>
          <div className="flex w-full h-8 gap-2 mx-3 my-6 text-2xl font-bold text-yellow-500 sm:gap-1 sm:my-4 sm:h-10 sm:text-3xl sm:mx-8 font-heading">
            <img
              src={require("../images/logo.png")}
              alt=""
              className="h-full text-yellow-500 "
            />
            PREMIUMCORES
          </div>
        </Link>
      </div>
      {!token && (
        <div className="sm:w-[408px] xs:mx-10 mx-5 sm:mx-auto text-white signup-box my-10 xs:my-[20vh] sm:my-[100px] rounded p-6">
          <p className="mb-10 text-2xl font-bold tracking-tighter sm:text-3xl md:text-4xl font-heading">
            Forgot password
          </p>

          <form onSubmit={handleSubmit} className="mt-6 ">
            <span className="px-2 mb-2 text-xs ">
              {data &&
                !load &&
                !error &&
                "Email sent, please check your email."}
            </span>
            <input
              type="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              className="block input-field w-[100%]"
              placeholder="Enter your email"
              onBlur={emailValidation}
            />
            <span className="px-2 text-xs text-red-400 ">{invalid}</span>

            <div className="">
              <button
                type="submit"
                className="w-[100%]  hover:scale-100 duration-200"
              >
                <RegisterButton
                  text={data == null ? "Send reset link" : "Resend link"}
                  className="h-10 py-1 text-lg font-semibold "
                  loading={loading}
                />
              </button>
            </div>
          </form>
        </div>
      )}
      {token && (
        <div className="sm:w-[408px] xs:mx-10 mx-5 sm:mx-auto text-white signup-box my-10 xs:my-[20vh] sm:my-[100px] rounded p-6">
          <p className="mb-6 text-2xl font-bold tracking-tighter sm:text-3xl md:text-4xl font-heading">
            Change password
          </p>
          <form onSubmit={handleReset}>
            <div>
              <label
                htmlFor="password"
                className=" text-white-grey text-[16px] h-4 mb-[2px]"
              >
                New password
              </label>
              <div className="flex flex-row p-0 mt-[-1px] input-field h-[52px]">
                <input
                  className="bg-transparent h-[52px] rounded-[2px] w-[100%] p-2"
                  name="password"
                  type={eye ? "text" : "password"}
                  placeholder=" Enter password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  onBlur={() => {
                    if (password !== confirmPassword) setPasswordMatch(false);
                    else if (password === confirmPassword)
                      setPasswordMatch(true);
                    if (password.length < 6) setPasswordLenError(true);
                    else if (password.length >= 6) setPasswordLenError(false);
                  }}
                />
                <button onClick={show} type="button" className="p-3 ">
                  {eye ? (
                    <IoMdEye className="text-xl" />
                  ) : (
                    <IoMdEyeOff className="text-xl" />
                  )}
                </button>
              </div>
              {passwordLenError && (
                <p className="text-xs text-red-600 sm:text-sm">
                  Password must be of atleast 6 characters.
                </p>
              )}
            </div>
            <div className="mt-2">
              <label
                htmlFor="confirmPassword"
                className=" text-white-grey text-[16px] h-4 mb-[2px]"
              >
                Confirm password
              </label>
              <div className="flex flex-row p-0 mt-[-1px] input-field h-[52px]">
                <input
                  className="bg-transparent h-[52px] rounded-[2px] w-[100%] p-2"
                  name="confirmPassword"
                  type={eyeOpen ? "text" : "password"}
                  placeholder=" Enter password"
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                  value={confirmPassword}
                />
                <button
                  onClick={showConfirmPassword}
                  type="button"
                  className="p-3"
                >
                  {eyeOpen ? (
                    <IoMdEye className="text-xl" />
                  ) : (
                    <IoMdEyeOff className="text-xl" />
                  )}
                </button>
              </div>
              {!passwordMatch && (
                <p className="text-xs text-red-600 sm:text-sm">
                  Passwords should match.
                </p>
              )}
            </div>
            <div className="mt-3">
              <button
                type="submit"
                className="w-[100%]  hover:scale-100 duration-200"
              >
                <RegisterButton
                  text="Change password"
                  className="h-10 py-1 text-lg font-semibold "
                  loading={load}
                />
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
