import client from "./client";

const createRoom = (data) => client.post(`/xroom/create`, data);
const getRooms = () => client.get(`/xroom/create`);

const getRoom = (roomId) => client.get(`/xroom/${roomId}`);

export default {
  createRoom,
  getRoom,
  getRooms,
};
