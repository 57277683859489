import React from "react";
import { useNavigate } from "react-router-dom";

const BTermsOfService = ({ setAgree, currentStep, setCurrentStep }) => {
  const navigate = useNavigate();
  return (
    <div className="text-white">
      <h3 className="text-lg font-semibold text-center sm:text-2xl">
        Terms of Service (Buyer)
      </h3>
      <div className="p-3 mt-2 text-sm sm:text-base overflow-y-scroll rounded-t scrollbar h-72 lg:h-72 md:h-[31vh] bg-dark-700">
        <h3>1. ACCEPTANCE THE USE OF LOREM IPSUM TERMS AND CONDITIONS</h3>
        <p>
          Your access to and use of Lorem Ipsum (the app) is subject exclusively
          to these Terms and Conditions. You will not use the app for any
          purpose that is unlawful or prohibited by these Terms and Conditions.
          By using the app you are fully accepting the terms, conditions and
          disclaimers contained in this notice. If you do not accept these Terms
          and Conditions you must immediately stop using the app.
        </p>

        <h3>2. CREDIT CARD DETAILS</h3>
        <p>
          All Lorem Ipsum purchases are managed by the individual App Stores
          (Apple, Google Windows) and Lorem Ipsum will never store your credit
          card information or make it available to any third parties. Any
          purchasing information provided will be provided directly from you to
          the respective App Store and you will be subject to their credit card
          policies.
        </p>

        <h3>3. LEGAL ADVICE</h3>
        <p>
          The contents of Lorem Ipsum app do not constitute advice and should
          not be relied upon in making or refraining from making, any decision.
          All material contained on Lorem Ipsum is provided without any or
          warranty of any kind. You use the material on Lorem Ipsum at your own
          discretion
        </p>

        <h3>4. CHANGE OF USE</h3>
        <p>
          Lorem Ipsum reserves the right to: 4.1 change or remove (temporarily
          or permanently) the app or any part of it without notice and you
          confirm that Lorem Ipsum shall not be liable to you for any such
          change or removal and.
        </p>
      </div>
      <div className="z-20 flex justify-end -mt-2 rounded-b bg-dark-700">
        <div className="px-2 py-1 mb-2 mr-3 -mt-2 terms-button-div">
          <button
            className="px-6 py-1 rounded bg-error-500"
            type="button"
            onClick={() => {
              setAgree(false);
              navigate(-1);
            }}
          >
            Cancel
          </button>
          <button
            className="px-6 py-1 ml-2 rounded bg-success"
            type="button"
            onClick={() => {
              setAgree(true);
              setCurrentStep(currentStep + 1);
            }}
          >
            Agree
          </button>
        </div>
      </div>
    </div>
  );
};

export default BTermsOfService;
