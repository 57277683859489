import client from "./client";

const getAccounts = () => client.get("/account/all");

const transferAccount = (data) => client.put("/account/transfer", data);

const getOneAccount = (accountId) => client.get(`/account/${accountId}`);

const myAccounts = () => client.get("/account/myAccounts");

const postAccount = (data) => client.post("/account/post", data);

const editAccount = (data) => client.put("/account/edit", data);

const verifyId = (data) => client.post("/account/getPlayerName", data);

const verifyFb = (data) => client.post("/account/verifyFb", data);

const verifyTwitter = (data) => client.post("/account/verifyTwitter", data);

export default {
  getAccounts,
  transferAccount,
  getOneAccount,
  myAccounts,
  postAccount,
  editAccount,
  verifyId,
  verifyFb,
  verifyTwitter,
};
