import React from "react";

const ArrayInput = ({
  input,
  setInput,
  tags,
  setTags,
  isKeyReleased,
  setIsKeyReleased,
  label,
  className,
}) => {
  const onChange = (e) => {
    const { value } = e.target;
    setInput(value);
  };

  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();
    if (key === "Enter" && trimmedInput.length === 0) {
      const form = e.target.form;
      const index = Array.prototype.indexOf.call(form, e.target);
      form.elements[index + 1].focus();
      e.preventDefault();
    }

    if (
      (key === "," || key === "Enter") &&
      trimmedInput.length &&
      !tags.includes(trimmedInput)
    ) {
      e.preventDefault();
      setTags((prevState) => [...prevState, trimmedInput]);
      setInput("");
    }

    if (key === "Backspace" && !input.length && tags.length && isKeyReleased) {
      const tagsCopy = [...tags];
      const poppedTag = tagsCopy.pop();
      e.preventDefault();
      setTags(tagsCopy);
      setInput(poppedTag);
    }

    setIsKeyReleased(false);
  };

  const onKeyUp = () => {
    setIsKeyReleased(true);
  };

  const deleteTag = (index) => {
    setTags((prevState) => prevState.filter((tag, i) => i !== index));
  };
  return (
    <div
      className={`container w-full" ${className ? className : "mt-1 sm:mt-5"}`}
    >
      <p className="mt-0">{label}</p>
      <div className="flex flex-wrap px-2 py-1 text-gray-300 rounded input-field outline-0">
        {tags?.map((tag, index) => (
          <div className="flex items-center p-1 px-2 mx-2 my-1 bg-white rounded shadow-md tag bg-opacity-10">
            {tag}
            <button
              type="button"
              className="px-2 ml-2 font-medium text-gray-800 bg-white rounded-full bg-opacity-20"
              onClick={() => deleteTag(index)}
            >
              x
            </button>
          </div>
        ))}
        <input
          className="w-full px-2 py-1 text-gray-300 rounded outline-0 input-gradient"
          value={input}
          placeholder="Enter a keyword and press enter"
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default ArrayInput;
