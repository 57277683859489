import React, { useState } from "react";
import ShadowButton from "../components/Buttons/ShadowButton";
import { Link } from "react-router-dom";
import { MdClose, MdMenu } from "react-icons/md";
const Home = () => {
  const [toggel, setToggel] = useState(false);
  return (
    <div className="w-[100vw] h-[100vh] overflow-hidden text-white home-bg-img">
      <div className="h-[64px] flex justify-between">
        <div className="flex w-full h-8 gap-2 mx-3 my-4 text-2xl font-bold text-yellow-500 sm:gap-1 sm:h-10 sm:text-3xl sm:mx-8 font-heading">
          <img
            src={require("../images/logo.png")}
            alt=""
            className="h-full text-yellow-500 "
          />
          <p className="scale-x-95 scale-y-125 ">PREMIUMCORES</p>
        </div>
        <div className="hidden mx-5 my-4 text-lg font-semibold sm:mx-8 sm:text-xl md:flex sm:gap-x-3 lg:gap-x-6">
          <Link to={"/agentlogin"}>
            <button className="w-20 py-1 sm:pb-3 sm:py-2 ">Agent</button>
          </Link>
          <Link to={"/guest"}>
            <button className="w-20 py-1 sm:pb-3 sm:py-2 ">Guest</button>
          </Link>
          <Link to={"/login"}>
            <button className="w-20 py-1 sm:pb-3 sm:py-2 ">Log in</button>
          </Link>
          <Link to={"/signup"}>
            <button>
              <ShadowButton
                className="w-24 px-2 py-1 pb-2 text-white bg-yellow-500 sm:px-4 sm:w-36"
                text="Sign up"
              />
            </button>
          </Link>
        </div>
        <div
          onMouseEnter={() => setToggel(true)}
          onMouseLeave={() => setToggel(false)}
          className="px-5 py-4 md:hidden"
        >
          {!toggel && (
            <button onClick={() => setToggel(true)}>
              <MdMenu size={30} />
            </button>
          )}

          <div
            className={`absolute h-[100vh]  z-40 top-0  right-0  py-3 text-left md:hidden w-[90%]  transition-all transform  duration-250  bg-dark-700 ${
              toggel ? "mr-0 " : "mr-[-100%] "
            }`}
          >
            <div className="flex justify-end mx-6">
              <button onClick={() => setToggel(false)}>
                <MdClose size={30} />
              </button>
            </div>
            <div className="grid grid-cols-1 mt-4">
              <Link to={"/agentlogin"}>
                <button className="w-20 py-1 sm:pb-3 sm:py-2 ">Agent</button>
              </Link>
              <Link to={"/guest"}>
                <button className="w-20 py-1 sm:pb-3 sm:py-2 ">Guest</button>
              </Link>
              <div className="flex justify-between ">
                <Link to={"/login"}>
                  <button className="w-20 py-1 sm:pb-3 sm:py-2 ">Log in</button>
                </Link>
                <Link to={"/signup"}>
                  <button>
                    <ShadowButton
                      className="w-24 px-2 py-1 mx-6 text-white bg-yellow-500 sm:px-4 sm:w-36"
                      text="Sign up"
                    />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center mt-[25vh]">
        <h1 className="text-4xl font-bold scale-x-75 scale-y-125 xs:text-5xl sm:scale-x-95 xs:px-4 sm:tracking-tighter lg:tracking-tight sm:text-7xl lg:px-0 md:text-8xl font-heading">
          PREMIUMCORES
        </h1>
        <div className="py-3 text-sm mx-7 xs:mx-14 sm:text-base sm:mx-auto sm:w-3/5 lg:w-1/2 xl:w-2/5 2xl:w-1/3">
          <p className=" text-grey-200">
            A hub for all gaming accounts with secure and reliable marketplace
            for selling and buying game accounts.A secure and reliable
            marketplace for selling and buying game accounts.
          </p>
        </div>
        <div className=" sm:mt-7">
          <Link to="/signup">
            <button className="p-1 text-xl font-semibold border-2 border-grey solid">
              <ShadowButton
                className="px-5 py-2 text-white bg-yellow-500"
                text="GET STARTED"
              />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
