import React, { useState, useContext, useEffect } from "react";
import { MdSend, MdArrowBack } from "react-icons/md";
import { format } from "timeago.js";
import { v4 as uuidv4 } from "uuid";

import useApi from "../../hooks/useApi";
import userDetailsApi from "../../api/userDetails";
import chatApi from "../../api/chat";
import { useRef } from "react";
import Loader from "../components/Loader";

const ChatWindow = ({
  chat,
  setSendMessage,
  receiveMessage,
  currentUserId,
  online,
  setShowchat,
}) => {
  const [newMessage, setNewMessage] = useState("");
  const [messages, setMessages] = useState([]);

  const scrollRef = useRef();

  const userId = chat?.members.find((id) => id !== currentUserId);

  const { data: secondUser, request: getSecondUser } = useApi(
    userDetailsApi.getUserDetails
  );

  useEffect(() => {
    getSecondUser(userId);
  }, [chat]);

  useEffect(() => {
    if (receiveMessage !== null && receiveMessage?.chatId === chat?._id) {
      setMessages([...messages, { ...receiveMessage }]);
    }
  }, [receiveMessage]);

  // fetching data for messages
  const { data: messagesData, request: getMessages } = useApi(
    chatApi.getMessages
  );

  const {
    data,
    error,
    loading,
    request: sendMessage,
  } = useApi(chatApi.sendMessage);

  useEffect(() => {
    getMessages(chat?._id);
  }, [chat]);

  useEffect(() => {
    setMessages(messagesData);
  }, [messagesData]);

  useEffect(() => {
    if (!error && !loading && data) {
      setMessages([...messages, data]);
      setNewMessage("");
    }
  }, [data]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behaviour: "smooth" });
  }, [messages]);

  const handleChange = (e) => {
    setNewMessage(e.target.value);
  };

  if (!secondUser?.name || (chat !== null && messages === null)) {
    // console.log(chat);
    return (
      <div className=" w-full md:w-[50%] messages">
        <Loader className="h-[72vh] mt-14" />
      </div>
    );
  }

  const handleSend = (e) => {
    e?.preventDefault();
    const message = {
      senderId: currentUserId,
      text: newMessage,
      chatId: chat._id,
    };

    // send message to database
    sendMessage(message);

    // send message to socket server
    const receiverId = chat.members.find((id) => id !== currentUserId);
    setSendMessage({ ...message, receiverId });
  };

  // message component
  const MessageRecieved = ({ msg }) => {
    return (
      <div className="flex flex-col w-full mb-2">
        <div className="px-3 p-2 max-w-[60%] w-fit bg-white rounded-[10px]">
          <p className=" text-black font-normal text-[16px]">{msg.text}</p>
        </div>
        <div className="chat-date">{format(msg.createdAt)}</div>
      </div>
    );
  };

  const MessageSent = ({ msg }) => {
    return (
      <div className="flex flex-col items-end w-full mb-2 ">
        <div className="pl-3 pr-[10px] p-2 max-w-[60%] bg-[#444444] rounded-[10px]">
          <p className=" text-[#FFFFFF] font-normal text-[16px]">{msg.text}</p>
        </div>
        <div className="text-right chat-date">{format(msg.createdAt)}</div>
      </div>
    );
  };

  const onKeyDown = (e) => {
    const { key } = e;
    // const trimmedInput = input.trim();

    if (key === "Enter") {
      // console.log(key);
      handleSend();
    }

    // setIsKeyReleased(false);
  };

  // const onKeyUp = () => {
  //   setIsKeyReleased(true);
  // };

  return (
    <div className="messages w-full md:w-[60%] xl:w-[50%]">
      <div className="flex flex-row justify-between py-3 chat-heading">
        <div className="flex flex-row items-center">
          <button
            className="block ml-2 font-bold text-yellow-500 md:hidden"
            onClick={() => setShowchat(false)}
          >
            <MdArrowBack size={25} />
          </button>
          <div className="avatar flex justify-center items-center md:ml-5 font-semibold text-xl w-10 h-10 bg-[#ffa800] rounded-full">
            {secondUser?.name[0]}
          </div>
          <div className="ml-1 sm:ml-5">
            <p className="text-white text-[16px] font-bold">
              {secondUser?.name}
            </p>
            {online && (
              <p className="flex flex-row justify-between gap-1 items-center chat-date text-[#10B981] bg-[#8CA78A4D] w-fit px-2 rounded-lg">
                <div className="h-[0.3rem] w-[0.3rem] rounded-full bg-[#10B981]" />{" "}
                online
              </p>
            )}
          </div>
        </div>
      </div>
      <div className={`flex flex-col h-[72vh] pt-4`}>
        {messages === null && <Loader />}
        {/* <ReactScrollableFeed className="px-10"> */}
        <div className="overflow-y-scroll scrollbar-hide h-[72vh]">
          {messages?.map((msg, index) => {
            return (
              <div ref={scrollRef} key={index} className="px-3">
                {msg.senderId === currentUserId ? (
                  <MessageSent msg={msg} />
                ) : (
                  <MessageRecieved msg={msg} />
                )}
              </div>
            );
          })}
        </div>
        <div className="flex items-center self-end w-[90%] sm:w-[95%] justify-center m-5 p-2 text-box">
          <input
            type="text-area"
            className=" bg-[#191919] text-white w-full outline-none"
            placeholder="Send a message"
            name="textM"
            id="textM"
            onChange={handleChange}
            value={newMessage}
            onKeyDown={onKeyDown}
          />
          <button
            className="w-9 h-9 bg-yellow-500 rounded-[10px]"
            onClick={handleSend}
          >
            <MdSend className="text-center text-white h-[18px] w-[20.52px] mx-auto" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatWindow;
