// import { replace } from "formik";
import { useLocation, Navigate, Outlet } from "react-router-dom";

import useToken from "../hooks/useToken";
import Navbar from "../screens/components/Navbar";

export function ProtectedRoutes({ children, setIsSeller }) {
  const { token } = useToken();
  // const navigate = useNavigate();
  const location = useLocation();
  // console.log(token);
  return token ? (
    <>
      <Navbar setIsSeller={setIsSeller} />
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  );
}
