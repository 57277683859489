import React, { useState, useEffect, useRef } from "react";

const Stepper = ({ steps, currentStep }) => {
  const [newStep, setNewStep] = useState([]);
  const stepsRef = useRef();

  const updateStep = (stepNumber, steps) => {
    const newSteps = [...steps];
    let count = 0;
    while (count < newSteps.length) {
      //current step
      if (count === stepNumber) {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: true,
          selected: true,
          completed: false,
        };
        count++;
      }

      //step completed
      else if (count < stepNumber) {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: false,
          selected: true,
          completed: true,
        };
        count++;
      }
      //step pending
      else {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: false,
          selected: false,
          completed: false,
        };
        count++;
      }
    }

    return newSteps;
  };

  useEffect(() => {
    const stepsState = steps.map((step, index) =>
      Object.assign(
        {},
        {
          description: step,
          completed: false,
          highlighted: index === 0 ? true : false,
          selected: index === 0 ? true : false,
        }
      )
    );

    stepsRef.current = stepsState;
    const current = updateStep(currentStep - 1, stepsRef.current);
    setNewStep(current);
  }, [steps, currentStep]);

  const stepsDisplay = newStep.map((step, index) => {
    return (
      <div
        key={index}
        className={
          index !== newStep.length - 1
            ? "w-full items-center flex"
            : "items-center flex"
        }
        // style={{ display: "flex" }}
      >
        <div
          // style={{ display: "flex" }}
          className="relative flex flex-col items-center text-white"
        >
          <div
            className={`flex stepper-div transition duration-500 ease-in-out sm:h-12 sm:w-12 h-8 w-8 items-center justify-center py-3  ${
              step.selected
                ? " bg-yellow-500 text-white font-bold border-yellow-500"
                : "bg-grey text-white font-bold border-grey"
            }`}
          >
            {index + 1}
          </div>
          <div
            className={`absolute top-0 sm:w-24 leading-5 xl:leading-7 invisible sm:visible  xl:w-32 text-center mt-8 sm:mt-12 text-base ${
              step.highlighted ? "text-black dark:text-white" : "text-grey"
            }`}
          >
            {step.description}
          </div>
        </div>
        <div
          className={`flex-auto border-t-2 transition duration-500 ease-in-out  ${
            step.completed ? " border-yellow-500" : "border-grey"
          }  `}
        ></div>
      </div>
    );
  });

  return (
    <>
      <div className="flex items-center justify-between w-full px-2 mx-auto xs:px-0 sm:px-3 md:px-0 mp-14">
        {stepsDisplay}
      </div>
      <div className="sm:hidden">
        {newStep.map((step, index) => {
          return (
            <>
              {step.highlighted && (
                <p
                  className="pt-4 text-2xl text-center text-yellow-500 sm:invisible"
                  key={index}
                >
                  {step.description}
                </p>
              )}
            </>
          );
        })}
      </div>
    </>
  );
};
export default Stepper;
