import { useLocation, Navigate, Outlet } from "react-router-dom";

import useToken from "../hooks/useToken";

export function ProtectedRoutes3() {
  const { token } = useToken();

  const location = useLocation();
  // console.log(token);
  return token ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to="/agentlogin" replace state={{ from: location }} />
  );
}
