import React from "react";
import { useState } from "react";
import FetchPhone from "./FetchPhone";

const FetchID = ({ currentStep, setCurrentStep }) => {
  const [revealed, setReveal] = useState(false);
  const [resendOTP, setResendOTP] = useState(false);
  const [extend, setExtend] = useState(false);
  const [linkPhone, setLinkPhone] = useState(false);
  const [data, setData] = useState({ email: "", password: "" });
  return (
    <>
      {!linkPhone && (
        <div>
          <h3 className="hidden text-2xl font-semibold text-center sm:block">
            Fetch and link ID & Password
          </h3>
          <div className="px-4 py-3 mt-2 bg-dark-700 sm:h-80 h-[354px] overflow-y-scroll scrollbar">
            <p className="font-semibold ">Get ID and password</p>

            <div className="flex-row mt-2 sm:flex gap-x-3 lg:gap-x-6 2xl:gap-x-14">
              <div className="grid grid-cols-1 sm:w-1/3">
                <label htmlFor="email">Email ID</label>
                <input
                  type="email"
                  name="email"
                  placeholder="--"
                  className="h-8 px-2 rounded-sm bg-light-grey"
                  onChange={(e) => {
                    setData({
                      email: e.target.value,
                      password: data.password,
                    });
                  }}
                />
              </div>
              <div className="grid grid-cols-1 sm:w-1/3 ">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  name="password"
                  placeholder="--"
                  className="h-8 px-2 rounded-sm bg-light-grey"
                  onChange={(e) => {
                    setData({ email: data.email, password: e.target.value });
                  }}
                />
              </div>
              <button
                className={`px-4 mt-2 sm:mt-0 py-1 rounded  ${
                  revealed ? "bg-light-silver px-14" : "bg-yellow-500"
                }`}
                onClick={() => {
                  setReveal(!revealed);
                }}
              >
                {revealed ? "Revealed" : "Reveal ID & password"}
              </button>
            </div>

            <div className="flex mt-5 gap-x-3 lg:gap-x-6 2xl:gap-x-14">
              <div className="grid w-1/2 grid-cols-1 sm:w-1/3">
                <label htmlFor="otp">OTP</label>
                <input
                  type="number"
                  name="otp"
                  placeholder="--"
                  className="w-full h-8 px-2 rounded-sm bg-light-grey"
                />
              </div>
              <div>
                <button
                  className={`h-full py-1 font-semibold rounded px-4 sm:px-14 ${
                    revealed ? "bg-yellow-500" : "bg-light-silver"
                  }`}
                  type="button"
                  onClick={() => {
                    setResendOTP(true);
                  }}
                >
                  {resendOTP ? "Resend OTP" : "Fetch OTP"}
                </button>
              </div>
            </div>
            <button
              className={`px-4 rounded  ${
                revealed ? "bg-light-silver px-14" : "bg-yellow-500"
              }`}
              onClick={() => {
                setReveal(!revealed);
              }}
            >
              {revealed ? "Revealed" : "Reveal ID & password"}
            </button>
          </div>

          <div className="mt-6 sm:mt-10">
            <p className="font-semibold text-center">
              Connect the Id provided above to your acount listed for sale.
            </p>
            <div className="grid justify-center mt-3 font-semibold gap-y-2 sm:flex gap-x-4">
              <button
                className={`sm:px-20 px-10 py-2 rounded ${
                  resendOTP ? "bg-yellow-500" : "bg-light-silver"
                }`}
                type="button"
                onClick={() => {
                  setLinkPhone(true);
                }}
              >
                Proceed
              </button>
              <button
                className="px-2 py-2 border-2 border-yellow-500 rounded sm:px-6 solid"
                onClick={() => {
                  setExtend(true);
                }}
              >
                Extend time by 2 mins
              </button>
            </div>
            {extend && (
              <p className="ml-[53%] text-sm text-silver-white">
                Time extended for 2 mins
              </p>
            )}
          </div>
        </div>
      )}
      {linkPhone && (
        <FetchPhone currentStep={currentStep} setCurrentStep={setCurrentStep} />
      )}
    </>
  );
};

export default FetchID;
