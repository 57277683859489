import React, { useEffect, useState } from "react";

import useApi from "../../hooks/useApi";
import bidApi from "../../api/bid";
import Loader from "../components/Loader";
import { MdClose } from "react-icons/md";

const BidWindow = ({
  setSendBidProposal,
  receiveBidProposal,
  currentUserId,
  gameId,
  chat,
}) => {
  const [newBidProposal, setNewBidProposal] = useState("");
  const [bidProposals, setBidProposals] = useState([]);
  const [isBidAccepted, setIsBidAccepted] = useState();
  const [hidden, setHidden] = useState(true);

  const {
    data,
    error,
    loading,
    request: proposeBid,
  } = useApi(bidApi.proposeBid);

  // fetching data for messages
  const { data: bidsData, request: getBids } = useApi(bidApi.getBids);

  const {
    data: acceptBidData,
    error: acceptBidError,
    request: acceptBid,
  } = useApi(bidApi.acceptBid);

  useEffect(() => {
    getBids(chat?._id, gameId);
  }, [chat]);

  useEffect(() => {
    setBidProposals(bidsData);
    if (bidsData) setIsBidAccepted(bidsData[bidsData?.length - 1]?.isAccepted);
  }, [bidsData]);

  useEffect(() => {
    if (!error && !loading && data) {
      setBidProposals([...bidProposals, data]);
      setNewBidProposal("");
    }
  }, [data]);

  useEffect(() => {
    if (
      receiveBidProposal !== null &&
      receiveBidProposal?.chatId === chat?._id
    ) {
      setBidProposals([...bidProposals, { ...receiveBidProposal }]);
    }
  }, [receiveBidProposal]);

  useEffect(() => {
    if (!error && !loading && data) {
      setBidProposals([...bidProposals, data]);
      setNewBidProposal("");
    }
  }, []);

  const handleChange = (e) => {
    setNewBidProposal(e.target.value);
  };

  const handleProposeBid = (e) => {
    e?.preventDefault();
    const bidProposal = {
      senderId: currentUserId,
      proposedBid: newBidProposal,
      chatId: chat._id,
      gameId,
    };

    // send bid to database
    proposeBid(bidProposal);

    // send bid to socket server
    const receiverId = chat.members.find((id) => id !== currentUserId);
    setSendBidProposal({ ...bidProposal, receiverId });
  };

  const handleAcceptBid = () => {
    acceptBid({
      chatId: chat?._id,
      bidId: bidProposals[bidProposals?.length - 1]._id,
      gameId,
    });
    // console.log(isBidAccepted);
  };

  useEffect(() => {
    if (acceptBidData && !acceptBidError) setIsBidAccepted(true);
  }, [acceptBidData]);

  if (!bidProposals && (!hidden || window.screen.width >= 1280))
    return (
      <div className="flex flex-col justify-between m-2 text-white border-2 xl:h-full xl:m-0 messages">
        <Loader className=" h-[40vh] xl:h-full w-64 sm:w-80" />
      </div>
    );

  return (
    <>
      <div className="absolute visible font-semibold text-white bg-yellow-500 rounded xl:invisible right-3 top-3">
        <button className="px-4 py-2 " onClick={() => setHidden(!hidden)}>
          {hidden ? "bid" : <MdClose size={20} />}
        </button>
      </div>
      {(!hidden || window.screen.width >= 1280) && (
        <div className="flex flex-col justify-between h-full text-white border-2 xs:m-2 xl:m-0 messages">
          <div>
            <div className="text-2xl chat-heading">
              <p className="p-4 font-semibold text-white">Bid</p>
            </div>
            <div className="p-4">
              <div
                className={`bg-[#1F1F1F] p-2 py-3 rounded text-center ${
                  isBidAccepted ? "border-[#ffa800] border-2" : ""
                }`}
              >
                <h3>{isBidAccepted ? "Accepted" : "Current"} Bid</h3>
                <p className="text-4xl font-bold text-[#ffa800] mt-1">
                  {bidProposals[bidProposals.length - 1]?.proposedBid
                    ? "₹" + bidProposals[bidProposals.length - 1]?.proposedBid
                    : "-"}
                </p>
              </div>
              <div>
                <h3 className="mt-2 font-semibold">Bid History</h3>
                {bidProposals?.map((bid) => {
                  return (
                    <div className="mt-2 bid-history">
                      <p className="flex flex-row justify-between">
                        {bid.senderId === currentUserId ? "Proposed" : "Bid"}
                        <span>₹{bid.proposedBid}</span>
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {!isBidAccepted && (
            <div className="p-4 propose-bid">
              <div className="flex flex-row items-center justify-center w-full h-10 mb-2 rounded">
                <div className="flex flex-row items-center bg-[#1F1F1F] h-full rounded-l px-2">
                  <input
                    type="text"
                    placeholder="Propose new bid"
                    className="outline-none bg-[#1F1F1F] rounded w-40 sm:w-auto"
                    onChange={handleChange}
                    value={newBidProposal}
                  />
                  <span className="text-xs">INR</span>
                </div>
                <div
                  className="flex items-center justify-center h-full px-2 text-sm font-semibold text-black bg-white rounded-r hover:cursor-pointer"
                  onClick={handleProposeBid}
                >
                  Propose
                </div>
              </div>
              <button
                className={`items-center flex justify-center  text-center rounded h-10 w-full  ${
                  bidProposals[bidProposals.length - 1]?.senderId ===
                  currentUserId
                    ? " bg-[#72500c]"
                    : "hover:cursor-pointer bg-[#ffa800]"
                }`}
                onClick={handleAcceptBid}
                disabled={
                  bidProposals[bidProposals.length - 1]?.senderId ===
                  currentUserId
                }
              >
                Accept Bid
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default BidWindow;
