import client from "./client";

const getChats = () => client.get(`/chat`);

const postNewChat = (receiverId) => client.post("/chat", { receiverId });

const getMessages = (chatId) => client.get(`/message/${chatId}`);

const sendMessage = (data) => client.post("/message", data);

export default {
  getChats,
  postNewChat,
  getMessages,
  sendMessage,
};
