import React from "react";
import { useState, useEffect } from "react";
import useToken from "../../hooks/useToken";
import useApi from "../../hooks/useApi";
import agentApi from "../../api/agent";
import RegisterButton from "../../components/Buttons/RegisterButton";

import Lottie from "react-lottie";
import whiteLoader from "../../images/white-loader.json";
import { Link, useNavigate } from "react-router-dom";

const AgentSignup = () => {
  const [otp, setOtp] = useState("");
  // const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [invalid, setValidation] = useState("");
  const [nameError, setNameError] = useState("");

  // const handleChange = (e, i) => {
  //   console.log(e);
  //   setOtp(e.target.value);
  //   console.log("OTP :", otp);
  // };

  const { setToken } = useToken();
  const navigate = useNavigate();

  const {
    data: sent,
    error,
    loading,
    request: sendOTP,
  } = useApi(agentApi.sendOTP);
  const {
    data,
    error: err,
    loading: signing,
    request: signup,
  } = useApi(agentApi.signup);

  const handleOTP = () => {
    // if (isNaN(phoneNumber) || phoneNumber.length !== 10) {
    //   return alert("please enter a valid phone number");
    // }
    if (
      !String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      return alert("please enter a valid email");
    }
    if (name.length < 3) {
      return alert("please enter your full name");
    }
    sendOTP({ email: email, Name: name });
  };

  const handleLogin = async () => {
    // if (phoneNumber.length !== 10) {
    //   return alert("please enter correct number");
    // }
    if (
      !String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      return alert("please enter a valid email");
    }
    if (otp.length < 6) {
      return alert("please enter a valid OTP");
    }
    await signup({
      // phoneNumber: phoneNumber,
      email: email,
      otp: otp,
      Name: name,
    });
  };
  useEffect(() => {
    if (!err && !signing && data) {
      setToken(data.token);
      navigate("/agentdashboard", { replace: true });
    }
  }, [data]);
  useEffect(() => {
    if (error && !loading) {
      alert(sent);
    }
  }, [error, loading]);
  useEffect(() => {
    if (err && !signing) {
      alert(data);
    }
  }, [err, signing]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: whiteLoader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const emailValidation = () => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email) {
      setValidation("email is required");
    } else if (regex.test(email) === false) {
      setValidation("email is invalid");
    } else {
      setValidation("");
    }
  };
  useEffect(() => {
    if (email) {
      emailValidation(email);
    }
  }, [email]);
  const nameValidation = () => {
    if (!name) {
      setNameError("name is required");
    } else if (name.length < 3) {
      setNameError("name must be more than 2 characters");
    } else {
      setNameError("");
    }
  };
  useEffect(() => {
    if (name) {
      nameValidation(name);
    }
  }, [name]);
  return (
    <>
      <div className="w-screen h-screen pb-4 background-image">
        <div className="h-[64px] flex justify-between">
          <Link to={"/"}>
            <div className="flex w-full h-8 gap-2 mx-3 my-6 text-2xl font-bold text-yellow-500 sm:gap-1 sm:my-4 sm:h-10 sm:text-3xl sm:mx-8 font-heading">
              <img
                src={require("../../images/logo.png")}
                alt=""
                className="h-full text-yellow-500 "
              />
              PREMIUMCORES
            </div>
          </Link>
        </div>

        <div className="flex justify-center h-[85vh] text-white  ">
          <div className="w-full px-6 py-8 mx-8 my-auto rounded-md sm:mx-auto sm:w-auto signup-box ">
            <p className="text-2xl font-bold tracking-tighter lg:text-5xl sm:text-3xl font-heading">
              Agent Signup
            </p>
            <div className="grid mt-8 sm:grid-cols-2 gap-x-7 gap-y-3 ">
              <div className="grid">
                <label htmlFor="name">Full Name </label>
                <input
                  type="text"
                  name="name"
                  className="w-full h-12 input-field"
                  placeholder=" Enter full name"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  onBlur={() => nameValidation(name)}
                />
                <span className="text-xs text-red-400 ">{nameError}</span>
              </div>
              <div className="grid ">
                <label htmlFor="email">Email </label>

                <input
                  type="text"
                  name="email"
                  value={email}
                  className="w-full h-12 input-field"
                  placeholder=" Enter email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  autocomplete="off"
                  onBlur={() => emailValidation(email)}
                />
                <span className="text-xs text-red-400 ">{invalid}</span>
                {/* <input
                  maxLength={10}
                  type="text"
                  name="whatsappNumber"
                  value={phoneNumber}
                  className="w-full h-12 input-field"
                  placeholder=" Enter Whatsapp number"
                  onChange={(e) => {
                    if (e.target.value >= 0 && e.target.value <= 9999999999) {
                      setPhoneNumber(e.target.value);
                    }
                  }}
                /> */}
              </div>
            </div>

            {/* <div>
              <p>Terms & Conditions</p>
              <p>Y/N</p>
            </div> */}
            <div className="grid mt-3 sm:grid-cols-2 gap-x-7">
              <div className="grid gap-x-3">
                <label htmlFor="otp">Enter OTP </label>
                <input
                  className="w-full h-12 px-4 input-field "
                  name="otp"
                  placeholder=" Enter 6 digit OTP"
                  onChange={(e) => {
                    if (e.target.value >= 0 && e.target.value <= 999999) {
                      setOtp(e.target.value);
                    }
                  }}
                  value={otp}
                />
              </div>
              <div className="pt-6 ">
                <button
                  onClick={handleOTP}
                  type="button"
                  className="h-10 px-8 py-1 font-semibold bg-transparent bg-yellow-600 rounded-md"
                >
                  {!loading && !sent && " Send OTP"}

                  {loading && (
                    <div className="h-4">
                      <Lottie options={defaultOptions} width={100} />
                    </div>
                  )}

                  {sent && !loading && "Resend OTP"}
                </button>
              </div>
            </div>
            <div className="py-1 text-sm text-center text-yellow-600 ">
              {sent && !loading && !error && "OTP sent on your email"}
            </div>
            <div className="mx-auto text-sm text-center text-dark-600 sm:w-96">
              <p>
                Be an agent, turn your passion for gaming into a profitable
                career as an agent for buying, selling, and referring others to
                join in the buying and selling of gaming accounts.
              </p>
            </div>
            <div className="flex justify-end mt-4">
              <button
                className="w-full hover:scale-100"
                type="button"
                onClick={handleLogin}
              >
                {/* {!data && "Register"}
                {signing && (
                  <div className="h-4">
                    <Lottie options={defaultOptions} width={100} />
                  </div>
                )} */}
                <RegisterButton
                  loading={signing}
                  text="REGISTER"
                  className="w-full h-10 pt-2 font-semibold"
                />
              </button>
            </div>
            <div className="mt-4 text-center ">
              Already have an agent account ?{" "}
              <Link
                to={"/agentlogin"}
                className="font-semibold text-yellow-500 "
              >
                Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentSignup;
