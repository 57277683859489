import React from "react";
import { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
const BuyerStep = ({ currentStep, setCurrentStep }) => {
  const [loading, setLoading] = useState(true);

  setTimeout(() => {
    setLoading(false);
    setCurrentStep(currentStep + 1);
  }, 2000);
  return (
    <div>
      <h3 className="hidden text-2xl font-semibold text-center sm:block">
        Buyer steps
      </h3>
      <div className="flex justify-center mt-2 rounded bg-dark-700 h-[354px] sm:h-80">
        <div className="my-auto text-center">
          <ClipLoader color="white" size={100} loading={loading} />
          <p>Buyer is .....</p>
        </div>
      </div>
    </div>
  );
};

export default BuyerStep;
