import React from "react";
import { MdOutlineClose } from "react-icons/md";

const Notification = ({ setShowNotification }) => {
  const data = [1, 2, 3, 4, 5, 6, 7];
  return (
    <div className="right-0 z-50 h-screen p-4 sm:absolute sm:w-4/5 md:w-96 sm:h-[594px] notification-box lg:w-2/5 xl:w-2/6">
      <div className="flex justify-between font-medium ">
        <h3>Notification</h3>
        <button type="button" onClick={() => setShowNotification(false)}>
          <span className="text-2xl">
            <MdOutlineClose size={28} />
          </span>
        </button>
      </div>
      <div className="h-[93%] mt-4 overflow-y-scroll  scrollbar">
        {data.map((data, index) => {
          return (
            <div className="p-2 mb-2 text-sm rounded bg-chat-backgound ">
              <div className="flex justify-between">
                <h4>Notification</h4>
                <p>3rd October 22:00</p>
              </div>
              <div>
                <p className=" text-dark-gray-300">
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint. Velit officia consequat duis enim velit mollit.
                  Velit officia consequat duis enim velit mollit.
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Notification;
