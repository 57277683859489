import React from "react";
import { Link, useNavigate } from "react-router-dom";
const ReSchedule = ({ data, index, isSeller }) => {
  const navigate = useNavigate();

  return (
    <div
      key={data._id}
      className="mb-3 duration-200 xs:mb-6 md:mb-0 sm:flex md:block lg:flex sm:flex-row schedule-div md:hover:scale-105"
    >
      <div className="w-full sm:w-1/3 md:w-full lg:w-1/3 sm:rounded-l-xl sm:rounded-r-none rounded-t-xl md:rounded-t-xl md:rounded-b-none lg:rounded-r-none lg:rounded-l-xl h-52 sm:h-auto md:h-52 lg:h-auto">
        <div className="h-full">
          <img
            src={data._doc.Thumbnail}
            // src={require("../../../../images/pubgBg.png")}
            className="self-center w-full h-full sm:rounded-l-xl sm:rounded-r-none rounded-t-xl md:rounded-t-xl md:rounded-b-none lg:rounded-r-none lg:rounded-l-xl"
          />
        </div>
      </div>
      <div className="p-3 sm:w-2/3 md:w-full lg:w-2/3">
        <div className="flex flex-row justify-between font-semibold ">
          <div className="flex flex-row gap-x-4 ">
            <p className="text-lg ">{data.game}</p>
            <Link to={`/schedule/account/${data._doc.gameAccountId}`}>
              <button className="px-2 font-normal rounded-full bg-neutral-700 h-fit">
                view details
              </button>
            </Link>
          </div>
          <p className="text-lg ">₹{data.price}</p>
        </div>
        <div className="mt-1 xs:mt-3">
          {" "}
          <h4 className="font-semibold text-yellow-600">Event Confirmed</h4>
          <p>
            XRoom event is sheduled on{" "}
            {new Date(data._doc.proposedSchedule.date).toDateString()} at{" "}
            {data._doc.proposedSchedule.time.hours}:
            {data._doc.proposedSchedule.time.minute} PM by Premium cores{" "}
          </p>
        </div>
        <div className="flex text-neutral-500">
          Event will start in{" "}
          <p className="ml-1 font-semibold text-yellow-600">2 hrs 20 mins</p>{" "}
        </div>
        <button
          className="w-full py-2 mt-2 border-2 border-yellow-500 solid"
          onClick={() => {
            if (isSeller) navigate("/sxroom", { state: data });
            else if (!isSeller) navigate("/bxroom", { state: data });
          }}
        >
          {/* Ask for reschedule */}
          Go to XRoom
        </button>
      </div>
    </div>
  );
};

export default ReSchedule;
