import React from "react";
import { useEffect } from "react";
import useApi from "../../hooks/useApi";
import agentApi from "../../api//agent";
import Loader from "../components/Loader";
import { useLocation, Navigate, useNavigate } from "react-router-dom";
import useToken from "../../hooks/useToken";
import { useState } from "react";
import { IoMdWallet } from "react-icons/io";
import {
  MdCopyAll,
  MdOutlineAccountBalance,
  MdOutlineNotifications,
} from "react-icons/md";
import Notification from "../../screens/components/Notification";

const AgentDashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();

  function handleNavigate() {
    navigate(-1, { beforeNavigate: preventNavigation });
  }

  function preventNavigation() {
    return false;
  }
  useEffect(() => {
    handleNavigate();
  }, [window.location.pathname]);

  const { token, removeToken } = useToken();
  const [code, setCode] = useState();
  const [isCopied, setIsCopied] = useState(false);
  const [earnings, setEarnings] = useState([]);
  const [showNotification, setShowNotification] = useState(false);

  const { data, error, loading, request: getAgent } = useApi(agentApi.getAgent);
  const {
    data: earning,
    error: er,
    loading: fetching,
    request: getEarnings,
  } = useApi(agentApi.getEarnings);

  const {
    data: referral,
    error: err,
    loading: generating,
    request: getReferralCode,
  } = useApi(agentApi.getReferralCode);

  useEffect(() => {
    getAgent();
    getEarnings();
  }, [token]);

  useEffect(() => {
    setEarnings(earning?.earnings);
  }, [earning?.earnings]);

  useEffect(() => {
    if (referral) {
      setCode(referral);
    }
    if (data?.referral) {
      setCode(data.referral);
    }
  }, [referral, data?.referral]);

  const referralCode = () => {
    if (token) {
      getReferralCode();
    }
  };

  const handleCopy = async () => {
    if (!code && !data?.referral) {
      return alert("please generate referral code first");
    }
    try {
      await navigator.clipboard.writeText(code ? code : data?.referral);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 5000);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  if (error) {
    <Navigate to="/agentlogin" replace state={{ from: location }} />;
  }
  const logout = () => {
    removeToken();
    window.location.reload();
  };
  return (
    <>
      <div className="mx-4 mt-3 text-white sm:mx-16">
        <div className="flex justify-between h-16 py-4">
          <div className="flex gap-x-1 ">
            <img
              src={require("../../images/logo.png")}
              alt="premiumcores"
              className="w-9"
            />
            <p className="pb-2 text-2xl font-bold text-yellow-600 scale-x-95 scale-y-110 font-heading xl:text-3xl">
              PREMIUMCORES
            </p>
          </div>
          <div>
            <button
              onClick={() => {
                setShowNotification(!showNotification);
              }}
            >
              <MdOutlineNotifications className="relative w-8 h-8" />
            </button>
          </div>
        </div>
        {showNotification && (
          <div className="absolute top-0 z-50 -ml-5 text-white sm:relative">
            <Notification setShowNotification={setShowNotification} />
          </div>
        )}
      </div>
      <div className="h-full min-h-screen px-5 py-6 text-white md:px-20">
        {/* <div className="flex justify-end ">
          <div className="w-6 h-6 rounded-full md:w-8 md:h-8 bg-thunderbird-red"></div>
        </div> */}
        <div className="px-4 pt-10 my-3 rounded-md py-7 sm:px-16 bg-dark-700">
          <h1 className="text-3xl font-bold ">
            Welcome to your agent dashboard
          </h1>
          <div className="flex justify-between mt-5">
            {!code && !data?.referral ? (
              <button
                className="px-10 py-1 bg-green-650"
                type="button"
                onClick={referralCode}
              >
                Generate Referral Code
              </button>
            ) : (
              <>
                Share referral Code and earn on every successfull transactions
              </>
            )}
            <button
              className="h-8 px-3 py-1 font-semibold bg-yellow-600 rounded-md sm:px-10 "
              type="button"
              onClick={logout}
            >
              Logout
            </button>
          </div>
          <div className="grid gap-y-8">
            {/* <div className="justify-between xl:flex ">
            <div className="flex ">
              <p>Link :</p>
              <div className="flex gap-x-3">
                <p className="h-6 border-b-2 border-black border-dotted w-[20vh] xl:w-[60vw] "></p>
                <button>(Copy)</button>
              </div>
            </div>
            <div>
              <p>Validity 59D 23hr+</p>
            </div>
          </div> */}
            {(data?.referral || referral) && (
              <div className="sm:justify-between mt-7 xl:flex ">
                <div className="sm:flex ">
                  <p className="font-semibold text-yellow-500 ">
                    Referral Code :
                  </p>
                  <div className="flex gap-x-3">
                    <p className="h-6 px-10 font-semibold text-center border-b-2 border-dotted border-cyan-350 text-yellow-650 ">
                      {code}
                    </p>
                    <button
                      type="button"
                      onClick={handleCopy}
                      className="flex px-3 rounded-md gap-x-1 bg-dark-600"
                    >
                      <span className="py-1 ">
                        <MdCopyAll size={20} />
                      </span>
                      {isCopied ? "copied" : "copy"}
                    </button>
                  </div>
                </div>
                <div>
                  <p>Validity 59D 23hr+</p>
                </div>
              </div>
            )}
            <div className="flex justify-end mt-7">
              <button
                className="px-10 py-1 rounded-md bg-cyan-350 "
                type="button"
              >
                Control Center
              </button>
            </div>
          </div>
        </div>
        {data?.balance && (
          <div>
            <div className="sm:flex ">
              <p className="flex gap-x-2 ">
                <span className=" text-amber-600">
                  <IoMdWallet size={28} />
                </span>{" "}
                Wallet Balance :
              </p>
              <div className="flex gap-x-2">
                <p className="px-20 font-bold text-center text-green-500 border-b-2 border-dotted border-cyan-350">
                  {data?.balance} INR.
                </p>
                <button
                  type="button"
                  className="flex px-3 font-bold text-orange-600 rounded bg-cyan-350"
                >
                  <span className="py-1 ">
                    <MdOutlineAccountBalance size={20} />
                  </span>{" "}
                  Withdraw
                </button>
              </div>
            </div>
          </div>
        )}
        <hr className="my-5 border-dark-gray-300 bg-dark-gray-300" />
        {earnings?.length > 0 ? (
          <>
            <div className="font-semibold">Referrals</div>
            <hr className=" border-dark-gray-300 bg-dark-gray-300" />
            <div className="w-full overflow-x-scroll scrollbar-hide ">
              <div className="flex min-w-[800px] w-full bg-yellow-650 text-dark-700 font-bold border-dark-gray-300 border-b-2  ">
                <span className="w-16 text-center ">S.No.</span>
                <div className="grid w-full grid-cols-4 text-center ">
                  <p>Name</p>
                  <p>Id_Number</p>
                  <p>Role</p>
                  <p>Earned</p>
                  {/* <div className="text-center ">
              <span className="px-8 py-1 bg-blue-450" type="button">
                chat
              </span>
            </div>
            <div className="flex justify-between ">
              status <p className="px-2 py-2 rounded-full bg-red-950"> i</p>
            </div> */}
                </div>
              </div>
              <div className="grid max-h-[80vh] min-w-[800px] w-full grid-cols-1 overflow-y-scroll  scrollbar-hide border-b-2 border-x-2 border-dark-100 ">
                {earnings?.map((customer, index) => {
                  return (
                    <div
                      className={`flex   w-full border-b border-dark-100 ${
                        (index + 1) % 2 === 0 ? "bg-dark-600" : " bg-dark-700"
                      } `}
                      key={customer._id}
                    >
                      <span className="w-16 text-center">{index + 1}.</span>
                      <div className="grid w-full grid-cols-4 text-sm text-center">
                        <p>{customer.name}</p>
                        <p>{customer.id}</p>
                        <p>{customer.role}</p>
                        <p className="font-semibold text-success">
                          {customer.earned || customer.amount} INR.
                        </p>
                        {/* <div className="text-center ">
                    <span className="px-8 py-1 bg-blue-450" type="button">
                      chat
                    </span>
                  </div>
                  <div className="flex justify-between ">
                    status{" "}
                    <p className="px-2 py-2 rounded-full bg-red-950"> i</p>
                  </div> */}
                      </div>
                    </div>
                  );
                })}
                <div
                  className={
                    "grid grid-cols-4 justify-end w-full border-b border-dark-100 bg-dark-700"
                  }
                >
                  <div></div>
                  <div></div>
                  <div></div>
                  <div className="flex justify-center px-6 gap-x-7">
                    <p className="text-yellow-500 ">Total : </p>
                    <p className="font-semibold text-green-600">
                      {data?.balance} INR.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="flex justify-center text-white h-14 bg-neutral-800">
              <p className="my-auto ">No referrals yet</p>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AgentDashboard;
