import React from "react";
import { Link } from "react-router-dom";

import scheduleApi from "../../../../api/schedule";
import useApi from "../../../../hooks/useApi";

const Waiting = ({ val, data, index, isSeller, getSchedule }) => {
  console.log("data : ", data);
  const {
    data: withdrawData,
    error,
    loading,
    request: withdrawSchedule,
  } = useApi(scheduleApi.withdrawSchedule);

  if (!loading && error)
    alert("Something went wrong. Couldn't withdraw schedule.");

  const withdrawHandler = () => {
    withdrawSchedule(data._doc._id, isSeller);
    if (!error) getSchedule(isSeller);
  };

  return (
    <div
      key={val}
      className="mb-3 duration-200 xs:mb-6 md:mb-0 sm:flex md:block lg:flex sm:flex-row schedule-div md:hover:scale-105"
    >
      <div className="w-full sm:w-1/3 md:w-full lg:w-1/3 sm:rounded-l-xl sm:rounded-r-none rounded-t-xl md:rounded-t-xl md:rounded-b-none lg:rounded-r-none lg:rounded-l-xl h-52 sm:h-auto md:h-52 lg:h-auto">
        <div className="h-full">
          <img
            src={data._doc.Thumbnail}
            // src={require("../../../../images/pubgBg.png")}
            className="self-center w-full h-full sm:rounded-l-xl sm:rounded-r-none rounded-t-xl md:rounded-t-xl md:rounded-b-none lg:rounded-r-none lg:rounded-l-xl"
          />
        </div>
      </div>
      <div className="p-3 sm:w-2/3 md:w-full lg:w-2/3">
        <div className="flex flex-row justify-between font-semibold ">
          <div className="flex flex-row gap-x-4 ">
            <p className="text-lg ">{data.game}</p>
            <Link to={`/schedule/account/${data._doc.gameAccountId}`}>
              <button className="px-2 font-normal rounded-full bg-neutral-700 h-fit">
                View Details
              </button>
            </Link>
          </div>
          <p className="text-lg ">₹{data.price}</p>
        </div>
        <p className="py-2 xs:py-5 ">
          XRoom event is sheduled on{" "}
          {new Date(data._doc.proposedSchedule.date).toDateString()} at{" "}
          {data._doc.proposedSchedule.time.hours}:
          {data._doc.proposedSchedule.time.minute < 10 && "0"}
          {data._doc.proposedSchedule.time.minute}{" "}
          <span>{data._doc.proposedSchedule.time.PM ? "PM" : "AM"}</span> by
          Premium cores{" "}
        </p>
        <div className="grid grid-cols-2 gap-5 font-semibold">
          <button className="py-1 rounded-sm xs:py-2 bg-dark-gray-300 text-white-50">
            Waiting for approval
          </button>
          <button
            className="py-1 border-2 border-yellow-500 rounded-sm xs:py-2 solid"
            type="button"
            onClick={withdrawHandler}
          >
            Withdraw event
          </button>
        </div>
        <div className="mt-2 xs:flex text-neutral-500">
          Time left for event is{" "}
          <p className="ml-1 font-semibold text-yellow-600">2 hrs 20 mins </p>
        </div>
      </div>
    </div>
  );
};

export default Waiting;
