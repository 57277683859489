import React, { useEffect } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import TextField from "../utilities/TextField";
import ArrayInput from "../components/ArrayInput";
import RegisterButton from "../components/Buttons/RegisterButton";
import { Link } from "react-router-dom";
import { IoMdEyeOff, IoMdEye } from "react-icons/io";
import { HiOutlineCalendar } from "react-icons/hi";
import { useState, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useApi from "../hooks/useApi";
import authApi from "../api/auth";
import { useNavigate } from "react-router-dom";

const CreateAccount = () => {
  const [eye, setEye] = useState(false);
  const [eyeOpen, setEyeOpen] = useState(false);
  // const [verified, setVerified] = useState(false);
  const [startDate, setStartDate] = useState();
  const [date, setDate] = useState();
  const [email, setEmail] = useState(false);
  // const [isOtpSent, setIsOtpSent] = useState();
  // const [phoneNumber, setPhoneNumber] = useState();
  // const [otp, setOtp] = useState();
  // const [GamesInput, setGamesInput] = useState("");
  // const [GamesArray, setGamesArray] = useState([]);
  // const [isGamesKeyReleased, setIsGamesKeyReleased] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [passwordLenError, setPasswordLenError] = useState(false);

  const validate = Yup.object({
    firstName: Yup.string()
      .min(2, "must be more than 2 character")
      .max(50, "Must be 50 characters or less")
      .required("Required"),
    lastName: Yup.string()
      .min(2, "must be more than 2 character")
      .max(50, "Must be 50 characters or less")
      .required("Required"),
    // username: Yup.string()
    //   .min(2, "must be more than 2 characters")
    //   .max(16, "Must be 16 characters or less")
    //   .required("Required"),
    // dob: Yup.date().required("Required"),
    // gamesPlayed: Yup.string().required("Select an Option"),
    // phoneNumber: Yup.number()
    //   .min(8, "invalid phone number")
    //   .required("Required"),
    // whatsappNumber: Yup.string()
    //   .min(8, "invalid whatsapp number")
    //   .required("Required"),
    // telegramId: Yup.string().required("Please enter telegram ID"),
    email: Yup.string()
      .email("Email is invalid!")
      .required("Email is required"),
    // password: Yup.string()
    //   .min(6, "password length must be greater than or equal to 6")
    //   .required("Required"),
    // confirmPassword: Yup.string()
    //   .oneOf([Yup.ref("password"), null], "password must match")
    //   .required("confirm password is required"),
    agree: Yup.boolean().oneOf([true], "You must agree terms and conditions"),
  });

  const navigate = useNavigate();

  const { data, error, loading, request: signUp } = useApi(authApi.signup);

  // const {
  //   data: sendOtpData,
  //   loading: sendOtpLoading,
  //   request: sendOtp,
  // } = useApi(authApi.sendOtp);

  // const {
  //   data: resendOtpData,
  //   loading: resendOtpLoading,
  //   request: resendOtp,
  // } = useApi(authApi.resendOtp);

  // const {
  //   data: verifyOtpData,
  //   loading: verifyOtpLoading,
  //   request: verifyOtp,
  // } = useApi(authApi.verifyOtp);

  // const sendOtpHandler = () => {
  //   if (!phoneNumber) return alert("Please enter phone number.");
  //   console.log(phoneNumber);
  //   sendOtp(phoneNumber);
  //   console.log(sendOtpData);
  //   if (sendOtpData && !sendOtpLoading && !sendOtpData?.errorMessage)
  //     setIsOtpSent(true);
  // };

  // const resendOtpHandler = () => {
  //   if (!phoneNumber) return alert("Please enter phone number.");
  //   resendOtp(phoneNumber);
  //   console.log(resendOtpData);
  // };

  // const verifyOtpHandler = () => {
  //   if (!otp) return alert("Please enter an otp.");
  //   verifyOtp({ phoneNumber, otp });
  //   if (!verifyOtpLoading && verifyOtpData === "otp_verified")
  //     setIsOtpVerified(true);
  // };

  const submitHandler = (val) => {
    if (!date) return alert("Please enter your dob.");
    else if (!password) return alert("Please enter password.");
    else if (!confirmPassword) return alert("Please confirm your password.");

    signUp({ ...val, dob: date, password });
    setEmail(val.email);
  };

  useEffect(() => {
    if (!error && !loading && data) {
      navigate("/verifyotp", { state: { email } });
    } else if (error && !loading) {
      alert(data.message);
    }
  }, [data, loading]);

  const show = () => {
    setEye(!eye);
  };

  const showConfirmPassword = () => {
    setEyeOpen(!eyeOpen);
  };

  const datepickerRef = useRef();

  function handleClickDatepickerIcon() {
    const datepickerElement = datepickerRef.current;
    datepickerElement.setFocus(true);
  }

  return (
    <>
      <div className="w-screen min-h-screen pb-4 background-image">
        <div className="h-[64px] flex justify-between">
          <Link to={"/"}>
            <div className="flex w-full h-8 gap-1 mx-3 my-4 text-2xl font-bold text-yellow-500 sm:h-10 sm:text-3xl sm:mx-8 font-heading">
              <img
                src={require("../images/logo.png")}
                alt=""
                className="h-full text-yellow-500 "
              />
              PREMIUMCORES
            </div>
          </Link>
          {/* <button>
            <ShadowButton
              className="mx-8 my-4 text-2xl text-white bg-yellow-700 w-[188px] h-9"
              text="Get Started"
            />
          </button> */}
        </div>
        <div>
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              // username: "",
              // gamesPlayed: "",
              // phoneNumber: "",
              // otp: "",
              // telegramId: "",
              // password: "",
              // confirmPassword: "",
              agree: false,
            }}
            validationSchema={validate}
            onSubmit={submitHandler}
          >
            {(formik) => (
              <div className="md:w-[648px] w-[93%] xs:w-[85%] sm:w-[80%]  mx-auto mt-3 sm:mt-6 md:mt-10  sm:mb-7 text-white rounded signup-box p-4 h-full">
                <p className="text-2xl font-bold tracking-tighter lg:text-5xl sm:text-3xl md:text-4xl font-heading">
                  CREATE ACCOUNT
                </p>
                <Form className="mt-4 field-group">
                  <div className="grid sm:grid-cols-2 gap-y-1 gap-x-4 h-[40vh] xs:h-[55vh] sm:h-auto sm:overflow-visible overflow-y-scroll scrollbar">
                    <TextField
                      className=" text-white-grey"
                      label="First name"
                      name="firstName"
                      type="text"
                      placeholder=" Enter your first name"
                    />
                    <TextField
                      className=" text-white-grey"
                      label="Last name"
                      name="lastName"
                      type="text"
                      placeholder=" Enter your last name"
                    />
                    {/* <TextField
                      className="text-white-grey"
                      label="Username"
                      name="username"
                      type="text"
                      placeholder=" Enter your username"
                    /> */}

                    {/* <div className="">
                      <label htmlFor="phoneNumber" className=" text-white-grey">
                        Phone no.
                      </label>
                      <div className="flex input-field  h-[52px]">
                        <input
                          className="w-full bg-transparent "
                          label="Phone no."
                          name="phoneNumber"
                          type="tel"
                          placeholder=" Enter phone no."
                          onChange={(e) => {
                            setPhoneNumber(e.target.value);
                          }}
                          value={phoneNumber}
                        />

                        {isOtpVerified ? (
                          <p className=" text-base py-1 text-[#00B907]">
                            verified
                          </p>
                        ) : isOtpSent ? (
                          <button
                            type="button"
                            onClick={sendOtpHandler}
                            className="py-1 text-base w-36"
                          >
                            <p>Resend OTP</p>
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="py-1 text-base w-36"
                            onClick={sendOtpHandler}
                          >
                            <p>Send OTP</p>
                          </button>
                        )}
                      </div>
                    </div> */}
                    {/* <div>
                      <label htmlFor="otp" className=" text-white-grey">
                        Enter OTP
                      </label>
                      <div className="flex input-field h-[52px]">
                        <input
                          className="w-full bg-transparent "
                          label="Enter OTP"
                          name="otp"
                          type="otp"
                          placeholder=" OTP sent to your phone number"
                          onChange={(e) => {
                            setOtp(e.target.value);
                          }}
                          value={otp}
                        />
                        {isOtpSent && (
                          <button
                          className="ml-2 w-28"
                          type="button"
                          disabled={isOtpVerified}
                          onClick={verifyOtpHandler}
                          >
                          Verify OTP
                          </button>
                          )}
                          </div>
                        </div> */}
                    <TextField
                      className="mt-1 mb-0 text-white-grey"
                      label="Email ID"
                      name="email"
                      type="text"
                      placeholder=" Enter email ID"
                    />
                    <div className="grid grid-cols-1 mb-0">
                      <label
                        htmlFor="dob"
                        className="h-4 mb-2 text-base text-white-grey"
                      >
                        {" "}
                        DOB
                      </label>
                      <div className=" flex input-field h-[52px] ">
                        <DatePicker
                          ref={datepickerRef}
                          placeholderText=" Select date"
                          selected={startDate}
                          onChange={(date) => {
                            setStartDate(date);
                            setDate(date.toLocaleDateString());
                          }}
                          className="w-full mt-1 bg-transparent"
                          showYearDropdown
                          dateFormat="dd/MM/yyyy"
                          maxDate={new Date()}
                          scrollableYearDropdown
                          yearDropdownItemNumber={50}
                          disabledKeyboardNavigation
                          onFocus={(e) => e.target.blur()}
                        />
                        <button
                          className="text-xl "
                          type="button"
                          onClick={() => handleClickDatepickerIcon()}
                        >
                          <HiOutlineCalendar />
                        </button>
                      </div>
                    </div>
                    {/* 
                    <TextField
                      className="mt-1 mb-0 text-white-grey"
                      label="Telegram ID"
                      name="telegramId"
                      type="text"
                      placeholder=" Enter your telegram ID"
                    /> */}
                    {/* </div> */}
                    {/* <div>
                      <ArrayInput
                        label="Games Played"
                        name="gamesPlayed"
                        className="w-full h-full mt-1"
                        input={GamesInput}
                        setInput={setGamesInput}
                        tags={GamesArray}
                        setTags={setGamesArray}
                        isKeyReleased={isGamesKeyReleased}
                        setIsKeyReleased={setIsGamesKeyReleased}
                      />
                    </div> */}

                    {/* <div className="sm:grid sm:grid-cols-2 sm:gap-4"> */}
                    <div>
                      <label
                        htmlFor="password"
                        className=" text-white-grey text-[16px] h-4 mb-[2px]"
                      >
                        Password
                      </label>
                      <div className="flex flex-row p-0 mt-[-1px] input-field h-[52px]">
                        <input
                          className="bg-transparent h-[52px] rounded-[2px] w-[100%] p-2"
                          name="password"
                          type={eye ? "text" : "password"}
                          placeholder=" Enter password"
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                          onBlur={() => {
                            if (password !== confirmPassword)
                              setPasswordMatch(false);
                            else if (password === confirmPassword)
                              setPasswordMatch(true);
                            if (password.length < 6) setPasswordLenError(true);
                            else if (password.length >= 6)
                              setPasswordLenError(false);
                          }}
                        />
                        <button onClick={show} type="button" className="p-3 ">
                          {eye ? (
                            <IoMdEye className="text-xl" />
                          ) : (
                            <IoMdEyeOff className="text-xl" />
                          )}
                        </button>
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="confirmPassword"
                        className=" text-white-grey text-[16px] h-4 mb-[2px]"
                      >
                        Confirm Password
                      </label>
                      <div className="flex flex-row p-0 mt-[-1px] input-field h-[52px]">
                        <input
                          className="bg-transparent h-[52px] rounded-[2px] w-[100%] p-2"
                          name="confirmPassword"
                          type={eyeOpen ? "text" : "password"}
                          placeholder=" Enter password"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          value={confirmPassword}
                          onBlur={() => {
                            if (password !== confirmPassword)
                              setPasswordMatch(false);
                            else if (password === confirmPassword)
                              setPasswordMatch(true);
                          }}
                        />
                        <button
                          onClick={showConfirmPassword}
                          type="button"
                          className="p-3"
                        >
                          {eyeOpen ? (
                            <IoMdEye className="text-xl" />
                          ) : (
                            <IoMdEyeOff className="text-xl" />
                          )}
                        </button>
                      </div>
                    </div>

                    {passwordLenError && (
                      <p className="text-xs text-red-600 sm:text-sm">
                        Password must be of atleast 6 characters.
                      </p>
                    )}

                    {!passwordMatch && (
                      <p className="text-xs text-red-600 sm:text-sm">
                        Passwords should match.
                      </p>
                    )}
                  </div>
                  <div className="mt-4 text-sm text-center sm:text-base">
                    <Field
                      className="text-center"
                      type="checkbox"
                      name="agree"
                    />
                    <label htmlFor="agree" className="ml-3">
                      I agree to all terms and conditions{" "}
                      <a href="#" className="text-[#04D8D8] underline">
                        {" "}
                        terms & conditions
                      </a>
                    </label>
                  </div>
                  <div className="text-center ">
                    <ErrorMessage
                      component="div"
                      name="agree"
                      className="text-xs text-red-600 sm:text-sm "
                    />
                  </div>
                  {error && (
                    <p className="text-xs text-red-600 sm:text-sm">
                      {data.message}
                    </p>
                  )}
                  <button
                    type="Submit"
                    className="w-[100%] hover:scale-100 duration-200 mt-4"
                    disabled={!passwordMatch || loading}
                    onClick={() => {
                      if (
                        formik.errors.email ||
                        formik.errors.firstName ||
                        formik.errors.lastName ||
                        formik.errors.agree
                      ) {
                        alert("please fill details correctly");
                      }
                    }}
                  >
                    <RegisterButton
                      className="h-12 p-2 text-lg font-semibold "
                      text="CREATE ACCOUNT"
                      loading={loading}
                    />
                  </button>
                  <div className="mt-2 text-center sm:mt-4">
                    Already have an account?{" "}
                    <Link to="/login" className="text-[#FFA800]">
                      {" "}
                      Login{" "}
                    </Link>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
export default CreateAccount;
