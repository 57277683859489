import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { TbBell } from "react-icons/tb";

import { DropdownMenu } from "./DropDown";
import AuthContext from "../utilities/context";

const Navbar = ({ isSeller }) => {
  const [open, setOpen] = useState(false);

  const location = useLocation();

  const { userData } = useContext(AuthContext);

  return (
    <div className=" bg-gradient-to-l from-[#290000] to-[#0c0000]">
      <div className="navbar flex justify-between items-center px-6 md:px-12 pt-5 w-full">
        <div className="flex justify-center items-center space-x-10">
          <Link className="logo text-white font-body font-light" to="/">
            Premium<span className="font-bold">Core</span>
          </Link>
          <ul className="flex space-x-10 text-white font-body">
            <Link
              className={`hover:brightness-75 hover:cursor-pointer py-1 px-2 rounded ${
                location.pathname === "/dashboard" ? "bg-red-800" : ""
              }`}
              to="/dashboard"
            >
              Dashboard
            </Link>
            <Link
              className={`hover:brightness-75 hover:cursor-pointer py-1 px-2 rounded ${
                location.pathname === "/chats" ? "bg-red-800" : ""
              }`}
              to="/chats"
            >
              Chats
            </Link>
            <Link
              className={`hover:brightness-75 hover:cursor-pointer py-1 px-2 rounded ${
                location.pathname === "/requests" ? "bg-red-800" : ""
              }`}
              to="/requests"
            >
              Requests
            </Link>
            <Link
              className={`hover:brightness-75 hover:cursor-pointer py-1 px-2 rounded ${
                location.pathname === "/announcements" ? "bg-red-800" : ""
              }`}
              to={isSeller ? "/accounts" : "/announcements"}
            >
              {isSeller ? "Accounts" : "Announcements"}
            </Link>
          </ul>
        </div>
        <div className="flex justify-center items-center">
          <TbBell
            size={28}
            color="white"
            className="mr-5 hover:cursor-pointer"
          />
          <div
            className="profile flex hover:brightness-125 cursor-pointer text-sm items-center"
            onClick={() => setOpen(!open)}
          >
            <div>
              <img
                className="profileImg w-11 h-11 rounded-3xl mr-2"
                src={require("../images/5774918.jpg")}
                alt=""
              />
            </div>
            <div className="profile-name hidden md:block text-white font-body ">
              <div>{userData?.fullname}</div>
              {userData?.isSeller && (
                <span className="profile-type opacity-40">Seller</span>
              )}
            </div>
            {open && <DropdownMenu />}
          </div>
        </div>
      </div>
      <hr className="mt-3 w-[95%]  m-auto opacity-25" />
    </div>
  );
};

export default Navbar;
