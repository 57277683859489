import React from "react";
import { useState } from "react";

const SecureAccount = ({ currentStep, setCurrentStep }) => {
  const [fb, setFb] = useState({ id: "", password: "" });
  const [twitter, setTwitter] = useState({ id: "", password: "" });
  const [email, setEmail] = useState({ id: "", password: "" });
  const [revealed, setReveal] = useState(false);
  const [twRevealed, setTwReveal] = useState(false);
  const [emRevealed, setEmReveal] = useState(false);
  const [fbDone, setFbDone] = useState(false);
  const [done, setDone] = useState(false);
  const [done2, setDone2] = useState(false);
  const [OTP, setOTP] = useState(false);
  const [fetch, setFetch] = useState(false);

  return (
    <div className="font-semibold ">
      <h3 className="hidden text-2xl text-center sm:block">
        Securing accounts
      </h3>
      <div className="p-3 mt-1 overflow-y-scroll rounded md:h-[34vh] lg:h-80 h-[354px] sm:h-80 bg-dark-700 scrollbar">
        <p>Facebook</p>
        <div className="sm:flex gap-x-7">
          <div className="grid xs:w-9/12 gap-y-2 sm:grid-cols-2 gap-x-7">
            <input
              type="text"
              name="fb.id"
              placeholder="ID"
              className="w-full h-12 px-1 rounded xs:px-3 bg-light-grey"
              onChange={(e) => {
                setFb({ id: e.target.value, password: fb.password });
              }}
            />

            <input
              type="password"
              name="fb.password"
              placeholder="Password"
              className="w-full h-12 px-1 rounded xs:px-3 bg-light-grey"
              onChange={(e) => {
                setFb({ id: fb.id, password: e.target.value });
              }}
            />
          </div>
          <button
            className={`px-4 rounded mt-2 sm:mt-0 py-1 sm:w-3/12  ${
              fbDone
                ? " bg-success"
                : revealed
                ? "text-sm font-normal bg-yellow-500"
                : "bg-yellow-500"
            }`}
            onClick={() => {
              revealed ? setFbDone(true) : setReveal(!revealed);
            }}
          >
            {done
              ? "Done"
              : fbDone
              ? "Secure"
              : revealed
              ? "Click here once you are done changing the password"
              : "Reveal ID & password"}
          </button>
        </div>
        <p className="text-sm font-normal text-yellow-500">
          ** Change the ID and password of the facebook account to secure the
          account
        </p>

        <p className="mt-4 ">Twitter</p>
        <div className="sm:flex gap-x-7">
          <div className="grid xs:w-9/12 sm:grid-cols-2 gap-y-2 gap-x-7">
            <input
              type="text"
              name="twitter.id"
              placeholder="ID"
              className="w-full h-12 px-1 rounded xs:px-3 bg-light-grey"
              onChange={(e) => {
                setTwitter({ id: e.target.value, password: twitter.password });
              }}
            />

            <input
              type="password"
              name="twitter.password"
              placeholder="Password"
              className="w-full h-12 px-1 rounded xs:px-3 bg-light-grey"
              onChange={(e) => {
                setTwitter({ id: twitter.id, password: e.target.value });
              }}
            />
          </div>
          <button
            className={`px-4 mt-2 sm:mt-0 py-1 rounded sm:w-3/12 ${
              done
                ? " bg-success"
                : fbDone && !twRevealed
                ? "bg-yellow-500"
                : "bg-light-silver"
            }`}
            onClick={() => {
              setTwReveal(!twRevealed);
            }}
          >
            {done ? "Done" : twRevealed ? "Revealed" : "Reveal ID & password"}
          </button>
        </div>
        <p className="text-sm font-normal text-yellow-500">
          ** Change the ID and password of the twitter account to secure the
          account
        </p>

        {done && (
          <>
            <p className="mt-4 ">Email</p>
            <div className="sm:flex gap-x-7">
              <div className="grid xs:w-9/12 gap-y-2 sm:grid-cols-2 gap-x-7">
                <input
                  type="text"
                  name="email.id"
                  placeholder="ID"
                  className="w-full h-12 px-1 rounded xs:px-3 bg-light-grey"
                  onChange={(e) => {
                    setEmail({ id: e.target.value, password: email.password });
                  }}
                />

                <input
                  type="password"
                  name="email.password"
                  placeholder="Password"
                  className="w-full h-12 px-1 rounded xs:px-3 bg-light-grey"
                  onChange={(e) => {
                    setEmail({ id: email.id, password: e.target.value });
                  }}
                />
              </div>
              <button
                className={`px-4 mt-2 sm:mt-0 py-1 rounded sm:w-3/12 ${
                  fbDone && !emRevealed ? "bg-yellow-500" : "bg-light-silver"
                }`}
                onClick={() => {
                  setEmReveal(!emRevealed);
                }}
              >
                {emRevealed ? "Revealed" : "Reveal ID & password"}
              </button>
            </div>
            <p className="text-sm font-normal text-yellow-500">
              ** Change the ID and password of the email account to secure the
              account
            </p>
          </>
        )}

        {emRevealed && (
          <div className="flex mt-4 sm:w-3/5 gap-x-3">
            <input
              type="tel"
              name="otp"
              placeholder="OTP"
              className="w-2/3 h-12 px-3 rounded bg-light-grey"
              onChange={(e) => {
                setOTP(e.target.value);
              }}
            />
            <button
              className={` w-1/3 rounded ${
                !fetch ? "bg-yellow-500" : "bg-light-silver"
              } `}
              onClick={() => {
                setFetch(true);
              }}
            >
              Fetch OTP
            </button>
          </div>
        )}

        {fetch && (
          <div className="px-3 mt-3 text-center">
            <button
              className={` rounded sm:h-12 py-2  ${
                done2
                  ? " bg-success px-6 sm:w-1/5"
                  : "bg-yellow-500 text-sm font-normal px-7 "
              }`}
              onClick={() => {
                setDone2(true);
              }}
            >
              {done2
                ? "Done"
                : "Click here once you are done changing the password"}
            </button>
          </div>
        )}
        <div className="px-3 mt-5 text-center ">
          <p>
            Click confirm once you are done changing the ID and Password of the
            accounts
          </p>
          <button
            className={`sm:w-1/5 px-6 py-2 sm:h-12 mt-2 rounded ${
              (twRevealed && !done2 && !done) || (done && done2)
                ? " bg-yellow-500"
                : "bg-light-silver"
            }`}
            onClick={() => {
              done2 ? setCurrentStep(currentStep + 1) : setDone(true);
            }}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

export default SecureAccount;
