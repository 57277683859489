import React from "react";
import { Link } from "react-router-dom";

const PaymentRecieved = () => {
  return (
    <div>
      <h3 className="hidden text-2xl font-semibold text-center sm:block">
        Payment recieved
      </h3>
      <div className="flex justify-center mt-2 rounded bg-dark-700 h-[354px] sm:h-80 px-2">
        <div className="my-auto font-semibold text-center">
          <p>Payment recieved from buyer. Thanks for</p>
          <p>working with us</p>

          <Link to="/sellerdashboard">
            <button className="px-3 py-2 mt-8 border-2 border-yellow-500 rounded sm:px-6 solid">
              Return to profile page
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PaymentRecieved;
