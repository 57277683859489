import axios from "axios";
import React from "react";
import { useState, useRef } from "react";
import { useDropzone } from "react-dropzone";
import Loader from "../../components/Loader";

const Uploads = ({
  accountThumbnail,
  setAccountThumbnail,
  filesArray2,
  setFilesArray2,
}) => {
  const [image1, setImage1] = useState();
  const [image2, setImage2] = useState();
  const [video, setVideo] = useState();
  const [uploading1, setUploading1] = useState(false);
  const [uploading2, setUploading2] = useState(false);
  const fileInput = useRef();

  const { getRootProps: getImg1RootProps, getInputProps: getImg1InputProps } =
    useDropzone({
      accept: "image/*",
      onDrop: async (acceptedFiles) => {
        console.log(acceptedFiles);
        if (acceptedFiles[0].type.split("/")[0] !== "image")
          return alert("Please select an image.");
        else if (acceptedFiles[0].size > 5250000)
          return alert("Please select an image of size less than 5MB");

        const formData = new FormData();
        formData.append("file", acceptedFiles[0]);
        formData.append("upload_preset", "i4o0qcxt");

        setUploading1(true);

        const res = await axios.post(
          "https://api.cloudinary.com/v1_1/dydnsw82t/image/upload",
          formData
        );

        console.log(res);

        setAccountThumbnail(res.data.url);
        setUploading1(false);

        setImage1(
          ...acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
    });

  const { getRootProps: getVidRootProps, getInputProps: getVidInputProps } =
    useDropzone({
      accept: { "video/*": [], "image/*": [] },
      onDrop: async (acceptedFiles) => {
        if (
          acceptedFiles[0].type.split("/")[0] !== "video" &&
          acceptedFiles[0].type.split("/")[0] !== "image"
        )
          return alert("Please select an image or a video.");
        else if (acceptedFiles[0].size > 10500000)
          return alert("Please select a video of size upto 10MB");

        if (acceptedFiles[0].type.split("/")[0] === "video") {
          const formData = new FormData();
          formData.append("file", acceptedFiles[0]);
          formData.append("upload_preset", "i4o0qcxt");

          setUploading2(true);

          const res = await axios.post(
            "https://api.cloudinary.com/v1_1/dydnsw82t/video/upload",
            formData
          );

          console.log(res);

          setUploading2(false);
          setVideo(
            ...acceptedFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            )
          );
          setFilesArray2([...filesArray2, acceptedFiles]);
        } else if (acceptedFiles[0].type.split("/")[0] === "image") {
          const formData = new FormData();
          formData.append("file", acceptedFiles[0]);
          formData.append("upload_preset", "i4o0qcxt");

          setUploading2(true);

          const res = await axios.post(
            "https://api.cloudinary.com/v1_1/dydnsw82t/image/upload",
            formData
          );

          console.log(res);

          setFilesArray2(res.data.url);
          setUploading2(false);

          setImage2(
            ...acceptedFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            )
          );
        }
      },
    });

  return (
    <div className="overflow-y-scroll h-80 scrollbar xs:h-96">
      <div className="mx-1">
        <p>Upload thumbnail (Image displaying on card)</p>
        <div
          className="flex flex-col items-center justify-center w-full min-h-[124px] mr-2 img-input hover:cursor-pointer"
          onClick={() => fileInput.current.click()}
          {...getImg1RootProps()}
        >
          {uploading1 && (
            <div>
              <Loader className="h-full " />
            </div>
          )}
          {!image1 && (
            <>
              <input
                style={{ display: "none" }}
                type="file"
                ref={fileInput}
                {...getImg1InputProps()}
              />
              <span>Choose or drag a file here</span>
            </>
          )}
          {image1 && (
            <div className="flex items-center">
              <img
                src={image1.preview}
                alt="preview"
                className="object-contain h-36"
              />
              <button
                className="flex items-center self-start justify-center w-2 h-2 p-3 ml-2 font-medium text-red-200 bg-white rounded-full bg-opacity-20"
                onClick={() => setImage1()}
              >
                x
              </button>
            </div>
          )}
        </div>
        <p className=" text-no-new-message">
          PNG or JPG format & image dimensions between 300x200 px
        </p>
      </div>
      <div className="mx-1 mt-6 ">
        <p>Upload pictures or videos related to your account</p>
        <div
          className="flex flex-col items-center justify-center w-full min-h-[124px] mr-2 img-input hover:cursor-pointer"
          {...getVidRootProps()}
        >
          {!(video || image2) && (
            <>
              <input
                style={{ display: "none" }}
                type="file"
                ref={fileInput}
                {...getVidInputProps()}
              />
              {uploading2 && (
                <div>
                  <Loader className="h-full " />
                </div>
              )}
              <span>Choose or drag a file here</span>
            </>
          )}

          {video && (
            <div className="flex items-center">
              <video
                src={video.preview}
                alt="preview"
                className="object-contain h-40"
              />
              <button
                className="flex items-center self-start justify-center w-2 h-2 p-3 ml-2 font-medium text-red-200 bg-white rounded-full bg-opacity-20"
                onClick={() => setVideo()}
              >
                x
              </button>
            </div>
          )}
          {image2 && (
            <div className="flex items-center">
              <img
                src={image2.preview}
                alt="preview"
                className="object-contain h-36"
              />
              <button
                className="flex items-center self-start justify-center w-2 h-2 p-3 ml-2 font-medium text-red-200 bg-white rounded-full bg-opacity-20"
                onClick={() => setImage2()}
              >
                x
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Uploads;
