import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import AccountDetails from "../AccountModal/Steps/AccountDetails";
import InventoryDetails from "../AccountModal/Steps/InventoryDetails";
import SocialAccounts from "../AccountModal/Steps/SocialAccounts";
import BankDetails from "../AccountModal/Steps/BankDetails";
import Uploads from "../AccountModal/Steps/Uploads";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import useApi from "../../hooks/useApi";
import accountApi from "../../api/account";
import Loader from "../components/Loader";

const ListAccount = ({ isSeller }) => {
  const [seasons, setSeasons] = useState([
    { title: "Levels", items: [1, 2, 3, 4] },
    { title: "Regular", items: [] },
    { title: "Elite", items: [] },
    { title: "Elite Plus", items: [] },
  ]);
  const [filesArray1, setFilesArray1] = useState([]);
  const [filesArray2, setFilesArray2] = useState([]);
  const [accountThumbnail, setAccountThumbnail] = useState();
  const [titlesInput, setTitlesInput] = useState("");
  const [titlesArr, setTitlesArr] = useState([]);
  const [isTitlesKeyReleased, setIsTitlesKeyReleased] = useState(false);
  const [framesInput, setFramesInput] = useState("");
  const [framesArray, setFramesArray] = useState([]);
  const [isFramesKeyReleased, setIsFramesKeyReleased] = useState(false);

  const { state: account } = useLocation();
  const navigate = useNavigate();

  const { data, error, request: editAccount } = useApi(accountApi.editAccount);

  useEffect(() => {
    if (account) {
      setSeasons(account.royalPassDetails);
      setFilesArray2([account.accountMedia]);
      setTitlesArr(account.memorableTitles);
      setFramesArray(account.memorableFrames);
    }
  }, [account]);

  const UploadedFiles1 = ({ file, index }) => {
    return (
      <div className="mb-2 ">
        <img src={file} className="w-32" />
      </div>
    );
  };

  const UploadedFiles2 = ({ file, index }) => {
    return (
      <div className="mb-2">
        <div className="flex flex-row justify-center p-1 text-xs w-36 gap-x-2 file-name">
          {/* <p>{file[0].path}</p> */}
          <img src={file} className="w-32" />

          <button type="button" onClick={() => RemoveFiles2({ index })}>
            X
          </button>
        </div>
      </div>
    );
  };

  const RemoveFiles1 = ({ index }) => {
    setFilesArray1((filesArray1) =>
      filesArray1.filter((current) => current !== filesArray1[index])
    );
  };

  const RemoveFiles2 = ({ index }) => {
    setFilesArray2((filesArray1) =>
      filesArray1.filter((current) => current !== filesArray2[index])
    );
  };
  // const submitHandler =() =>{
  //   console.log(Formik)
  // }

  // if (!account) return <div>Loading</div>;
  if (!account) return <Loader />;

  return (
    <div className=" bg-black-900">
      <Formik
        // initialValues={{
        //   game: account.game,
        //   price: account.price,
        //   playerID: account.playerID,
        //   ingameName: account.ingameName,
        //   fbtc: false,
        //   twtc: false,
        //   emtc: false,
        //   motc: false,
        //   ownershipTenure: {
        //     years: "",
        //     months: "",
        //   },
        //   accountLevel: "",
        //   memorableFrames: "",
        //   memorableTiles: "",
        //   inventoryDetails: "",
        //   specialMention: "",
        //   facebook: {
        //     username: "",
        //     password: "",
        //   },
        //   twitter: {
        //     username: "",
        //     password: "",
        //   },
        //   accountDetails: {
        //     accountNumber: "",
        //     confirmAccountNumber: "",
        //     IFSCNumber: "",
        //     beneficiaryName: "",
        //   },
        // }}
        initialValues={account}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          values.royalPassDetails = seasons;
          values.memorableFrames = framesArray;
          values.memorableTitles = titlesArr;
          editAccount(values);
          navigate(`/sellerdashboard/account/${account._id}`, {
            replace: true,
          });
          return false;
        }}
      >
        {(formik) => (
          <>
            <Form>
              <div className="py-8 mx-8 sm:mx-14 lg:mx-20 ">
                <div className="sm:flex sm:justify-between font-semibold text-white sm:h-[51px] ">
                  <p className="justify-center text-2xl font-bold">
                    Edit list account
                  </p>
                  <div className="flex gap-2">
                    <button
                      className="px-8 py-2 border border-yellow-500 border-solid rounded py-auto"
                      onClick={() => navigate(-1)}
                      type="button"
                    >
                      Exit
                    </button>
                    <button
                      className="px-8 py-2 bg-yellow-500 rounded py-auto"
                      type="submit"
                    >
                      Save and Exit
                    </button>
                  </div>
                </div>
                <div className="mt-10 text-white">
                  <p className="font-semibold text-[18px]">Account details</p>
                  <AccountDetails formik={formik} />

                  <p className="font-semibold text-[18px] mt-14">Uploads</p>
                  <div className="flex-row sm:flex gap-7">
                    <div className="sm:w-2/3">
                      <Uploads
                        setAccountThumbnail={setAccountThumbnail}
                        filesArray1={filesArray1}
                        setFilesArray1={setFilesArray1}
                        filesArray2={filesArray2}
                        setFilesArray2={setFilesArray2}
                      />
                    </div>
                    <div className="flex gap-8 sm:flex-col sm:w-1/3">
                      <div className="sm:mt-5 md:flex md:flex-row min-h-[166px]">
                        <div className="w-1/3">Uploaded files -</div>
                        <div className="sm:w-2/3">
                          {
                            <UploadedFiles1
                              file={account.accountThumbnail}
                              // key={index}
                              // index={index}
                            />
                          }
                        </div>
                      </div>
                      <div className=" md:flex md:flex-row h-min-36">
                        <div className="w-1/3">Uploaded files -</div>
                        <div className="sm:w-2/3">
                          {filesArray2.map((file, index) => {
                            return (
                              <UploadedFiles2
                                file={file}
                                key={index}
                                index={index}
                              />
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" mt-14">
                    <p className="font-semibold text-[18px]">
                      Inventory details
                    </p>
                    <div className="mx-1">
                      <InventoryDetails
                        formik={formik}
                        seasons={seasons}
                        setSeasons={setSeasons}
                        framesArray={framesArray}
                        titlesArr={titlesArr}
                        framesInput={framesInput}
                        setFramesInput={setFramesInput}
                        // framesArray={framesArray}
                        setFramesArray={setFramesArray}
                        isFramesKeyReleased={isFramesKeyReleased}
                        setIsFramesKeyReleased={setIsFramesKeyReleased}
                        titlesInput={titlesInput}
                        setTitlesInput={setTitlesInput}
                        // titlesArr={titlesArr}
                        setTitlesArr={setTitlesArr}
                        isTitlesKeyReleased={isTitlesKeyReleased}
                        setIsTitlesKeyReleased={setIsTitlesKeyReleased}
                      />
                    </div>
                  </div>

                  <div className=" mt-14">
                    <p className="font-semibold text-[18px]">Socials</p>
                    <SocialAccounts />
                  </div>

                  <div className=" mt-14">
                    <p className="font-semibold text-[18px]">Bank details</p>
                    <BankDetails />
                  </div>
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default ListAccount;
