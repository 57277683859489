import React, { useState, useEffect } from "react";
import { MdFilterList } from "react-icons/md";
import {
  HiOutlineDotsHorizontal,
  HiOutlineLogout,
  HiOutlinePlus,
} from "react-icons/hi";
import { Menu } from "@headlessui/react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import RegisterButton from "../../components/Buttons/RegisterButton";
import AccountFormModal from "../AccountModal/AccountFormModal";
import useApi from "../../hooks/useApi";
import useToken from "../../hooks/useToken";
import accountApi from "../../api/account";
import { useContext } from "react";
import AuthContext from "../../utilities/context";
import AccountDetails from "./AccountDetails";
import Loader from "./Loader";

const Dashboard = ({ isSeller }) => {
  const [selected, setSelected] = useState(false);
  let navigate = useNavigate();

  const { userData } = useContext(AuthContext);

  const { removeToken } = useToken();

  console.log(isSeller);

  const {
    data: accounts,
    error,
    loading,
    request: loadMyAccounts,
  } = useApi(accountApi.myAccounts);

  useEffect(() => {
    loadMyAccounts();
  }, []);

  const handleExplore = () => {
    navigate("/explore");
  };

  if (!accounts) return <Loader />;
  if (!userData) return <Loader />;

  return (
    <div>
      <div className="mx-8 text-white sm:mx-14 lg:mx-20 mt-7">
        {selected && (
          <AccountFormModal setSelected={setSelected} selected={selected} />
        )}
        <div className="mb-8 sm:flex sm:flex-row sm:justify-between">
          <p className="text-3xl font-semibold ">Hello {userData.firstName},</p>
          <button
            onClick={isSeller ? () => setSelected(true) : handleExplore}
            type="button"
            className="relative flex mt-3 sm:mt-0"
          >
            <RegisterButton
              text={isSeller ? "List account" : "Explore accounts"}
              className={`py-2  ${
                isSeller ? "pl-8 pr-12" : "px-12"
              } font-semibold text-lg `}
            />
            {isSeller && (
              <HiOutlinePlus size={25} className="absolute z-20 mt-2 right-5" />
            )}
          </button>
        </div>
        <div className="sm:flex sm:flex-row sm:gap-7">
          <div className="px-4 pt-3 sm:w-1/2 md:w-1/3 schedule-div py-9">
            {/* <p className="flex justify-end pt-5 pb-9">Logout</p> */}
            {/* <div className="flex justify-end mt-5 mb-9">
              <HiOutlineLogout
                className="duration-200 hover:cursor-pointer hover:scale-110"
                color="white"
                size={25}
                onClick={() => {
                  removeToken();
                  localStorage.removeItem("isSeller");
                  window.location.reload();
                }}
              />
            </div> */}

            <div>
              <div className="w-24 h-24 mx-auto mt-4 bg-white rounded-full xl:w-32 xl:h-32">
                <img
                  src={require("../../images/seller.png")}
                  alt=""
                  className="rounded-full"
                />
              </div>
              <p className="mt-2 text-lg text-center">
                {userData.firstName + " " + userData.lastName}
              </p>
            </div>
            <div className="p-2 mt-8 rounded-lg social-div">
              <div className="flex justify-between mb-2">
                {isSeller && <p>Accounts sold</p>}
                {!isSeller && <p>Accounts owned</p>}

                <p>{isSeller ? userData.sold : userData.bought}</p>
              </div>
              <div className="flex justify-between">
                {isSeller && <p>Accounts in store</p>}
                {!isSeller && <p>Total value of accounts</p>}

                <p>{isSeller ? accounts.length : ""}</p>
              </div>
            </div>
            <p className="mt-8 ">Details</p>
            <div className="grid p-2 mt-3 overflow-x-scroll rounded-lg social-div gap-y-2 scrollbar-hide">
              <div className="xl:flex xl:justify-between">
                <p>Email</p>
                <p>{userData.email}</p>
              </div>
              <div className="justify-between xs:space-x-3 xs:flex">
                <p>D.O.B</p>
                <p>{userData.dob}</p>
              </div>
              {/* <div className="flex justify-between">
                <p>Phone no.</p>
                <p>+91 {userData.phoneNumber}</p>
              </div> */}
            </div>
            <div></div>
          </div>
          <div className="mt-5 sm:w-1/2 md:w-2/3 schedule-div sm:mt-0">
            {isSeller && !accounts.length && (
              <div className="text-center py-9">
                <p>No account is added here. Please add a game </p>
                <div>
                  account for sell from{" "}
                  <span
                    className="text-yellow-500 cursor-pointer "
                    onClick={() => setSelected(true)}
                  >
                    here
                  </span>
                </div>
              </div>
            )}

            {!isSeller && !accounts.length && (
              <div className="text-center py-9">
                <p>No account is purchased yet. Purchase some gaming</p>
                <p>
                  account for sell from{" "}
                  <Link to="/explore" className="text-yellow-500 ">
                    here
                  </Link>
                </p>
              </div>
            )}

            {accounts.length !== 0 && (
              <div className="p-4">
                <div className="flex justify-between">
                  <p className="p-2 text-lg font-semibold">
                    {isSeller ? "Game accounts listed" : "Game accounts owned"}
                  </p>
                  <Menu as="div" className="relative inline-block text-left">
                    <Menu.Button
                      type="button"
                      className="flex gap-2 px-6 py-2 mb-1 border-2 rounded social-div border-neutral-700 solid"
                    >
                      <span className="py-1">
                        <MdFilterList />
                      </span>{" "}
                      {isSeller ? "Listed" : "Owned"} accounts
                    </Menu.Button>

                    {isSeller && (
                      <Menu.Items className="absolute z-40 flex flex-col w-full gap-4 p-5 dropdown-div">
                        <Menu.Item
                          as="button"
                          onClick={() => {
                            console.log("recently added");
                          }}
                          className="text-left"
                        >
                          Recently added
                        </Menu.Item>
                        <Menu.Item as="button" className="text-left">
                          Archived
                        </Menu.Item>
                        <Menu.Item as="button" className="text-left">
                          Sold
                        </Menu.Item>
                      </Menu.Items>
                    )}

                    {!isSeller && (
                      <Menu.Items className="absolute z-40 flex flex-col w-full gap-4 p-5 dropdown-div">
                        <Menu.Item
                          as="button"
                          onClick={() => {
                            console.log("archived");
                          }}
                          className="text-left"
                        >
                          Archived
                        </Menu.Item>
                        <Menu.Item as="button" className="text-left">
                          Hidden
                        </Menu.Item>
                        <Menu.Item as="button" className="text-left">
                          Favourite
                        </Menu.Item>
                      </Menu.Items>
                    )}
                  </Menu>
                </div>
                <div className="grid mt-6 overflow-y-scroll scrollbar md:grid-cols-2 xl:grid-cols-3 max-h-96 gap-x-10 gap-y-6">
                  {accounts.map((account, index) => {
                    return (
                      <div
                        className="social-div rounded-xl card-bg"
                        style={{
                          backgroundImage: `url(${account.accountThumbnail})`,
                        }}
                      >
                        <div className="p-2 h-52 rounded-t-xl">
                          <div className="flex justify-between top-10 ">
                            <p className="px-3 text-sm text-black bg-white rounded-full">
                              Listed
                            </p>
                            <button className="p-1 rounded-full details-btn">
                              <HiOutlineDotsHorizontal />
                            </button>
                          </div>
                        </div>
                        <div className="px-3 py-4 overlay">
                          <div className="flex justify-between font-semibold">
                            <p>{account.game}</p>
                            <p>₹{account.price}</p>
                          </div>
                          <Link
                            to={
                              isSeller
                                ? `/sellerdashboard/account/${account._id}`
                                : `/profile/account/${index}`
                            }
                            state={account}
                          >
                            <button
                              className="w-full py-3 mt-3 text-yellow-500 rounded-lg schedule-div"
                              // onClick={() =>
                              //   navigate("/listaccount", { state: account })
                              // }
                            >
                              View details
                            </button>
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
