import React, { useContext, useEffect } from "react";
import { useState, useRef } from "react";
import AccountDetails from "./AccountDetails";
import { RiSearchLine } from "react-icons/ri";
import { HiSortAscending, HiOutlineDotsHorizontal } from "react-icons/hi";
import AuthContext from "../../utilities/context";
import useApi from "../../hooks/useApi";
import accountApi from "../../api/account";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";
import SearchInput from "../../components/SearchInput";

const Explore = ({ isSeller }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [accountDetails, setAccountDetails] = useState({});
  const [focus, setFocus] = useState(false);
  const inputRef = useRef(null);
  const [query, setQuery] = useState("");
  const keys = ["game", "inventoryDetails"];

  const {
    data: accounts,
    error,
    request: loadAccounts,
  } = useApi(accountApi.getAccounts);

  const { userData } = useContext(AuthContext);

  useEffect(() => {
    loadAccounts();
  }, []);

  const handleClick = () => {
    inputRef.current.click();
  };
  if (!accounts) return <Loader />;

  //   const accounts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];

  return (
    <div>
      {showDetails && (
        <AccountDetails
          setShowDetails={setShowDetails}
          accountDetails={accountDetails}
        />
      )}
      {!showDetails && (
        <div className="mx-8 font-semibold text-white mt-7 sm:mx-14 lg:mx-20 mb-28">
          <div className="sm:flex sm:justify-between ">
            <h1 className="mb-4 text-3xl font-semibold sm:mb-0 ">
              Hello {userData?.firstName},
            </h1>
            {/* <label
              htmlFor="search"
              className="flex flex-row py-0 cursor-pointer input-field"
            >
              <div className="flex flex-row w-11/12 py-0">
                <button className="py-2 text-2xl" onClick={handleClick}>
                  <RiSearchLine />
                </button>
                <input
                  type="text"
                  name="search"
                  ref={inputRef}
                  placeholder="Search for games"
                  className={`ml-2 xl:w-80 text-base bg-transparent outline-none`}
                />
              </div>
              <span className="w-1/12 py-2 text-2xl">
                <HiSortAscending />
              </span>
            </label> */}
            <SearchInput
              className="h-10 sm:h-12 input-field "
              query={query}
              setQuery={setQuery}
            />
          </div>

          <div className="grid max-h-screen mt-6 overflow-scroll sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-7 scrollbar">
            {accounts
              .filter(
                (item) =>
                  keys.some((key) => item[key].toLowerCase().includes(query)) ||
                  query >= item.price
              )
              ?.map((account) => {
                return (
                  // <div className="social-div rounded-xl">
                  <div
                    key={account._id}
                    className={`social-div rounded-xl card-bg `}
                    style={{
                      backgroundImage: `url(
                        ${account.accountThumbnail}
                      )`,
                    }}
                  >
                    <div className="flex justify-between p-1 h-44">
                      <p></p>
                      <button className="pl-[6px] h-7 w-7 rounded-full details-btn">
                        <HiOutlineDotsHorizontal />
                      </button>
                    </div>
                    <div className="px-3 py-4 overlay">
                      <div className="flex justify-between text-2xl font-semibold">
                        <p>{account.game}</p>
                        <p>₹{account.price}</p>
                      </div>
                      <div className="mt-2 overflow-y-scroll text-base font-normal scrollbar h-14">
                        <p>{account.inventoryDetails}</p>
                      </div>
                      <button
                        className="w-full py-3 mt-2 text-yellow-500 duration-200 rounded-lg schedule-div"
                        onClick={() => {
                          setShowDetails(true);
                          setAccountDetails(account);
                        }}
                      >
                        View details
                      </button>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Explore;
