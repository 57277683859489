import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  MdOutlineNotifications,
  MdOutlineMenu,
  MdOutlineClose,
} from "react-icons/md";
import { HiOutlineLogout, HiUserCircle } from "react-icons/hi";
import { useState } from "react";
import Notification from "./Notification";
import useToken from "../../hooks/useToken";

const Navbar = ({ setIsSeller }) => {
  const { removeToken } = useToken();
  const [isShown, setIsShown] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [toggel, setToggel] = useState(false);
  const navigate = useNavigate();

  const isSeller = localStorage.getItem("isSeller") === "true" ? true : false;

  return (
    <div className="">
      <div className="h-[64px] w-screen relative">
        <div className="fixed z-40 w-screen bg-[#131313]">
          <div className="flex flex-row justify-between py-4 mx-8 sm:py-6 sm:mx-14 lg:mx-20">
            <div
              className="flex mt-1 text-yellow-500 cursor-pointer sm:mt-0 "
              onClick={() => {
                isSeller ? navigate("/sellerdashboard") : navigate("/profile");
              }}
            >
              {" "}
              <img
                src={require("../../images/logo.png")}
                alt="PremiumCores"
                className="sm:w-[38px] sm:h-[34px] w-8 h-7"
              />{" "}
              <div className="ml-1 ">
                <p className="hidden font-semibold scale-x-95 scale-y-110 xs:block sm:font-bold sm:text-xl font-heading lg:text-2xl">
                  PREMIUMCORES
                  <sub> {isSeller ? "seller" : "buyer"}</sub>
                </p>
                <p className=" xs:hidden">
                  {" "}
                  <sub> {isSeller ? "seller" : "buyer"}</sub>
                </p>
              </div>
            </div>

            <div className="hidden gap-6 -ml-32 leading-5 text-center lg:-ml-48 tab:flex sm:flex-row nav">
              {!isSeller && (
                <NavLink
                  to="/explore"
                  className={({ isActive }) =>
                    `p-1 ${
                      isActive
                        ? "text-white-50 border-b-2 border-white"
                        : "text-nav-link"
                    }`
                  }
                >
                  Explore
                </NavLink>
              )}
              {isSeller && (
                <NavLink
                  to="/sellerdashboard"
                  className={({ isActive }) =>
                    `p-1 ${
                      isActive
                        ? "text-white-50 border-b-2 border-white"
                        : "text-nav-link"
                    }`
                  }
                >
                  Dashboard
                </NavLink>
              )}

              <NavLink
                to={"/chats"}
                className={({ isActive }) =>
                  `p-1 ${
                    isActive
                      ? "text-white-50 border-b-2 border-white"
                      : "text-nav-link"
                  }`
                }
              >
                Chats
              </NavLink>
              {!isSeller && (
                <NavLink
                  to="/profile"
                  className={({ isActive }) =>
                    `p-1 ${
                      isActive
                        ? "text-white-50 border-b-2 border-white"
                        : "text-nav-link"
                    }`
                  }
                >
                  Profile
                </NavLink>
              )}
              <NavLink
                to={"/schedule"}
                className={({ isActive }) =>
                  `p-1 ${
                    isActive
                      ? "text-white-50 border-b-2 border-white"
                      : "text-nav-link"
                  }`
                }
              >
                Schedule
              </NavLink>
            </div>
            <div className="flex gap-x-2 text-white-50">
              <button
                type="button"
                className=" hover:text-yellow-500"
                onClick={() => {
                  setShowNotification(!showNotification);
                  setIsShown(false);
                  setToggel(false);
                }}
              >
                <MdOutlineNotifications size={30} className="relative -mt-1 " />
              </button>
              <div
                onMouseEnter={() => setIsShown(true)}
                onMouseLeave={() => setIsShown(false)}
              >
                <button
                  onClick={() => {
                    setIsShown(!isShown);
                    setShowNotification(false);
                    setToggel(false);
                  }}
                  className="py-1 hover:text-yellow-500"
                >
                  <HiUserCircle size={30} />
                </button>
                {isShown && (
                  <div className="absolute z-50 w-48 p-3 bg-black border-2 rounded right-8 sm:right-14 lg:right-20 border-dark-700 ">
                    <div className="flex px-2 mt-1 duration-200 rounded hover:text-yellow-500 hover:cursor-pointer hover:scale-105 hover:border-yellow-500 solid hover:border">
                      <Link to={isSeller ? "/explore" : "/sellerdashboard"}>
                        <button
                          className="flex "
                          onClick={
                            isSeller
                              ? () => {
                                  localStorage.setItem("isSeller", false);
                                  setIsSeller(false);
                                  setIsShown(false);
                                }
                              : () => {
                                  localStorage.setItem("isSeller", true);
                                  setIsSeller(true);
                                  setIsShown(false);
                                }
                          }
                        >
                          <HiUserCircle size={20} className="my-auto " /> Switch
                          to {isSeller ? "Buyer" : "Seller"}
                        </button>
                      </Link>
                    </div>
                    <div
                      className="flex px-2 mt-1 duration-200 rounded hover:text-yellow-500 hover:cursor-pointer hover:scale-105 hover:border-yellow-500 solid hover:border"
                      onClick={() => {
                        removeToken();
                        localStorage.removeItem("isSeller");
                        window.location.reload();
                      }}
                    >
                      <HiOutlineLogout size={20} className="my-auto " />
                      <span className="flex">LogOut</span>
                    </div>
                  </div>
                )}
              </div>

              <div className=" hover:text-yellow-500 text-white-50 tab:hidden">
                <button
                  type="button"
                  onClick={() => {
                    setToggel(!toggel);
                    setIsShown(false);
                    setShowNotification(false);
                  }}
                >
                  <span className="text-3xl scale-y-125">
                    {!toggel ? (
                      <MdOutlineMenu size={36} />
                    ) : (
                      <MdOutlineClose size={36} />
                    )}
                  </span>
                </button>
              </div>
            </div>
          </div>

          {showNotification && (
            <div className="absolute top-0 z-40 text-white-50 sm:relative sm:mx-14 lg:mx-20">
              <Notification setShowNotification={setShowNotification} />
            </div>
          )}
          {toggel && (
            <div className="relative mx-8 text-center rounded-none tab:hidden sm:mx-14 lg:mx-20 ">
              <div className="absolute z-40 w-full -top-3 navbar bg-black-800 ">
                {!isSeller && (
                  <NavLink
                    to="/explore"
                    onClick={() => setToggel(false)}
                    className={({ isActive }) =>
                      `${isActive ? "text-white-50 bg-grey" : "text-nav-link"}`
                    }
                  >
                    <div>Explore</div>
                  </NavLink>
                )}
                {isSeller && (
                  <NavLink
                    to="/sellerdashboard"
                    onClick={() => setToggel(false)}
                    className={({ isActive }) =>
                      `${isActive ? "text-white-50 bg-grey" : "text-nav-link"}`
                    }
                  >
                    <div>Dashboard</div>
                  </NavLink>
                )}

                <NavLink
                  to="/chats"
                  onClick={() => setToggel(false)}
                  className={({ isActive }) =>
                    `p-1 ${
                      isActive ? "text-white-50 bg-grey" : "text-nav-link"
                    }`
                  }
                >
                  <div>Chats</div>
                </NavLink>
                {!isSeller && (
                  <NavLink
                    to="/profile"
                    onClick={() => setToggel(false)}
                    className={({ isActive }) =>
                      `p-1 ${
                        isActive ? "text-white-50 bg-grey" : "text-nav-link"
                      }`
                    }
                  >
                    <div>Profile</div>
                  </NavLink>
                )}
                <NavLink
                  to={isSeller ? "/seller/schedule" : "/buyer/schedule"}
                  onClick={() => setToggel(false)}
                  className={({ isActive }) =>
                    `p-1 ${
                      isActive ? "text-white-50 bg-grey" : "text-nav-link"
                    }`
                  }
                >
                  <div>Schedule</div>
                </NavLink>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
