import React from "react";
import { useState } from "react";
import { MdOutlineNotifications } from "react-icons/md";
import Notification from "../../screens/components/Notification";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import RegisterButton from "../../components/Buttons/RegisterButton";

const XBLogin = () => {
  const [data, setData] = useState({ userID: "", password: "" });
  const [eye, setEye] = useState(false);
  const show = () => {
    setEye(!eye);
  };
  const [showNotification, setShowNotification] = useState(false);
  return (
    <div className="h-screen text-white background-image">
      <div className="flex justify-between h-16 py-4 mx-4 xs:mx-8 sm:mx-20">
        <div>
          <img
            src={require("../../images/logo.png")}
            alt="premiumcores"
            className="w-10 "
          />
        </div>
        <div>
          <button
            onClick={() => {
              setShowNotification(!showNotification);
            }}
          >
            <MdOutlineNotifications className="relative w-8 h-8" />
          </button>
        </div>
      </div>
      {showNotification && (
        <div className="absolute top-0 z-50 text-white sm:relative sm:mx-20">
          <Notification setShowNotification={setShowNotification} />
        </div>
      )}
      <div className="flex justify-center my-6 mx-5 xs:my-[20vh] lg:my-24">
        <div className="xs:px-5 px-2 py-6 login-div sm:w-[408px]">
          <p className="mb-6 text-2xl font-bold tracking-tighter md:text-3xl lg:text-4xl font-heading">
            LOGIN TO XROOM
          </p>
          <div className="grid mb-3 grid-col-1">
            <label htmlFor="userID" className=" text-white-grey">
              User ID
            </label>
            <input
              type="text"
              name="UserID"
              placeholder="Enter your user ID"
              className="h-14 input-field"
              value={data.userID}
              onChange={(e) => {
                setData({ userID: e.target.value, password: data.password });
              }}
            />
          </div>
          <div className="grid mb-3 grid-col-1">
            <label htmlFor="userID" className="text-white-grey">
              Password
            </label>
            <div className="flex p-0 mb-3 h-14 input-field">
              <input
                type={!eye ? "password" : "text"}
                name="password"
                placeholder="Enter your password"
                className="w-[100%] bg-transparent px-2"
                value={data.password}
                onChange={(e) => {
                  setData({ userID: data.userID, password: e.target.value });
                }}
              />
              <button onClick={show} type="button" className="p-3">
                {eye ? (
                  <IoMdEye className="text-xl" />
                ) : (
                  <IoMdEyeOff className="text-xl" />
                )}
              </button>
            </div>
          </div>
          <RegisterButton text="Enter Xroom " className="py-3 font-semibold" />
        </div>
      </div>
    </div>
  );
};

export default XBLogin;
