import React from "react";
import { useState } from "react";

const FetchValidate = ({ currentStep, setCurrentStep }) => {
  const [revealed, setReveal] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [data, setData] = useState({ email: "", password: "" });

  const [valPhone, setValPhone] = useState(false);
  const [revealedPh, setRevealPh] = useState(false);
  const [confirmPh, setConfirmPh] = useState(false);
  const [phone, setPhone] = useState({ phonenumber: "", password: "" });
  return (
    <>
      {!valPhone && (
        <div className="font-semibold ">
          <h3 className="hidden text-2xl text-center sm:block">
            Fetch & Validate ID & Passwords
          </h3>
          <div className="xs:px-4 px-2 py-3 mt-1 rounded bg-dark-700 sm:h-80 h-[354px]  md:h-[34vh] overflow-y-scroll scrollbar lg:h-80">
            <p className="text-lg">Validate ID and password</p>
            <p className="mt-2 ">Get ID and password</p>

            <div className="flex-row mt-2 sm:flex gap-x-7">
              <div className="grid w-full xs:w-9/12 gap-y-2 sm:grid-cols-2 gap-x-7">
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  className="h-12 px-1 rounded xs:px-3 bg-light-grey"
                  onChange={(e) => {
                    setData({ email: e.target.value, password: data.password });
                  }}
                />

                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  className="h-12 px-1 rounded xs:px-3 bg-light-grey"
                  onChange={(e) => {
                    setData({ email: data.email, password: e.target.value });
                  }}
                />
              </div>
              <button
                className={`px-4 mt-2 sm:mt-0 py-1 rounded  ${
                  revealed ? "bg-light-silver px-14" : "bg-yellow-500"
                }`}
                onClick={() => {
                  setReveal(!revealed);
                }}
              >
                {revealed ? "Revealed" : "Reveal ID & password"}
              </button>
            </div>

            <div className="mt-4 text-center">
              <button
                className={` px-6 py-2 rounded  ${
                  revealed ? "bg-yellow-500 " : "bg-light-silver"
                } ${confirm && "invisible"}`}
                onClick={() => {
                  setValPhone(true);
                }}
              >
                Confirm you are logged in
              </button>
              <p className="mt-5 sm:mt-12">
                Try this ID password with the account you are buying. Let us
                know the results
              </p>
              <button
                className={`py-2 mt-2 border-2 rounded px-7 solid ${
                  revealed ? " border-error-500" : "border-light-silver"
                }`}
                onClick={() => {
                  setConfirm(!confirm);
                }}
              >
                Problem logging in
              </button>
            </div>
          </div>
        </div>
      )}
      {valPhone && (
        <div className="font-semibold ">
          <h3 className="hidden text-2xl text-center sm:block">
            Fetch & Validate ID & Passwords
          </h3>
          <div className="xs:px-4 px-2 py-3 mt-1 rounded bg-dark-700 sm:h-80 md:h-[34vh] h-[354px] overflow-y-scroll scrollbar lg:h-80">
            <p className="text-lg">Validate Phone no.</p>
            <p className="mt-2 ">Get phone no. and password</p>

            <div className="flex-row mt-2 sm:flex gap-x-7">
              <div className="grid w-full xs:w-9/12 sm:grid-cols-2 gap-y-2 gap-x-7">
                <input
                  type="tel"
                  name="phone"
                  placeholder="--"
                  className="w-full h-10 rounded xs:px-3 sm:h-12 bg-light-grey"
                  onChange={(e) => {
                    setPhone({
                      phonenumber: e.target.value,
                      password: phone.password,
                    });
                  }}
                />

                <input
                  type="password"
                  name="password"
                  placeholder="--"
                  className="w-full h-10 rounded xs:px-3 sm:h-12 bg-light-grey"
                  onChange={(e) => {
                    setPhone({
                      phonenumber: phone.phonenumber,
                      password: e.target.value,
                    });
                  }}
                />
              </div>
              <button
                className={`px-4 mt-2 sm:mt-0 py-1 rounded  ${
                  revealedPh ? "bg-light-silver px-14" : "bg-yellow-500"
                }`}
                onClick={() => {
                  setRevealPh(!revealedPh);
                }}
              >
                {revealedPh ? "Revealed" : "Reveal ID & password"}
              </button>
            </div>

            <div className="mt-4 text-center ">
              <button
                className={` px-6 py-2 rounded  ${
                  revealedPh ? "bg-yellow-500 " : "bg-light-silver"
                } ${confirmPh && "invisible"}`}
                onClick={() => {
                  setCurrentStep(currentStep + 1);
                }}
              >
                Confirm you are logged in
              </button>
              <p className="mt-5 sm:mt-12">
                Try this ID password with the account you are buying. Let us
                know the results
              </p>
              <button
                className={`py-2 mt-2 border-2 rounded px-7 solid ${
                  revealedPh ? " border-error-500" : "border-light-silver"
                }`}
                onClick={() => {
                  setConfirmPh(!confirmPh);
                }}
              >
                Problem logging in
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FetchValidate;
