import React from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { HiOutlineCalendar } from "react-icons/hi";
import { useState, useRef } from "react";

const ScheduleBuy = ({ isSeller, id }) => {
  const [date, setDate] = useState(null);
  const [time, setTime] = useState([
    { hours: 0, minute: 0, AM: true, PM: false },
  ]);
  const AM = "AM";
  const PM = "PM";
  const [checked, setChecked] = useState(false);
  const datepickerRef = useRef(null);
  function handleClickDatepickerIcon() {
    const datepickerElement = datepickerRef.current;
    datepickerElement.setFocus(true);
  }
  const handleSubmit = () => {
    console.log("Date :", date);
    console.log(time);
  };
  const navigate = useNavigate();

  const account = [];
  const data = [];
  return (
    <div>
      <div className="mx-8 text-white lg:mx-20 md:mx-14 mt-9">
        <p className="text-2xl font-semibold sm:text-4xl ">Schedule buy</p>
        <div className="flex-row mt-8 md:flex gap-x-8">
          <div className="p-4 mb-3 md:w-1/2 lg:w-5/12 schedule-div">
            <p className="text-xl font-semibold ">Sellers detail</p>
            <div className="h-64 overflow-y-scroll md:h-fit">
              <div className="flex-row mt-3 lg:flex gap-x-4">
                <div className="grid justify-center ">
                  <div className="w-24 h-24 bg-white rounded-[50%]"></div>
                  <p className="text-center">{data.name}</p>
                </div>
                <div className="overflow-scroll text-lg lg:w-3/4">
                  <div className="flex flex-row justify-between">
                    <p>Accounts owned</p>
                    <p>{data.accountsOwned}</p>
                  </div>
                  <div className="flex-row justify-between lg:flex lg:gap-x-2">
                    <p>Gmail</p>
                    <p>{data.email}</p>
                  </div>
                  {/* <div className="flex flex-row justify-between">
                    <p>Total value of accounts</p>
                    <p>0</p>
                  </div> */}
                </div>
              </div>
              <div className="mt-6 ">
                <p className="text-xl font-semibold ">Account summary</p>
                <div className="grid gap-1 mt-3 lg:grid-cols-2 xl:gap-20 md:gap-4">
                  <div className="flex flex-row justify-between">
                    <p className=" text-no-new-message">Account level</p>
                    <p>{account.accountLevel} level</p>
                  </div>
                  <div className="flex flex-row justify-between">
                    <p className=" text-no-new-message">Tenure of ownership</p>
                    <p>
                      {account.ownershipTenure.years} years{" "}
                      {account.ownershipTenure.months} months
                    </p>
                  </div>
                </div>
                <div className="mt-6">
                  <p className=" text-no-new-message">Special mentions</p>
                  <p>{account.specialMention}</p>
                </div>
                <div className="grid mt-3 lg:grid-cols-2 md:gap-4 xl:gap-20">
                  <div className="flex flex-row justify-between">
                    <p className=" text-no-new-message">Memorable frames</p>
                    <div>
                      {account.memorableFrames.map((frame) => (
                        <p>{frame}</p>
                      ))}
                    </div>
                  </div>
                  <div className="flex flex-row justify-between">
                    <p className=" text-no-new-message">Memorable tiles</p>
                    <div>
                      {account.memorableTitles.map((title) => (
                        <p>{title}</p>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="px-3 py-3 mb-3 sm:px-6 md:w-1/2 lg:w-7/12 sm:py-7 schedule-div">
            <p className="text-xl font-semibold sm:text-2xl ">Schedule event</p>
            <div className="flex flex-row justify-between px-1 py-3 mt-6 text-xl font-semibold sm:px-4 sm:text-2xl lg:text-3xl input-field ">
              <p>{account.game}</p>
              <p>₹{account.price}</p>
            </div>
            <div className="mt-6 ">
              <p className="text-lg font-semibold sm:text-xl ">
                Schedule date and time -
              </p>
              <div className="grid mt-3 lg:grid-cols-2 gap-x-7">
                <div>
                  <label
                    htmlFor="date"
                    className=" text-no-new-message text-[16px] h-4 mb-[2px]"
                  >
                    Select date
                  </label>
                  <div className="flex mt-1 input-field h-[52px] p-0">
                    <DatePicker
                      placeholderText=" Select date"
                      selected={date}
                      onChange={(date) => setDate(date)}
                      className="bg-transparent h-[52px]  w-[100%] date-picker p-2"
                      showYearDropdown
                      dateFormat="dd/MM/yyyy"
                      scrollableYearDropdown
                      yearDropdownItemNumber={50}
                      ref={datepickerRef}
                      name="date"
                      minDate={new Date()}
                      disabledKeyboardNavigation
                      onFocus={(e) => e.target.blur()}
                    />

                    <button
                      className="p-3 text-xl"
                      type="button"
                      onClick={() => handleClickDatepickerIcon()}
                    >
                      <HiOutlineCalendar />
                    </button>
                  </div>
                </div>
                <div>
                  <p className=" text-no-new-message text-[16px] h-4 mb-[2px]">
                    Select time
                  </p>
                  <div className="flex flex-row p-2 gap-x-2">
                    <div>
                      {/* <input
                        type="number"
                        min={1}
                        max={12}
                        name="time.hours"
                        placeholder="--"
                        className="w-16 input-field"
                        onChange={(e) =>
                          setTime({
                            hours: e.target.value,
                            minute: time.minute,
                            AM: time.AM,
                            PM: time.PM,
                          })
                        }
                      /> */}
                      <select
                        name="time.hours"
                        id="time.hours"
                        className="w-12 bg-black input-field"
                        onChange={(e) =>
                          setTime({
                            hours: e.target.value,
                            minute: time.minute,
                            AM: time.AM,
                            PM: time.PM,
                          })
                        }
                      >
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                      </select>
                      <label htmlFor="time.hours" className="mx-1 ">
                        hours
                      </label>
                    </div>
                    <div>
                      {/* <input
                        type="number"
                        min={0}
                        max={59}
                        name="time.mimute"
                        placeholder="--"
                        className="w-16 input-field"
                        onChange={(e) =>
                          setTime({
                            hours: time.hours,
                            minute: e.target.value,
                            AM: time.AM,
                            PM: time.PM,
                          })
                        }
                      /> */}
                      <select
                        name="time.minute"
                        id="time.minute"
                        className="w-12 bg-black input-field"
                        onChange={(e) =>
                          setTime({
                            hours: time.hours,
                            minute: e.target.value,
                            AM: time.AM,
                            PM: time.PM,
                          })
                        }
                      >
                        <option value="0">00</option>
                        <option value="15">15</option>
                        <option value="30">30</option>
                        <option value="45">45</option>
                      </select>
                      <label htmlFor="time.minute" className="mx-1 ">
                        minute
                      </label>
                    </div>
                    <div className="flex gap-2 input-field">
                      <button
                        type="button"
                        className={`${time.PM ? "" : " bg-neutral-700"}`}
                        onClick={() => {
                          setTime({
                            hours: time.hours,
                            minute: time.minute,
                            AM: true,
                            PM: false,
                          });
                        }}
                      >
                        AM
                      </button>
                      <button
                        type="button"
                        className={` ${time.PM ? " bg-neutral-700" : ""}`}
                        onClick={() => {
                          setTime({
                            hours: time.hours,
                            minute: time.minute,
                            AM: false,
                            PM: true,
                          });
                        }}
                      >
                        PM
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6 font-medium text-center">
              <p className=" text-grey-text">
                purchase schedule on date{" "}
                {date !== null ? date.toLocaleDateString() : " "} and time{" "}
                {time.hours}:{time.minute < 10 && "0"}
                {time.minute} <span>{time.PM ? PM : AM}</span>
              </p>
              {isSeller && (
                <div className="mt-4 text-grey-text">
                  {" "}
                  <input
                    type="checkbox"
                    name="checked"
                    onClick={() => setChecked(!checked)}
                  />{" "}
                  Share prize with other buyers until the account is sold
                </div>
              )}
              <div className="flex flex-col-reverse gap-1 mt-6 text-xl xs:gap-2 md:gap-3 sm:grid sm:grid-cols-2 sm:gap-4 md:flex md:flex-col-reverse lg:grid lg:gap-8 lg:grid-cols-2">
                <button
                  onClick={() => {
                    // setScheduleBuy(!scheduleBuy);
                    setTime({ hours: null, minute: null });
                    setDate(null);
                    navigate(-1);
                  }}
                  className="py-1 border border-yellow-500 border-solid rounded-lg sm:py-2 lg:py-4 bg-neutral-700"
                >
                  Go back to details
                </button>
                <button
                  className="py-1 bg-yellow-500 rounded-lg sm:py-2 lg:py-4"
                  onClick={handleSubmit}
                >
                  {isSeller ? "Book" : "Propose schedule"}
                </button>
              </div>
              {!isSeller && (
                <p className="mt-6 text-grey-text">
                  **Need to pay 10% of total cost while scheduling
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleBuy;
