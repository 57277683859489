import React from "react";
import { ErrorMessage, Field } from "formik";
import TextInput from "../../../utilities/TextInput";

const BankDetails = ({ selected, formik }) => {
  const handleKeyDown = (e) => {
    const { key } = e;
    if ((key >= 0 && key <= 9) || key === "Enter" || key === "Backspace") {
      formik.handleChange(e);
      if (key === "Enter") {
        const form = e.target.form;
        const index = Array.prototype.indexOf.call(form, e.target);
        form.elements[index + 1].focus();
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  };
  return (
    <div
      className={`${
        selected ? "h-80 xs:h-96 overflow-y-scroll scrollbar" : ""
      }`}
    >
      <div className="px-4 py-3 mb-3 social-div">
        <p className="text-[18px] font-medium leading-5 text-white">
          Bank details
        </p>
        <div className="grid h-64 mt-2 overflow-y-scroll xs:h-fit sm:grid-cols-2 gap-x-7 scrollbar">
          <TextInput
            type="tel"
            label="Account number"
            name="accountDetails.accountNumber"
            placeholder="Enter account number"
            className="mb-2"
            onKeyDown={handleKeyDown}
            // type="number"
          />
          <TextInput
            label="Confirm acccount number"
            name="accountDetails.confirmAccountNumber"
            placeholder="Confirm account number"
            className="mb-2"
            onKeyDown={handleKeyDown}
            // type="number"
          />
          <TextInput
            label="IFSC number"
            name="accountDetails.IFSCNumber"
            placeholder="Enter IFSC number"
            className="mb-2"
          />
          <TextInput
            label="Name of beneficiary"
            name="accountDetails.beneficiaryName"
            placeholder="Enter your beneficiary"
            className="mb-2"
          />
        </div>
      </div>
    </div>
  );
};

export default BankDetails;
