import React from "react";
import { useState, useEffect } from "react";
import useToken from "../../hooks/useToken";
import useApi from "../../hooks/useApi";
import agentApi from "../../api/agent";

import Lottie from "react-lottie";
import whiteLoader from "../../images/white-loader.json";
import { Link, useNavigate } from "react-router-dom";
import RegisterButton from "../../components/Buttons/RegisterButton";

const AgentLogin = () => {
  const [otp, setOtp] = useState("");
  // const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [invalid, setValidation] = useState("");

  const { setToken } = useToken();
  const navigate = useNavigate();

  const {
    data: sent,
    error,
    loading,
    request: sendOTP,
  } = useApi(agentApi.sendOTP);
  const {
    data,
    error: err,
    loading: signing,
    request: login,
  } = useApi(agentApi.login);

  const handleOTP = () => {
    // if (isNaN(phoneNumber) || phoneNumber.length !== 10) {
    //   return alert("please enter a valid phone number");
    // }
    if (
      !String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      return alert("please enter a valid email");
    }
    sendOTP({ email: email });
  };

  const handleLogin = async () => {
    if (
      !String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      return alert("please enter a valid email");
    }
    // if (phoneNumber.length !== 10) {
    //   return alert("please enter correct number");
    // }
    if (otp.length < 6) {
      return alert("please enter a valid OTP");
    }
    await login({
      // phoneNumber: phoneNumber,
      email: email,
      otp: otp,
    });
  };

  useEffect(() => {
    if (!err && !signing && data) {
      setToken(data.token);
      navigate("/agentdashboard", { replace: true });
    }
  }, [data]);
  useEffect(() => {
    if (error && !loading) {
      alert(sent);
    }
  }, [error, loading]);
  useEffect(() => {
    if (err && !signing) {
      alert(data);
    }
  }, [err, signing]);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: whiteLoader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const emailValidation = () => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email) {
      setValidation("email is required");
    } else if (regex.test(email) === false) {
      setValidation("email is invalid");
    } else {
      setValidation("");
    }
  };
  useEffect(() => {
    if (email) {
      emailValidation(email);
    }
  }, [email]);

  return (
    <div>
      <div className="w-screen h-screen pb-4 background-image">
        <div className="h-[64px] flex justify-between">
          <Link to={"/"}>
            <div className="flex w-full h-8 gap-2 mx-3 my-6 text-2xl font-bold text-yellow-500 sm:gap-1 sm:my-4 sm:h-10 sm:text-3xl sm:mx-8 font-heading">
              <img
                src={require("../../images/logo.png")}
                alt=""
                className="h-full text-yellow-500 "
              />
              PREMIUMCORES
            </div>
          </Link>
        </div>

        <div className="flex justify-center h-[85vh] text-white  ">
          <div className="w-full px-6 py-8 mx-8 my-auto rounded-md sm:mx-auto sm:w-auto signup-box ">
            <p className="text-2xl font-bold tracking-tighter lg:text-5xl sm:text-3xl font-heading">
              Agent Login
            </p>

            <div className="grid mt-8 ">
              <label htmlFor="email">Email</label>

              <input
                type="text"
                name="email"
                value={email}
                className="w-full h-12 input-field"
                placeholder=" Enter email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                onBlur={() => emailValidation(email)}
              />
              <span className="text-sm text-red-400 ">{invalid}</span>
              {/* <input
                maxLength={10}
                type="text"
                name="whatsappNumber"
                value={phoneNumber}
                className="w-full h-12 input-field"
                placeholder=" Enter Whatsapp number"
                onChange={(e) => {
                  if (e.target.value >= 0 && e.target.value <= 9999999999) {
                    setPhoneNumber(e.target.value);
                  }
                }}
              /> */}
            </div>

            <div className="grid mt-3 sm:grid-cols-2 gap-x-7">
              <div className="grid gap-x-3">
                <label htmlFor="otp">Enter OTP </label>
                <input
                  className="w-full h-12 px-4 input-field "
                  name="otp"
                  placeholder=" Enter 6 digit OTP"
                  onChange={(e) => {
                    if (e.target.value >= 0 && e.target.value <= 999999) {
                      setOtp(e.target.value);
                    }
                  }}
                  value={otp}
                />
              </div>
              <div className="pt-6 ">
                <button
                  onClick={handleOTP}
                  type="button"
                  className="h-10 px-8 py-1 font-semibold bg-transparent bg-yellow-600 rounded-md"
                >
                  {!loading && !sent && " Send OTP"}

                  {loading && (
                    <div className="h-4">
                      <Lottie options={defaultOptions} width={100} />
                    </div>
                  )}

                  {sent && !loading && "Resend OTP"}
                </button>
              </div>
            </div>
            <div className="py-1 text-sm text-center text-yellow-600 ">
              {sent && !loading && !error && "OTP sent on your Email Id"}
            </div>

            <div className="mt-4 ">
              <button
                type="button"
                onClick={handleLogin}
                className="w-full hover:scale-100"
              >
                {/* {!data && "Login"}
                {signing && (
                  <div className="h-4">
                    <Lottie options={defaultOptions} width={100} />
                  </div>
                )} */}
                <RegisterButton
                  loading={signing}
                  text="LOG IN"
                  className="w-full h-10 pt-2 font-semibold"
                />
              </button>
            </div>
            <div className="mt-4 text-center ">
              Don't have an agent account ?{" "}
              <Link
                to={"/agentsignup"}
                className="font-semibold text-yellow-500 "
              >
                Signup
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentLogin;
