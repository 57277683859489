import React, { useState, useRef, useEffect, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import { MdOutlineNotifications, MdContentCopy } from "react-icons/md";
import { io } from "socket.io-client";

import Stepper from "../../screens/AccountModal/Stepper";
import Notification from "../../screens/components/Notification";
import BTermsOfService from "./BTermsOfService";
import Confirmation from "./Confirmation";
import DepositeFunds from "./DepositeFunds";
import FetchValidate from "./FetchValidate";
import SecureAccount from "./SecureAccount";
import SellerStep from "./SellerStep";
import ValidateSocials from "./ValidateSocials";
import AuthContext from "../../utilities/context";
import useApi from "../../hooks/useApi";
import userDetailsApi from "../../api/userDetails";
import Loader from "../../screens/components/Loader";
import RegisterButton from "../../components/Buttons/RegisterButton";
import roomApi from "../../api/room";
import { baseURL } from "../../shared/baseURL";
import BuyerPage from "../../screens/Guest/BuyerPage";

const BXRoom = () => {
  const { id } = useParams();
  const [guestId, setGuestId] = useState();
  const [isCopied, setIsCopied] = useState(false);
  const [referral, setReferral] = useState("");
  // const Attendees = [1, 2];
  const steps = [
    "Sign contracts",
    "Deposit funds",
    "Seller steps",
    "Fetch & validate credentials",
    "Validate socials",
    "Secure account",
    "Confirmation",
  ];
  const [currentStep, setCurrentStep] = useState(1);
  const [seller, setSeller] = useState({});

  const [agree, setAgree] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  let [loading, setLoading] = useState(false);
  const [onlineXroomMembers, setOnlineXroomMembers] = useState();

  const { userData } = useContext(AuthContext);

  const { state } = useLocation();

  const socket = useRef();

  const { data: secondUser, request: getSecondUser } = useApi(
    userDetailsApi.getUserDetails
  );

  const { data: room, request: getRoom } = useApi(roomApi.getRoom);

  const [time, setTime] = useState(0);
  const __DEV__ = process.env.NODE_ENV === "development";
  const updateGuest = async (guest) => {
    const res = await fetch(baseURL + `/xroom/update/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ guest: true }),
    });

    const room = await res.json();
    setGuestId(room.guestId);
  };
  useEffect(() => {
    if (id) {
      getRoom(id);
    } else if (state?.room) {
      getRoom(state.room?._id);
    }

    // console.log(room);
  }, [id]);
  useEffect(() => {
    setSeller(room?.seller);
    // console.log("seller : ", seller);
  }, [room?.seller]);
  useEffect(() => {
    if (userData) {
      socket.current = io(
        __DEV__ ? "http://localhost:8000" : "https://pc-web-server.onrender.com"
      );
      socket.current.on("connect", () =>
        console.log("XRoom Connected to " + socket.current.id)
      );
      socket.current.emit("new-xroom-member-add", userData._id);

      socket.current.on("get-xroom-members", (users) => {
        setOnlineXroomMembers(users);
        console.log(users);
      });

      // socket.current.on("receive-message", (data) => {
      //   setReceiveMessage(data);
      // });
      // socket.current.on("receive-bid-proposal", (data) => {
      //   setReceiveBidProposal(data);
      // });
    }

    if (state?.room !== undefined || room) {
      socket.current = io(
        __DEV__ ? "http://localhost:8000" : "https://pc-web-server.onrender.com"
      );
      // socket.current = io("https://pc-web-server.onrender.com");
      socket.current.on("connect", () =>
        console.log("XRoom Connected to " + socket.current.id)
      );

      if (guestId && !room.guestId) {
        socket.current.emit("new-xroom-member-add", guestId);
        socket.current.emit("send-guest-details", {
          data: guestId,
          receiverId: room.seller.sellerId,
        });
      }
      if (state?.room.guestId && !room?.guestId) {
        socket.current.emit("new-xroom-member-add", state.room.guestId);
      }
      if (room?.guestId) {
        socket.current.emit("new-xroom-member-add", room.guestId);
      }
      socket.current.on("get-xroom-members", (users) => {
        setOnlineXroomMembers(users);
        // console.log(users);
      });
      socket.current.on("receive-seller-details", (data) => {
        console.log("data recieved : ", data);
        setSeller(data.data.seller);
      });
    }
  }, [userData, state?.room, room, id, guestId]);

  useEffect(() => {
    let interval = null;

    interval = setInterval(() => {
      setTime((time) => time + 10);
    }, 10);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const sendBuyerDepositStatus = () => {
    socket.current.emit("buyer-deposit-status", {
      status: "money_deposited",
      receiverId: state?._doc.sellerId ? state._doc.sellerId : seller.sellerId,
    });
  };

  function Timer(props) {
    return (
      <div className="timer">
        <span className="digits">
          {("0" + Math.floor((props.time / 60000) % 60)).slice(-2)}:
        </span>
        <span className="digits">
          {("0" + Math.floor((props.time / 1000) % 60)).slice(-2)}
        </span>
      </div>
    );
  }

  useEffect(() => {
    if (state) {
      // console.log(state);
      if (!state.guest) {
        getSecondUser(state._doc.sellerId);
      }
    }
  }, []);

  const checkOnlineStatus = (secondUserId) => {
    const online = onlineXroomMembers?.find(
      (user) => user.userId === secondUserId
    );
    // console.log(online);
    return online ? true : false;
  };

  // console.log(state);
  if (!guestId && id && !state?.room?.guestId) {
    return (
      <BuyerPage
        room={room}
        updateGuest={updateGuest}
        setGuestId={setGuestId}
      />
    );
  }

  if (!secondUser && !room) return <Loader />;

  const displayStep = (step) => {
    switch (step) {
      case 1:
        return (
          <BTermsOfService
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            setAgree={setAgree}
          />
        );

      case 2:
        return (
          <DepositeFunds
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            setLoading={setLoading}
            data={
              (id && room.seller) || seller ? seller : { ...state, secondUser }
            }
            sendBuyerDepositStatus={sendBuyerDepositStatus}
          />
        );

      case 3:
        return (
          <SellerStep
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            loading={loading}
            setLoading={setLoading}
          />
        );

      case 4:
        return (
          <FetchValidate
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        );

      case 5:
        return (
          <ValidateSocials
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        );

      case 6:
        return (
          <SecureAccount
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        );

      case 7:
        return (
          <Confirmation
            referral={referral}
            sellerId={
              state?._doc?.sellerId
                ? state?._doc.sellerId
                : room.seller.sellerId
            }
            scheduleId={state?._doc?._id ? state._doc._id : room._id}
            room={room?._id}
            userData={userData && userData}
            data={
              (id && room.seller) || seller ? seller : { ...state, secondUser }
            }
          />
        );

      default:
    }
  };

  const handleCopylink = async () => {
    try {
      await navigator.clipboard.writeText(
        state?.link
          ? state.link
          : __DEV__
          ? `http://localhost:3000/sxroom/${room._id}`
          : `https://pc-website.onrender.com/sxroom/${room._id}`
      );
      setIsCopied(true);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <div className="relative ">
      <div className="mx-5 text-white md:mx-20">
        <div className="flex justify-between h-16 py-4">
          <div>
            <img
              src={require("../../images/logo.png")}
              alt="premiumcores"
              className="w-10 "
            />
          </div>
          <div>
            <button
              onClick={() => {
                setShowNotification(!showNotification);
              }}
            >
              <MdOutlineNotifications className="relative w-8 h-8" />
            </button>
          </div>
        </div>
        {showNotification && (
          <div className="absolute top-0 z-50 -ml-5 text-white sm:relative">
            <Notification setShowNotification={setShowNotification} />
          </div>
        )}
        <div className="mt-4">
          {state?.link && (
            <>
              <div className="text-sm text-yellow-600 ">
                copy link below and share to seller
              </div>
              <div className="flex justify-between px-3 py-1 bg-gray-900">
                <p className="w-[60vw] overflow-x-scroll sm:w-full scrollbar-hide">
                  {state.link}
                </p>
                <button onClick={handleCopylink} className="flex">
                  <span className="p-1 ">
                    <MdContentCopy />
                  </span>
                  {isCopied ? "Copied!" : "Copy"}
                </button>
              </div>
            </>
          )}
          {!room?.seller && !state?.link && room?._id && (
            <>
              {" "}
              <div className="text-sm text-yellow-600 ">
                copy link below and share to seller
              </div>
              <div className="flex justify-between px-3 py-1 bg-gray-900">
                <p className="w-[60vw] overflow-x-scroll sm:w-full scrollbar-hide">
                  {__DEV__
                    ? `http://localhost:3000/sxroom/${room?._id}`
                    : `https://pc-website.onrender.com/sxroom/${room?._id}`}
                </p>
                <button onClick={handleCopylink} className="flex">
                  <span className="p-1 ">
                    <MdContentCopy />
                  </span>
                  {isCopied ? "Copied!" : "Copy"}
                </button>
              </div>
            </>
          )}
          <h1 className="text-3xl font-semibold text-center sm:text-4xl">
            X-room
          </h1>
          {room === "undefined" && (
            <div>
              <label htmlFor="referral">Referral Code : </label>
              <input
                type="text"
                name="referral"
                value={referral}
                placeholder="Referral code if any"
                className="input-field"
                onChange={(e) => {
                  setReferral(e.target.value);
                }}
              />
            </div>
          )}
          <div className="mt-4 lg:flex lg:flex-row gap-x-7">
            <div className="w-full p-3 mb-2 schedule-div lg:w-1/4">
              <p className="font-semibold ">Attendees</p>
              <div className="flex w-full overflow-x-scroll scrollbar gap-x-2 lg:block ">
                <div className="p-3 mt-3 border-2 rounded-lg bg-black-800 border-black-700 solid">
                  <div className="flex flex-row space-x-1">
                    <p>Time elapsed - </p>
                    <Timer time={time} />
                  </div>

                  <div className="flex justify-center mt-1 sm:mt-7">
                    <div className="w-20 h-20 mb-2 bg-white rounded-full lg:w-32 md:w-24 md:h-24 lg:h-32 ">
                      <img
                        src={require("../../images/seller.png")}
                        alt="image"
                        className="rounded-full"
                      />
                    </div>
                  </div>
                  <div className="flex flex-row justify-between">
                    {(state?.guest === "undefined" && !guestId) ||
                    userData?.firstName ? (
                      <p className="pb-1">
                        {userData?.firstName + " " + userData?.lastName}
                      </p>
                    ) : (
                      <div>
                        <p>{guestId ? guestId : state?.room?.guestId}</p>
                      </div>
                      // <p>{state.room.userId}</p>
                    )}
                    <div className="flex flex-row w-24 h-8 gap-1 px-3 my-auto ml-2 text-success online">
                      <div className="w-2 h-2 my-auto rounded-full bg-success"></div>
                      <p className="pt-1 text-sm">Online</p>
                    </div>
                  </div>
                </div>
                <div className="p-3 min-w-[180px] mt-3 border-2 rounded-lg bg-black-800 border-black-700 solid ">
                  <div className="flex flex-row space-x-1">
                    <p>Time elapsed - </p>
                    <Timer time={time} />
                  </div>
                  <div className="flex justify-center mt-1 sm:mt-7">
                    <div className="w-20 h-20 mb-2 bg-white rounded-full lg:w-32 md:w-24 md:h-24 lg:h-32 ">
                      <img
                        src={require("../../images/seller.png")}
                        alt="image"
                        className="rounded-full"
                      />
                    </div>
                  </div>
                  <div className="flex flex-row justify-between">
                    {state?.guest === "undefined" || secondUser?.name ? (
                      <>
                        <p className="pb-1">{secondUser?.name}</p>
                        {checkOnlineStatus(state?._doc.sellerId) && (
                          <div className="flex flex-row gap-1 px-3 ml-2 text-success online">
                            <div className="w-2 h-2 my-auto rounded-full bg-success"></div>
                            <p>Online</p>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {!seller && (
                          <p className="w-full text-center text-red-600">
                            ask seller to join
                          </p>
                        )}

                        {seller?.sellerId && seller.sellerId}

                        {/* <div className="flex flex-row gap-1 px-3 ml-2 text-success online">
                          <div className="w-2 h-2 my-auto rounded-full bg-success"></div>
                          <p>Online</p>
                        </div> */}
                        {seller?.sellerId && (
                          <div className="flex flex-row gap-1 px-3 ml-2 text-success online">
                            <div className="w-2 h-2 my-auto rounded-full bg-success"></div>
                            <p>Online</p>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full p-3 mb-2 lg:w-3/4 schedule-div">
              <div className="w-full pb-2 mt-4 sm:px-5 md:px-10">
                <Stepper steps={steps} currentStep={currentStep} />
              </div>

              <div className="px-3 py-2 border-2 rounded-lg lg:py-4 sm:mt-16 bg-black-800 border-dark-700 md:h-fit solid h-96">
                {displayStep(currentStep)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BXRoom;
