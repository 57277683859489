import React, { useEffect, useState } from "react";
import Stepper from "./Stepper";
// import Account from "./Steps/Account";
// import Details from "./Steps/Details";
// import Final from "./Steps/Final";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { MdArrowBackIos, MdOutlineClose } from "react-icons/md";
import { FaChevronRight } from "react-icons/fa";
import AccountDetails from "./Steps/AccountDetails";
import InventoryDetails from "./Steps/InventoryDetails";
import Uploads from "./Steps/Uploads";
import SocialAccounts from "./Steps/SocialAccounts";
import BankDetails from "./Steps/BankDetails";
import useApi from "../../hooks/useApi";
import accountApi from "../../api/account";
import { useContext } from "react";
import AuthContext from "../../utilities/context";

const AccountFormModal = ({ handleForm, setSelected, selected }) => {
  const validate = Yup.object().shape({
    game: Yup.string().required("please select a game"),
    price: Yup.number().required("please enter price"),
    playerID: Yup.number().required("please enter player ID"),
    // fbtc: false,
    // twtc: false,
    // emtc: false,
    // motc: false,
    ownershipTenure: Yup.object().shape({
      years: Yup.number()
        .required("please enter years")
        .min(0, "years should be positive number"),
      months: Yup.number()
        .required("please enter months")
        .max(11, "months can't be greater than 11")
        .min(0, "months should be positive number"),
    }),
    accountLevel: Yup.number().required("please enter account level"),
    highlights: Yup.string().required("please enter highlights"),
    inventoryDetails: Yup.string().required("please enter some details"),
    specialMention: Yup.string().required("please enter some special mentions"),
    facebook: Yup.object().shape({
      username: Yup.string().required("please enter fb username"),
      password: Yup.string()
        .required("please enter fb password")
        .min(6, "password must be greater than 6 characters"),
    }),
    twitter: Yup.object().shape({
      username: Yup.string().required("please enter twitter username"),
      password: Yup.string()
        .required("please enter twitter password")
        .min(6, "password must be greater than 6 characters"),
    }),
    accountDetails: Yup.object().shape({
      accountNumber: Yup.number()
        .required("please enter account number")
        .min(9999999, "account number must be greater than 8 digits"),
      confirmAccountNumber: Yup.number().oneOf(
        [Yup.ref("accountNumber"), null],
        "account number does not match"
      ),
      IFSCNumber: Yup.string().required("please enter IFSC code"),
      beneficiaryName: Yup.string().required("please enter beneficiary name"),
    }),
  });

  // const [formData, setFormData] = useState({
  // 	loan_name: "",
  // 	loan_type: "1",
  // 	loan_amount: "",
  // 	loan_purpose: "",
  // 	loan_tenure: "",
  // 	loan_interest: "",
  // 	payment_frequency: "",
  // });
  const [currentStep, setCurrentStep] = useState(1);
  const [brJson, setBrJson] = useState();
  const [checkBox, setCheckBox] = useState(false);

  const { userData } = useContext(AuthContext);

  const steps = [
    "Account details",
    "Inventory details",
    "Uploads",
    "Social accounts",
    "Bank details",
  ];
  const [seasons, setSeasons] = useState([
    {
      title: "Levels",
      items: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
        39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56,
        57, 58, 59, 60,
      ],
    },
    { title: "Regular", items: [] },
    { title: "Elite", items: [] },
    { title: "Elite Plus", items: [] },
  ]);
  const [accountThumbnail, setAccountThumbnail] = useState();
  const [filesArray2, setFilesArray2] = useState();
  const [titlesInput, setTitlesInput] = useState("");
  const [titlesArr, setTitlesArr] = useState([]);
  const [isTitlesKeyReleased, setIsTitlesKeyReleased] = useState(false);
  const [framesInput, setFramesInput] = useState("");
  const [framesArray, setFramesArray] = useState([]);
  const [isFramesKeyReleased, setIsFramesKeyReleased] = useState(false);

  const displayStep = (step, formik, selected) => {
    switch (step) {
      case 1:
        return (
          <AccountDetails
            formik={formik}
            selected={selected}
            handleForm={handleForm}
          />
        );
      case 2:
        return (
          <InventoryDetails
            // handleNext={handleNext}
            // handlePrev={handlePrev}
            selected={selected}
            formik={formik}
            seasons={seasons}
            setSeasons={setSeasons}
            framesInput={framesInput}
            setFramesInput={setFramesInput}
            framesArray={framesArray}
            setFramesArray={setFramesArray}
            isFramesKeyReleased={isFramesKeyReleased}
            setIsFramesKeyReleased={setIsFramesKeyReleased}
            titlesInput={titlesInput}
            setTitlesInput={setTitlesInput}
            titlesArr={titlesArr}
            setTitlesArr={setTitlesArr}
            isTitlesKeyReleased={isTitlesKeyReleased}
            setIsTitlesKeyReleased={setIsTitlesKeyReleased}
          />
        );
      case 3:
        return (
          <Uploads
            // handlePrev={handlePrev}
            // finalSubmit={finalSubmit}
            formData={formik}
            // setCheckBox={setCheckBox}
            // checkBox={checkBox}
            accountThumbnail={accountThumbnail}
            setAccountThumbnail={setAccountThumbnail}
            filesArray2={filesArray2}
            setFilesArray2={setFilesArray2}
          />
        );
      case 4:
        return <SocialAccounts formik={formik} />;
      case 5:
        return <BankDetails selected="true" formik={formik} />;
      default:
    }
  };

  // const finalSubmit = async (data) => {
  // 	//..................

  // 	setCurrentStep((prevCurrentStep) => prevCurrentStep + 1);
  // };

  const handleNext = (newData, value) => {
    // if (value === true) {
    // 	let temp = { ...formData, ...newData };
    // 	setFormData(temp);
    // } else {
    // 	setFormData((prev) => ({ ...prev, ...newData }));
    // }
    setCurrentStep((prevCurrentStep) => prevCurrentStep + 1);
  };

  const handlePrev = (newData, value) => {
    // if (value === true) {
    // 	let temp = { ...formData, ...newData };
    // 	setFormData(temp);
    // } else {
    // 	setFormData((prev) => ({ ...prev, ...newData }));
    // }
    setCurrentStep((prevCurrentStep) => prevCurrentStep - 1);
    displayStep(currentStep);
  };

  const {
    data: accountData,
    error,
    request: addAccount,
  } = useApi(accountApi.postAccount);

  const handleSubmit = (formik) => {
    if (formik.values.game === "") {
      return alert(" please fill the details");
    }
    let clientError = Object.keys(formik.errors);
    if (clientError.length !== 0) {
      return alert(
        "please fill details correctly, " +
          "errors in :   " +
          JSON.stringify(clientError)
      );
    }
    const data = {
      ...formik.values,
      memorableFrames: framesArray,
      memorableTitles: titlesArr,
      royalPassDetails: seasons,
      accountThumbnail,
      accountMedia: filesArray2,
      sellerId: userData?._id,
    };
    console.log(data);
    addAccount(data);
    if (error) return alert(JSON.stringify(data));
    if (accountData === "Account added successfully.") {
      setSelected(false);
      return alert("Account added successfully");
    }
  };

  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center w-full h-screen transition duration-300 modalBackground backdrop-blur">
      <Formik
        initialValues={{
          game: "",
          price: "",
          playerID: "",
          ingameName: "xyz",
          fbtc: true,
          twtc: true,
          emtc: true,
          motc: true,
          ownershipTenure: {
            years: "",
            months: "",
          },
          accountLevel: "",
          highlights: "",
          inventoryDetails: "",
          specialMention: "",
          facebook: {
            username: "",
            password: "",
          },
          twitter: {
            username: "",
            password: "",
          },
          accountDetails: {
            accountNumber: "",
            confirmAccountNumber: "",
            IFSCNumber: "",
            beneficiaryName: "",
          },
        }}
        validationSchema={validate}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          return false;
        }}
      >
        {(formik) => (
          <div className="w-full h-screen xs:h-[98%] py-5 text-white border-2 rounded-none xs:mx-2 sm:h-fit xs:rounded-lg sm:rounded-xl md:w-10/12 xl:w-8/12 2xl:w-7/12 schedule-div solid border-dark-700">
            <Form>
              <div className="">
                <div className="flex justify-between px-3 text-lg font-semibold sm:px-6">
                  <h3 className=" dark:text-white  md:border-b-[#292C33] md:pb-3">
                    List Account
                  </h3>
                  <button
                    type="button"
                    onClick={() => {
                      setSelected(false);
                    }}
                  >
                    <span className="text-2xl">
                      <MdOutlineClose />
                    </span>
                  </button>
                </div>
                <hr className="border border-grey bg-grey" />

                <div className="px-1 mt-6 xs:px-4 sm:px-6">
                  <div className="px-1 sm:px-8">
                    <Stepper steps={steps} currentStep={currentStep} />
                  </div>
                  <div className="mt-2 h-[60vh] md:h-fit px-3 sm:mt-20">
                    {displayStep(currentStep, formik, selected)}
                  </div>
                  {currentStep <= steps.length && (
                    <div
                      className={`grid px-4 grid-cols-2 mt-0s sm:mt-1 gap-x-7`}
                    >
                      <button
                        onClick={
                          currentStep === 1
                            ? () => {
                                setSelected(false);
                              }
                            : handlePrev
                        }
                        type="button"
                      >
                        {currentStep === 1 ? (
                          "Cancel"
                        ) : (
                          <p className="flex justify-center">
                            <MdArrowBackIos className="my-1 " /> Back
                          </p>
                        )}
                      </button>

                      <button
                        onClick={
                          currentStep === steps.length
                            ? () => {
                                handleSubmit(formik);
                              }
                            : handleNext
                        }
                        type="button"
                        className="py-2 text-black bg-yellow-500 rounded-lg"
                      >
                        {currentStep === steps.length ? (
                          "Submit"
                        ) : (
                          <p className="flex justify-center pt-1">
                            Next
                            <span className="flex justify-center my-auto ml-1 ">
                              <FaChevronRight />
                            </span>
                          </p>
                        )}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default AccountFormModal;
