import { ErrorMessage, Field } from "formik";
import React, { useRef, useState } from "react";
import ArrayInput from "../../../components/ArrayInput";
import TextInput from "../../../utilities/TextInput";

const InventoryDetails = (props) => {
  const {
    formik,
    seasons,
    setSeasons,
    selected,
    framesInput,
    setFramesInput,
    framesArray,
    setFramesArray,
    isFramesKeyReleased,
    setIsFramesKeyReleased,
    titlesInput,
    setTitlesInput,
    titlesArr,
    setTitlesArr,
    isTitlesKeyReleased,
    setIsTitlesKeyReleased,
  } = props;

  const [dragging, setDragging] = useState(false);
  const dragItem = useRef();
  const dragNode = useRef();
  const grp0 = 0;
  const grp1 = 1;
  const grp2 = 2;
  const grp3 = 3;

  const handleDragStart = (e, params) => {
    dragItem.current = params;
    dragNode.current = e.target;
    dragNode.current.addEventListener("dragend", handleDragEnd);
    setTimeout(() => {
      setDragging(true);
    }, 0);
  };
  const handleDragEnd = () => {
    setDragging(false);
    dragNode.current.removeEventListener("dragend", handleDragEnd);
    dragItem.current = null;
    dragNode.current = null;
  };
  const handleDragEnter = (e, params) => {
    const currentItem = dragItem.current;
    if (e.target !== dragNode.current) {
      setSeasons((oldList) => {
        let newList = JSON.parse(JSON.stringify(oldList));
        newList[params.grp].items.splice(
          params.index,
          0,
          newList[currentItem.grp].items.splice(currentItem.index, 1)[0]
        );
        dragItem.current = params;
        return newList;
      });
    }
  };
  const getStyles = (params) => {
    const currentItem = dragItem.current;
    if (currentItem.grp === params.grp && currentItem.index === params.index) {
      return " bg-gray-300 text-black-900";
    }
    return "";
  };
  const handleEnter = (e) => {
    const { key } = e;
    if (key === "Enter") {
      const form = e.target.form;
      const index = Array.prototype.indexOf.call(form, e.target);
      form.elements[index + 1].focus();
      e.preventDefault();
    }
  };
  const handleKeyDown = (e) => {
    const { key } = e;
    if ((key >= 0 && key <= 9) || key === "Enter" || key === "Backspace") {
      formik.handleChange(e);
      if (key === "Enter") {
        const form = e.target.form;
        const index = Array.prototype.indexOf.call(form, e.target);
        form.elements[index + 1].focus();
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  };
  return (
    <div
      className={`${
        selected ? "overflow-y-scroll scrollbar h-80 xs:h-96" : ""
      }`}
    >
      <div className="grid sm:grid-cols-2 gap-x-3 xs:gap-x-4 sm:gap-x-7 gap-y-1 sm:gap-y-3">
        <div>
          <p>Tenure of ownership</p>
          <div className="flex flex-row gap-5 p-2">
            <div>
              <Field
                type="tel"
                name="ownershipTenure.years"
                placeholder="--"
                className="w-10 input-field"
                onKeyDown={handleKeyDown}
                value={formik.values.ownershipTenure.years}
              />
              <label htmlFor="ownershipTenure.years" className="mx-1 ">
                years
              </label>
              <ErrorMessage
                name="ownershipTenure.years"
                component="div"
                className="text-red-400 error-message"
              />
            </div>
            <div>
              <Field
                type="tel"
                name="ownershipTenure.months"
                placeholder="--"
                className="w-10 input-field"
                onKeyDown={handleKeyDown}
                value={formik.values.ownershipTenure.months}
              />
              <label htmlFor="ownershipTenure.months" className="mx-1 ">
                months
              </label>
              <ErrorMessage
                name="ownershipTenure.months"
                component="div"
                className="text-red-400 error-message"
              />
            </div>
          </div>
        </div>
        <div>
          {/* <TextInput
            type="tel"
            name="accountLevel"
            label="Account level"
            placeholder="Enter account level"
            className="sm:mb-3"
          /> */}
          <label htmlFor="accountLevel" className="mx-1 ">
            Account level
          </label>
          <div>
            <Field
              type="tel"
              name="accountLevel"
              placeholder="Enter account level"
              className="w-full input-field"
              onKeyDown={handleKeyDown}
              value={formik.values.accountLevel}
            />

            <ErrorMessage
              name="accountLevel"
              component="div"
              className="text-red-400 error-message"
            />
          </div>
        </div>
        <div>
          <ArrayInput
            name="memorableFrames"
            label="Memorable Frames"
            input={framesInput}
            setInput={setFramesInput}
            tags={framesArray}
            setTags={setFramesArray}
            isKeyReleased={isFramesKeyReleased}
            setIsKeyReleased={setIsFramesKeyReleased}
          />
          <ErrorMessage
            className="text-red-400 error-message"
            name="memorableFrames"
            component="div"
          />
        </div>
        <div>
          <ArrayInput
            name="memorableTitsles"
            label="Memorable Titles"
            input={titlesInput}
            setInput={setTitlesInput}
            tags={titlesArr}
            setTags={setTitlesArr}
            isKeyReleased={isTitlesKeyReleased}
            setIsKeyReleased={setIsTitlesKeyReleased}
          />
          <ErrorMessage
            className="text-red-400 error-message"
            name="memorableTiles"
            component="div"
          />
        </div>
        <div>
          <TextInput
            name="highlights"
            label="Highlights"
            placeholder="Enter highlights of this account"
            onKeyDown={handleEnter}
          />
        </div>
        <div>
          <TextInput
            name="specialMention"
            label="Special mention"
            placeholder="Enter special mention"
            rows={6}
            onKeyDown={handleEnter}
          />
        </div>
      </div>
      <div>
        <TextInput
          name="inventoryDetails"
          label="Inventory Details"
          placeholder="Enter inventory details"
          textarea
          rows={6}
        />
      </div>
      <div>
        <p>Royal pass details</p>
        <div className="mt-5 lg:flex lg:flex-row gap-x-2">
          <div className="lg:w-1/12">
            <p>Levels -</p>
          </div>
          <div
            className="md:flex-row sm:grid sm:grid-cols-2 w-full gap-2 overflow-x-scroll scrollbar-hide min-h-[30px] h-20 md:flex lg:w-11/12 md:h-7"
            onDragEnter={
              dragging && !seasons[grp0].items.length
                ? (e) => handleDragEnter(e, { grp: grp0, index: 0 })
                : null
            }
          >
            {seasons[grp0].items.map((val, index) => {
              return (
                <div
                  className={`levels-season rounded-full px-14 mb-1 w-48 ${
                    dragging ? getStyles({ grp: grp0, index }) : ""
                  }`}
                  key={index}
                  draggable
                  onDragStart={(e) => {
                    handleDragStart(e, { grp: grp0, index });
                  }}
                  onTouchStart={(e) => {
                    handleDragStart(e, { grp: grp0, index });
                    console.log("touched");
                  }}
                  onDragEnter={
                    dragging
                      ? (e) => {
                          handleDragEnter(e, { grp: grp0, index });
                        }
                      : null
                  }
                >
                  <div className="relative text-center">
                    {" "}
                    <img
                      src={require("../../../images/season.png")}
                      className="absolute mt-2 -left-5"
                    />
                    <p className="w-20 pb-1 ">Season {val}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="grid mt-3 text-center sm:grid-cols-3 gap-7">
          <div>
            <p>{seasons[1].title}</p>
            <div
              className="flex flex-col items-center justify-center min-h-[128px] season-pass py-3 px-1"
              onDragEnter={
                dragging && !seasons[grp1].items.length
                  ? (e) => handleDragEnter(e, { grp: grp1, index: 0 })
                  : null
              }
            >
              {seasons[grp1].items.map((val, index) => {
                return (
                  <div
                    className={`levels-season w-full text-center ${
                      dragging ? getStyles({ grp: grp1, index }) : ""
                    }`}
                    key={index}
                    draggable
                    onDragStart={(e) => {
                      handleDragStart(e, { grp: grp1, index });
                    }}
                    onDragEnter={
                      dragging
                        ? (e) => {
                            handleDragEnter(e, { grp: grp1, index });
                          }
                        : null
                    }
                  >
                    <div className="relative text-center">
                      {" "}
                      <img
                        src={require("../../../images/season.png")}
                        className="absolute mt-2 left-3"
                      />
                      <p className="pb-1">Season {val}</p>
                    </div>
                  </div>
                );
              })}
              {seasons[grp1].items.length == 0 && (
                <>
                  <span className=" text-dark-gray-300">
                    {" "}
                    Drag here if had regular
                  </span>
                  <p className=" text-dark-gray-300">season pass</p>
                </>
              )}
            </div>
          </div>
          <div>
            <p className="text-yellow-500 ">Elite</p>
            <div
              className="flex flex-col items-center justify-center min-h-[128px] season-pass py-3 px-1"
              onDragEnter={
                dragging && !seasons[grp2].items.length
                  ? (e) => handleDragEnter(e, { grp: grp2, index: 0 })
                  : null
              }
            >
              {seasons[grp2].items.map((val, index) => {
                return (
                  <div
                    className={`levels-season  w-full text-center${
                      dragging ? getStyles({ grp: grp2, index }) : ""
                    }`}
                    key={index}
                    draggable
                    onDragStart={(e) => {
                      handleDragStart(e, { grp: grp2, index });
                    }}
                    onDragEnter={
                      dragging
                        ? (e) => {
                            handleDragEnter(e, { grp: grp2, index });
                          }
                        : null
                    }
                  >
                    <div className="relative text-center">
                      {" "}
                      <img
                        src={require("../../../images/season.png")}
                        className="absolute mt-2 left-3"
                      />
                      <p className="pb-1">Season {val}</p>
                    </div>
                  </div>
                );
              })}
              {seasons[grp2].items.length == 0 && (
                <>
                  <span className=" text-dark-gray-300">
                    Drag here if had Elite
                  </span>
                  <p className=" text-dark-gray-300">season pass</p>
                </>
              )}
            </div>
          </div>
          <div>
            <p className="text-yellow-500 ">Elite Plus</p>
            <div
              className="flex flex-col items-center justify-center min-h-[128px] season-pass py-3 px-1"
              onDragEnter={
                dragging && !seasons[grp3].items.length
                  ? (e) => handleDragEnter(e, { grp: grp3, index: 0 })
                  : null
              }
            >
              {seasons[grp3].items.map((val, index) => {
                return (
                  <div
                    className={`levels-season  w-full text-center${
                      dragging ? getStyles({ grp: grp3, index }) : ""
                    }`}
                    key={index}
                    draggable
                    onDragStart={(e) => {
                      handleDragStart(e, { grp: grp3, index });
                    }}
                    onDragEnter={
                      dragging
                        ? (e) => {
                            handleDragEnter(e, { grp: grp3, index });
                          }
                        : null
                    }
                  >
                    <div className="relative text-center">
                      {" "}
                      <img
                        src={require("../../../images/season.png")}
                        className="absolute mt-2 left-3"
                      />
                      <p className="pb-1">Season {val}</p>
                    </div>
                  </div>
                );
              })}
              {seasons[grp3].items.length == 0 && (
                <>
                  <span className=" text-dark-gray-300">
                    Drag here if had Elite
                  </span>
                  <p className=" text-dark-gray-300">Plus season pass</p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryDetails;
