import React from "react";
import { Field, ErrorMessage } from "formik";

const SelectField = (props) => {
  const { label, name, options, ...rest } = props;
  return (
    <div>
      <label htmlFor={name}>{label}</label>
      <div className="flex flex-row input-field rounded-[2px]">
        <Field
          as="select"
          id={name}
          name={name}
          {...rest}
          className={`w-full bg-dark-gray-200 h-6 text-[#F7F8F9]`}
        >
          {options.map((option) => {
            return (
              <option
                key={option.value}
                value={option.value}
                className=" bg-dark-gray-200 text-no-new-message"
              >
                {option.key}
              </option>
            );
          })}
        </Field>
      </div>
    </div>
  );
};

export default SelectField;
