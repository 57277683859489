import client from "./client";

const getBids = (chatId, gameId) => client.get(`/bid/${chatId}/${gameId}`);

const proposeBid = (data) => client.post("/bid", data);

const acceptBid = ({ chatId, bidId, gameId }) =>
  client.put(`/bid/accept/${chatId}/${gameId}`, { bidId });

export default {
  getBids,
  proposeBid,
  acceptBid,
};
