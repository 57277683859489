import React from "react";
import { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { HiSortAscending } from "react-icons/hi";

const SearchInput = ({ className, query, setQuery }) => {
  const [sort, setSort] = useState(false);
  const [price, setPrice] = useState(50000);
  return (
    <div className="relative ">
      <div
        className={`flex justify-around items-center px-3  bg-opacity-30 rounded ${
          className ? className : "bg-white"
        }`}
      >
        <AiOutlineSearch size={20} color="white" />
        <input
          type="text"
          className="px-2 text-white bg-transparent outline-none w-[80%] sm:w-56 lg:w-auto font-body"
          placeholder="Search for games"
          onChange={(e) => setQuery(e.target.value)}
        />
        <div
          className="flex items-end justify-center space-x-2 cursor-pointer hover:brightness-75"
          onClick={() => setSort(!sort)}
        >
          <HiSortAscending size={20} color="white" />
          <span className="text-white">Sort</span>
        </div>
      </div>
      {sort && (
        <div className="absolute z-50 mt-1 bg-black rounded-xl right-2 ">
          <div className="p-3 glassmorphism sm:flex gap-x-7 ">
            <div>
              <p>Price</p>
              <input
                type="range"
                name="price"
                min="0"
                max="100000"
                step="10000"
                value={price}
                list
                onChange={(e) => setPrice(e.target.value)}
              />
              <div className="flex justify-between text-sm ">
                <p>0</p>
                <p>100000</p>
              </div>
            </div>
            <div className="flex-col">
              <p className="text-xl font-bold text-yellow-500">₹ 0 - {price}</p>
              <div className="flex p-2 mt-2 rounded-md gap-x-1 terms-button-div">
                <button
                  type="button"
                  className="w-full px-2 py-1 rounded bg-success"
                  onClick={() => {
                    setQuery(price);
                    setSort(false);
                  }}
                >
                  apply
                </button>
                <button
                  className="w-full px-2 py-1 rounded bg-error-500"
                  onClick={() => setSort(false)}
                >
                  close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchInput;
