import React from "react";
import { MdCheckCircleOutline } from "react-icons/md";

const ChangeID = ({ currentStep, setCurrentStep }) => {
  return (
    <div>
      <h3 className="hidden text-2xl font-semibold text-center sm:block">
        Change ID & Password
      </h3>
      <div className="flex justify-center mt-2 rounded bg-dark-700 h-[354px] sm:h-80">
        <div className="my-auto text-center">
          <MdCheckCircleOutline className="w-24 h-24 mx-auto text-green-light" />
          <p>ID & password changed successfully.</p>
          <button
            className="px-8 py-2 mt-4 bg-yellow-500 rounded"
            onClick={() => {
              setCurrentStep(currentStep + 1);
            }}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangeID;
