import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import TextInput from "../../../utilities/TextInput";
import accountApi from "../../../api/account";
import useApi from "../../../hooks/useApi";

const SocialAccounts = ({ formik }) => {
  const [twtCredentials, setTwtCredentials] = useState("");
  const [fbCredentials, setFbCredentials] = useState("");
  const [isTwtVerified, setIsTwtVerified] = useState(false);
  const [isFbVerified, setIsFbVerified] = useState(false);

  const {
    data: fbData,
    error: fbError,
    loading: fbLoading,
    request: verifyFb,
  } = useApi(accountApi.verifyFb);

  const {
    data: twtData,
    error: twtError,
    loading: twtLoading,
    request: verifyTwitter,
  } = useApi(accountApi.verifyTwitter);

  useEffect(() => {
    setFbCredentials(formik?.values.facebook);
  }, [formik?.values.facebook]);

  useEffect(() => {
    setTwtCredentials(formik?.values.twitter);
  }, [formik?.values.twitter]);

  useEffect(() => {
    if (fbData && fbData[0]) setIsFbVerified(true);
  }, [fbData]);

  useEffect(() => {
    if (twtData && twtData[0]) setIsTwtVerified(true);
  }, [twtData]);

  const handleVerifyFb = () => {
    console.log(fbCredentials);
    const { username, password } = fbCredentials;
    if (!username || !password)
      return alert("Please enter username and password.");
    verifyFb(fbCredentials);
  };

  const handleVerifyTwt = () => {
    console.log(twtCredentials);
    const { username, password } = twtCredentials;
    if (!username || !password)
      return alert("Please enter username and password.");
    verifyTwitter(twtCredentials);
  };

  return (
    <div className="overflow-y-scroll h-80 scrollbar xs:h-96">
      <div className="px-4 py-3 mb-3 social-div">
        <p className="text-[18px] font-medium leading-5 text-white">Facebook</p>
        <div className="grid mt-2 sm:grid-cols-2 gap-x-3 xs:gap-x-4 sm:gap-x-7">
          <TextInput
            label="Username"
            name="facebook.username"
            placeholder="Enter username"
            className="mb-2"
          />
          <TextInput
            label="Password"
            name="facebook.password"
            placeholder="Enter password"
            className="mb-2"
            type="password"
          />
        </div>
        <button
          className={`w-full h-12 rounded-lg hover:bg-white hover:scale-100 hover:text-light-grey ${
            isFbVerified ? "bg-green-500" : "bg-[#454545]"
          } rounded-lg`}
          onClick={handleVerifyFb}
          type="button"
          disabled={isFbVerified}
        >
          {fbLoading
            ? "Verifying"
            : isFbVerified
            ? "Verified"
            : "Verify facebook"}
        </button>
      </div>
      <div className="px-4 py-3 social-div">
        <p className="text-[18px] font-medium leading-5 text-white">Twitter</p>
        <div className="grid mt-2 sm:grid-cols-2 gap-x-3 xs:gap-x-4 sm:gap-x-7">
          <TextInput
            label="Username"
            name="twitter.username"
            placeholder="Enter username"
            className="mb-2"
          />
          <TextInput
            label="Password"
            name="twitter.password"
            placeholder="Enter password"
            className="mb-2"
            type="password"
          />
        </div>
        <button
          className={`w-full hover:bg-white rounded-lg hover:scale-100 hover:text-light-grey h-12 ${
            isTwtVerified ? "bg-green-500" : "bg-[#454545]"
          } rounded-lg`}
          onClick={handleVerifyTwt}
          type="button"
          disabled={isTwtVerified}
        >
          {twtLoading
            ? "Verifying"
            : isTwtVerified
            ? "Verified"
            : "Verify twitter"}
        </button>
      </div>
    </div>
  );
};

export default SocialAccounts;
