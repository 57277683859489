import React from "react";
import {
  DropdownWrapper,
  StyledSelect,
  StyledOption,
  StyledLabel,
} from "./styles.js";

export function DropMenu(props) {
  return (
    <DropdownWrapper
      className="h-8 w-[65%] mb-10"
      action={props.action}
      {...props}
    >
      <StyledLabel htmlFor="services" className="label-text-gradient">
        {props.formLabel}
      </StyledLabel>
      <StyledSelect
        id="services"
        name="services"
        className="bg-white bg-opacity-20 rounded outline-none text-gray-300 px-2 py-1 "
      >
        {props.children}
      </StyledSelect>
    </DropdownWrapper>
  );
}

export function Option(props) {
  return (
    <StyledOption selected={props.selected} className="bg-black bg-opacity-20 ">
      {props.value}
    </StyledOption>
  );
}
