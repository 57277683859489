import React from "react";
import { useField, ErrorMessage } from "formik";

const TextField = ({ label, className, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="flex flex-col h-[74px]">
      {label && (
        <div className="leading-5 text-[16px] mb-[2px] font-normal">
          <label
            className={`${className ? className : " text-no-new-message"}`}
            htmlFor={field.name}
          >
            {label}
          </label>
        </div>
      )}
      <div className="w-full ">
        <input
          className={`input-field w-[100%] h-[52px] ${
            meta.error && "is-invalid"
          }`}
          {...field}
          {...props}
        />
        <ErrorMessage
          component="div"
          name={field.name}
          className="mb-2 -mt-5 relative  ml-3 max-w-[290px] text-red-600 error-message"
        />
      </div>
    </div>
  );
};

export default TextField;
