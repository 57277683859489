import React, { useEffect } from "react";

import { displayRazorpay } from "../../utilities/displayRazorpay";
import useApi from "../../hooks/useApi";
import paymentApi from "../../api/payment";
import { useContext } from "react";
import AuthContext from "../../utilities/context";

const DepositeFunds = ({
  currentStep,
  setCurrentStep,
  setLoading,
  data,
  sendBuyerDepositStatus,
}) => {
  const {
    data: orderData,
    error,
    request: initiatePayment,
  } = useApi(paymentApi.initiatePayment);

  // console.log(data);

  const { userData } = useContext(AuthContext);

  useEffect(() => {
    initiatePayment({
      amount: parseInt(data.price ? data.price : data.amount),
      sellerId: data._doc?.sellerId ? data._doc.sellerId : data.sellerId,
      gameAccountId: data._doc?.gameAccountId
        ? data._doc?.gameAccountId
        : data.gameAccountId,
    });
  }, []);

  useEffect(() => {
    console.log(orderData);
  }, [orderData]);

  return (
    <div className="font-semibold ">
      <h3 className="hidden text-2xl text-center sm:block">Deposit funds</h3>
      <div className="flex justify-center px-1 mt-2 rounded bg-dark-700 md:h-[34vh] lg:h-80 h-[354px] sm:h-80">
        <div className="my-auto text-center text-silver-white">
          <p className="text-base sm:text-lg">
            Deposit total purchasing amount of{" "}
          </p>
          <p className="text-base sm:text-lg">
            the account (Rs.{data.price ? data.price : data.amount}){" "}
          </p>
          <button
            className="px-10 py-2 mt-3 bg-yellow-500 rounded"
            onClick={() => {
              // setCurrentStep(currentStep + 1);
              // setLoading(true);
              displayRazorpay({
                userData,
                orderData,
                currentStep,
                setCurrentStep,
                setLoading,
                sendBuyerDepositStatus,
              });
            }}
          >
            Deposit
          </button>
          <p className="mt-3 text-sm">
            ** This amount will be hold by us until the account is{" "}
          </p>
          <p className="text-sm "> completely transfered to you </p>
        </div>
      </div>
    </div>
  );
};

export default DepositeFunds;
