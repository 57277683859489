import React from "react";
import { MdOutlineRemoveRedEye, MdArrowBack } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SimpleBar from "simplebar-react";

import useApi from "../../hooks/useApi";
import Loader from "./Loader";
import accountApi from "../../api/account";
import { useEffect } from "react";
import { useState } from "react";

const OwnedAccountDetails = ({ id, isSeller }) => {
  const [account, setAccount] = useState();

  const navigate = useNavigate();
  const { state } = useLocation();

  const {
    loading,
    data,
    request: getAccount,
  } = useApi(accountApi.getOneAccount);

  useEffect(() => {
    if (state) setAccount(state);
    else if (!state) getAccount(id);
  }, []);

  useEffect(() => {
    setAccount(data);
  }, [data]);

  useEffect(() => {
    setAccount(state);
  }, [state]);

  if (!account || loading) return <Loader />;

  return (
    <div>
      <div className="mx-8 font-semibold text-white sm:mx-14 lg:mx-20 mt-7 mb-28">
        <div className="flex justify-between ">
          <button
            type="button"
            className="px-3 py-2 mt-1 text-xl rounded-lg bg-neutral-700"
            onClick={() => {
              navigate(-1);
            }}
          >
            <MdArrowBack />
          </button>
          {/* <Link to={`/sellerdashboard/editaccount/${account._id}`}> */}
          <button
            className="px-10 py-2 bg-yellow-500 rounded xs:py-2 sm:py-3"
            type="button"
            onClick={() =>
              navigate(`/sellerdashboard/editaccount/${account._id}`, {
                state: account,
              })
            }
          >
            Edit details
          </button>
          {/* </Link> */}
        </div>
        <div className="gap-8 mt-3 overflow-y-scroll scrollbar-hide md:flex h-80 xs:h-fit">
          <div className="md:w-1/3">
            <img className="w-full" src={account.accountThumbnail} />
          </div>

          <div className="md:w-2/3 ">
            <div className="flex justify-between text-2xl ">
              <div className="flex gap-x-2">
                <p>{account.game}</p>
                <p className="flex text-xl text-dark-100">
                  21{" "}
                  <span className="mt-1 text-2xl">
                    <MdOutlineRemoveRedEye className="my-auto " />
                  </span>
                </p>
              </div>
              <div>
                <p>₹{account.price}</p>
              </div>
            </div>

            <SimpleBar
              className="h-[75%] mt-3 pr-2 overflow-y-scroll text-base scrollbar"
              autoHide={false}
            >
              <div>
                <h3 className="text-lg">Account details</h3>
                <div className="grid mt-3 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-x-20 gap-y-3">
                  <div className="grid grid-cols-2 gap-x-7">
                    <p className="font-normal text-neutral-500">Player ID </p>
                    <p className="overflow-x-scroll scrollbar">
                      {account.playerID}
                    </p>
                  </div>
                  <div className="grid grid-cols-2 gap-x-7">
                    <p className="font-normal text-neutral-500">Ingame name </p>
                    <p className="overflow-x-scroll scrollbar">
                      {account.inGameName}
                    </p>
                  </div>
                  <div className="grid grid-cols-2 gap-x-7">
                    <p className="font-normal text-neutral-500">
                      Tenure of ownership{" "}
                    </p>
                    <p>
                      {account.ownershipTenure.years} years{" "}
                      {account.ownershipTenure.months} months
                    </p>
                  </div>
                  <div className="grid grid-cols-2 gap-x-7">
                    <p className="font-normal text-neutral-500">
                      Account level
                    </p>
                    <p>{account.accountLevel} level</p>
                  </div>
                  <div className="grid grid-cols-2 gap-x-7">
                    <p className="font-normal text-neutral-500">
                      Memorable frames
                    </p>
                    <div>
                      {account.memorableFrames.map((frame, index) => (
                        <>
                          <div>
                            {frame}
                            {index + 1 !== account.memorableFrames.length && (
                              <>,</>
                            )}{" "}
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-x-7">
                    <p className="font-normal text-neutral-500">
                      Memorable titles
                    </p>
                    <div>
                      {account.memorableTitles.map((title, index) => (
                        <>
                          <div>
                            {title}
                            {index + 1 !== account.memorableTitles.length && (
                              <>,</>
                            )}{" "}
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <div className="grid grid-cols-2 sm:flex sm:flex-row md:grid lg:flex gap-x-8">
                    <p className="font-normal text-neutral-500">
                      Inventory details{" "}
                    </p>
                    <p>{account.inventoryDetails}</p>
                  </div>
                  <div className="grid grid-cols-2 mt-3 sm:flex sm:flex-row md:grid lg:flex gap-x-8">
                    <p className="font-normal text-neutral-500">
                      Special mentions
                    </p>
                    <p>{account.specialMention}</p>
                  </div>
                </div>
              </div>

              <div className="mt-3">
                <h3 className="mb-3 text-lg">Royal pass details</h3>
                {account.royalPassDetails.map((pass) => {
                  if (pass.title === "Levels") return;
                  return (
                    <div className="mb-1 sm:flex md:block lg:flex sm:flex-row">
                      <div className="sm:w-1/4 md:w-full lg:w-1/4 ">
                        <p className="font-normal text-neutral-500">
                          {pass.title}
                        </p>
                      </div>
                      <div className="flex min-h-[28px] flex-row overflow-x-scroll sm:w-3/4 gap-x-3 scrollbar-hide">
                        {pass.items.map((season, index) => {
                          return (
                            <div className="w-48 rounded-full levels-season px-14">
                              <div className="relative text-center">
                                {" "}
                                <img
                                  src={require("../../images/season.png")}
                                  className="absolute mt-2 -left-5"
                                />
                                <p className="w-20 pb-1 ">Season {season}</p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="mt-3">
                <h3 className="mb-3 text-lg">Social linked</h3>
                <div className="overflow-x-scroll scrollbar">
                  <div className="flex flex-row md:block lg:flex gap-x-5">
                    <div className="w-1/4 ">
                      <p className="font-normal text-neutral-500">Facebook</p>
                    </div>
                    <div className="flex grid-cols-2 sm:grid sm:w-3/4 md:block md:w-full lg:w-3/4 lg:grid gap-x-3">
                      <div className="flex font-normal text-neutral-500">
                        <p className="w-24 ">Username - </p>
                        <p className="ml-2 font-semibold text-white">
                          {account.facebook.username}
                        </p>
                      </div>
                      <div className="flex ml-3 font-normal md:ml-0 text-neutral-500">
                        <p className="w-24 ">Password - </p>
                        <p className="ml-2 font-semibold text-white">
                          {account.facebook.password}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-row mt-3 lg:flex md:block gap-x-5">
                    <div className="w-1/4 md:w-full lg:w-1/4 ">
                      <p className="font-normal text-neutral-500">Twitter</p>
                    </div>
                    <div className="flex grid-cols-2 sm:grid sm:w-3/4 md:block md:w-full lg:w-3/4 lg:grid gap-x-3">
                      <div className="flex font-normal text-neutral-500">
                        <p className="w-24 ">Twitter ID - </p>
                        <p className="ml-2 font-semibold text-white">
                          {account.twitter.username}
                        </p>
                      </div>
                      <div className="flex ml-3 font-normal md:ml-0 text-neutral-500">
                        <p className="w-24 ">Password - </p>
                        <p className="ml-2 font-semibold text-white">
                          {account.twitter.password}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SimpleBar>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OwnedAccountDetails;
