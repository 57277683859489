import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

const SellerStep = ({ loading, setLoading, currentStep, setCurrentStep }) => {
  setTimeout(() => {
    setLoading(false);
    setCurrentStep(currentStep + 1);
  }, 2000);
  return (
    <div className="font-semibold ">
      <h3 className="hidden text-2xl text-center sm:block">Seller steps</h3>
      <div className="flex justify-center mt-2 rounded md:h-[33vh] lg:h-80 bg-dark-700 h-[354px]">
        <div className="my-auto text-center">
          <ClipLoader color="white" size={100} loading={loading} />
          <p>Fetching ID and Password .....</p>
        </div>
      </div>
    </div>
  );
};

export default SellerStep;
