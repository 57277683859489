import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "simplebar/dist/simplebar.min.css";

import Accounts from "./screens/seller/Accounts";
import Announcements from "./screens/Announcements";
import Schedule from "./screens/components/Schedule";
import Welcome from "./screens/Welcome";
import Chat from "./screens/Buyer/Chat";
import useToken from "./hooks/useToken";
import AuthContext from "./utilities/context";
import useApi from "./hooks/useApi";
import authApi from "./api/auth";
import Login from "./screens/Login";
import CreateAccount from "./screens/CreateAccount";
import OtpVerification from "./screens/OtpVerification";
import ListAccount from "./screens/seller/ListAccount";
import Dashboard from "./screens/components/Dashboard";
import XBLogin from "./Transaction/Buyer/XBLogin";
import XSLogin from "./Transaction/Seller/XSLogin";
import SXRoom from "./Transaction/Seller/SXRoom";
import BXRoom from "./Transaction/Buyer/BXRoom";
import Explore from "./screens/Buyer/Explore";

import { ProtectedRoutes } from "./components/ProtectedRoutes";
import SelectProfile from "./screens/SelectProfile";
import ScheduleBuy from "./screens/components/ScheduleBuy";
import OwnedAccountDetails from "./screens/components/OwnedAccountDetails";
import AccountDetails from "./screens/Buyer/AccountDetails";
import ScheduleAccountBuy from "./screens/seller/ScheduleAccountBuy";
import Home from "./screens/Home";
import Loader from "./screens/components/Loader";
import { ProtectedRoutes2 } from "./components/ProtectedRoutes2";
import GuestProfile from "./screens/Guest/GuestProfile";
import GuestDetails from "./screens/Guest/GuestDetails";
import BuyerPage from "./screens/Guest/BuyerPage";
import AgentSignup from "./screens/Agent/AgentSignup";
import AgentDashboard from "./screens/Agent/AgentDashboard";
import { ProtectedRoutes3 } from "./components/ProtectedRoutes3";
import AgentLogin from "./screens/Agent/AgentLogin";
import ResetPassword from "./screens/ResetPassword";

AOS.init();

function App() {
  const [userData, setUserData] = useState();
  const [isSeller, setIsSeller] = useState(
    localStorage.getItem("isSeller")
      ? localStorage.getItem("isSeller") === "true"
        ? true
        : false
      : null
  );

  const { token, setToken } = useToken();

  const { data, request: getUser } = useApi(authApi.getUser);

  useEffect(() => {
    if (token) {
      getUser();
    }
  }, [token]);

  useEffect(() => {
    setUserData(data);
  }, [data]);

  // if (!userData && token) return <Loader />;
  const Details = () => {
    const { id } = useParams();
    return <AccountDetails id={id} />;
  };
  const BuyerSchedule = () => {
    const { id } = useParams();
    return <ScheduleBuy id={id} />;
  };
  const OwnedAccount = ({ isSeller }) => {
    const { id } = useParams();
    return <OwnedAccountDetails id={id} isSeller={isSeller} />;
  };
  const EditAccount = ({ isSeller }) => {
    const { id } = useParams();
    return <ListAccount isSeller={isSeller} />;
  };
  const ViewAccount = () => {
    const { id } = useParams();
    return <ScheduleAccountBuy isSeller={isSeller} id={id} />;
  };
  const ScheduleAccountDetails = () => {
    const { id } = useParams();
    return <ScheduleBuy id={id} isSeller={isSeller} />;
  };

  return (
    <AuthContext.Provider
      value={{
        userData,
        setUserData,
        isSeller,
        setIsSeller,
      }}
    >
      {/* <div className="App"> */}
      <Router>
        <Routes>
          {!token && (
            <>
              <Route
                path="/"
                element={<Home setToken={setToken} setUserData={setUserData} />}
              />
              <Route
                path="/login"
                element={
                  <Login setToken={setToken} setUserData={setUserData} />
                }
              />
              <Route path="/signup" element={<CreateAccount />} />
              <Route path="/guest" element={<GuestProfile />} />
              <Route path="/guestdetails" element={<GuestDetails />} />
              <Route path="/buyerpage" element={<BuyerPage />} />
              <Route path="/verifyotp" element={<OtpVerification />} />

              <Route path="/agentlogin" element={<AgentLogin />} />
              <Route path="/agentsignup" element={<AgentSignup />} />
              <Route path="/reset-password" element={<ResetPassword />} />
            </>
          )}

          {isSeller === null && (
            <Route element={<ProtectedRoutes3 />}>
              <Route path="/agentdashboard" element={<AgentDashboard />} />
              <Route
                path="/"
                element={<Navigate to="/agentdashboard" replace />}
              />
            </Route>
          )}
          <Route element={<ProtectedRoutes2 />}>
            <Route path="/xblogin" element={<XBLogin />} />
            <Route path="/xslogin" element={<XSLogin />} />
            <Route path="/sxroom" element={<SXRoom />} />
            <Route path="/sxroom/:id" element={<SXRoom />} />
            <Route path="/bxroom" element={<BXRoom />} />
            <Route path="/bxroom/:id" element={<BXRoom />} />
            <Route path="/selectProfile" element={<SelectProfile />} />
          </Route>
          <Route element={<ProtectedRoutes setIsSeller={setIsSeller} />}>
            {isSeller !== null && (
              <Route
                path="/"
                element={
                  isSeller ? (
                    <Dashboard isSeller={isSeller} />
                  ) : (
                    <Navigate to="/explore" replace />
                  )
                }
                // element={isSeller ? <Navigate to="/sellerdashboard" replace /> : }
              />
            )}
            <Route
              path="/verify"
              element={
                <OtpVerification
                  setToken={setToken}
                  setUserData={setUserData}
                />
              }
            />
            <Route path="/accounts" element={<Accounts />} />
            {isSeller ? null : <Route path="/explore" element={<Explore />} />}
            <Route
              path="/schedule"
              element={<Schedule isSeller={isSeller} />}
            />
            <Route path="/chats" element={<Chat />} />
            <Route path="/announcements" element={<Announcements />} />

            <Route
              path="/listaccount"
              element={<ListAccount isSeller={isSeller} />}
            />
            <Route
              path={isSeller ? "/sellerdashboard" : "/profile"}
              element={<Dashboard isSeller={isSeller} />}
            />
            <Route
              path="/seller/schedule"
              element={<Schedule isSeller={isSeller} />}
            />
            <Route
              path="/seller/schedule/:id"
              element={<ViewAccount isSeller={isSeller} />}
            />
            <Route
              path="/seller/schedule/schedulebuy/:id"
              element={<ScheduleAccountDetails isSeller={isSeller} />}
            />
            <Route
              path="/buyer/schedule"
              element={<Schedule isSeller={false} />}
            />
            <Route path="/buyer/schedule/:id" element={<BuyerSchedule />} />
            <Route
              path="/sellerdashboard"
              element={<Dashboard isSeller={isSeller} />}
            />
            <Route
              path="/sellerdashboard/account/:id"
              element={<OwnedAccount isSeller={isSeller} />}
            />
            <Route
              path="/schedule/account/:id"
              element={<ViewAccount isSeller={isSeller} />}
            />
            <Route
              path="/sellerdashboard/editaccount/:id"
              element={<EditAccount isSeller={isSeller} />}
            />

            <Route path="/profile" element={<Dashboard isSeller={false} />} />
            <Route
              path="/profile/account/:id"
              element={<OwnedAccount isSeller={false} />}
            />
            <Route path="/explore/:id" element={<Details />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Route>
        </Routes>
      </Router>
      {/* </div> */}
    </AuthContext.Provider>
  );
}

export default App;
