import React, { useEffect } from "react";
import Loader from "../components/Loader";
import useApi from "../../hooks/useApi";

import userDetailsApi from "../../api/userDetails";

//Inbox list
const ChatName = ({ name, onClick, online }) => {
  if (name == undefined) {
    return <></>;
  }
  return (
    <div className="mb-2">
      <div className="inbox hover:cursor-pointer" onClick={onClick}>
        <div className="flex items-center">
          <div className="avatar flex justify-center items-center font-semibold text-xl w-10 h-10 bg-[#ffa800] rounded-full">
            {name[0]}
          </div>
          <div className="ml-3">
            <div className="text-white">
              <p>{name}</p>
            </div>
            <div className="text-no-new-message">{online ? "Online" : ""}</div>
          </div>
        </div>
        <div className="chat-date">{new Date().toDateString()}</div>
      </div>
    </div>
  );
};

const Conversation = ({
  data,
  setCurrentChat,
  currentUserId,
  online,
  setShowChat,
}) => {
  const userId = data.members.find((id) => id !== currentUserId);
  const { data: secondUser, request: getSecondUser } = useApi(
    userDetailsApi.getUserDetails
  );

  useEffect(() => {
    getSecondUser(userId);
  }, []);
  if (!secondUser)
    return (
      <div className="px-2">
        <Loader className="h-[60vh]" />
      </div>
    );

  return (
    <ChatName
      onClick={() => {
        setCurrentChat(data);
        setShowChat(true);
      }}
      key={data._id}
      name={secondUser?.name}
      online={online}
    />
  );
};

export default Conversation;
