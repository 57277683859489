import client from "./client";

const getUserDetails = (userId) => client.get(`/userDetails/${userId}`);

const getSellerDetails = (sellerId) =>
  client.get(`/userDetails/seller/${sellerId}`);

export default {
  getUserDetails,
  getSellerDetails,
};
