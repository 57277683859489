import React, { useState, useEffect } from "react";
import { Form, Formik, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import { baseURL } from "../../shared/baseURL";
import SelectField from "../../utilities/SelectField";
import { useNavigate } from "react-router-dom";
import accountApi from "../../api/account";
import useApi from "../../hooks/useApi";
const GuestDetails = ({ room, sendSellerData }) => {
  // const [playerId, setPlayerId] = useState();
  const [playerName, setPlayerName] = useState();
  // const [twtCredentials, setTwtCredentials] = useState("");
  // const [fbCredentials, setFbCredentials] = useState("");
  const [isTwtVerified, setIsTwtVerified] = useState(false);
  const [isFbVerified, setIsFbVerified] = useState(false);

  const {
    data,
    loading,
    error: playerNameError,
    request,
  } = useApi(accountApi.verifyId);

  const {
    data: fbData,
    error: fbError,
    loading: fbLoading,
    request: verifyFb,
  } = useApi(accountApi.verifyFb);

  const {
    data: twtData,
    error: twtError,
    loading: twtLoading,
    request: verifyTwitter,
  } = useApi(accountApi.verifyTwitter);

  // useEffect(() => {
  //   setFbCredentials(formik?.values.fb);
  // }, [formik?.values.fb]);

  // useEffect(() => {
  //   setTwtCredentials(formik?.values.tw);
  // }, [formik?.values.tw]);

  useEffect(() => {
    if (fbData && fbData[0]) setIsFbVerified(true);
  }, [fbData]);

  useEffect(() => {
    if (twtData && twtData[0]) setIsTwtVerified(true);
  }, [twtData]);
  // useEffect(() => {
  //   setPlayerId(formik.values.playerID);
  // }, [formik.values.playerID]);

  const verifyPlayerIdHandler = ({ playerId }) => {
    console.log(playerId);
    request({ playerId });
    console.log(data);
    if (loading) setPlayerName("loading...");
    if (!loading && !playerNameError) setPlayerName(data);
    else alert(playerNameError);
  };

  const handleVerifyFb = ({ fbCredentials }) => {
    console.log(fbCredentials);
    const { username, password } = fbCredentials;
    if (!username || !password)
      return alert("Please enter username and password.");
    verifyFb(fbCredentials);
  };

  const handleVerifyTwt = ({ twtCredentials }) => {
    console.log(twtCredentials);
    const { username, password } = twtCredentials;
    if (!username || !password)
      return alert("Please enter username and password.");
    verifyTwitter(twtCredentials);
  };

  const dropDownOptions = [
    { key: "Select a game", value: "" },
    { key: "Battle Grounds Mobile India", value: "BGMI" },
    { key: "PUBG", value: "PUBG" },
    // { key: "Free Fire", value: "FF" },
    // { key: "Valorant", value: "Valorant" },
  ];

  const navigate = useNavigate();
  const validate = Yup.object().shape({
    game: Yup.string().required("please select a game"),
    playerId: Yup.number().required("please enter player ID"),
    fb: Yup.object().shape({
      username: Yup.string().required("please enter fb username"),
      password: Yup.string()
        .required("please enter fb password")
        .min(6, "password must be greater than 6 characters"),
    }),
    tw: Yup.object().shape({
      username: Yup.string().required("please enter twitter username"),
      password: Yup.string()
        .required("please enter twitter password")
        .min(6, "password must be greater than 6 characters"),
    }),
    accountNumber: Yup.number()
      .required("please enter account number")
      .min(9999999, "account number must be greater than 8 digits"),
    confirmAccountNumber: Yup.number().oneOf(
      [Yup.ref("accountNumber"), null],
      "account number does not match"
    ),
    IFSCNumber: Yup.string().required("please enter IFSC code"),
    name: Yup.string().required("please enter beneficiary name"),
  });
  // const handleKeyDown = (e, formik) => {
  //   const { key } = e;
  //   const name = e.target.name;
  //   if ((key >= 0 && key <= 9) || key === "Enter" || key === "Backspace") {
  //     console.log(name, " :", e.target.value);
  //     formik.handleChange(e);
  //     if (key === "Enter") {
  //       const form = e.target.form;
  //       const index = Array.prototype.indexOf.call(form, e.target);
  //       form.elements[index + 1].focus();
  //       e.preventDefault();
  //     }
  //   } else {
  //     e.preventDefault();
  //   }
  // };
  const __DEV__ = process.env.NODE_ENV === "development";

  const handlePost = async (formik) => {
    if (formik.values.amount === "") {
      return alert("please fill the amount for account sale");
    }
    if (formik.values.game === "") {
      return alert(" please fill the details");
    }
    let clientError = Object.keys(formik.errors);
    if (clientError.length !== 0) {
      return alert(
        "please fill details correctly, " +
          "errors in :   " +
          JSON.stringify(clientError)
      );
    }
    const values = formik.values;
    // console.log("post call", values);
    const response = await fetch(baseURL + "/xroom/seller", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ values }),
    });
    if (response.status !== 200) {
      alert("something went wrong !");
    }
    const xroom = await response.json();
    // console.log(xroom);
    if (xroom && response.status === 200) {
      const link = __DEV__
        ? `http://localhost:3000/bxroom/${xroom._id}`
        : `https://pc-website.onrender.com/bxroom/${xroom._id}`;
      navigate(`/sxroom/${xroom._id}`, {
        state: { room: xroom, guest: true, link: link },
      });
    }
  };
  const handleContinue = async (formik) => {
    if (formik.values.game === "" || formik.values.currency === "") {
      return alert(" please fill the details");
    }
    let clientError = Object.keys(formik.errors);
    if (clientError.length !== 0) {
      return alert(
        "please fill details correctly, " +
          "errors in :   " +
          JSON.stringify(clientError)
      );
    }
    const values = formik.values;
    const response = await fetch(baseURL + `/xroom/${room._id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ values }),
    });
    const xroom = await response.json();
    room.seller = xroom.seller;
    // console.log("seller info :", xroom);
    sendSellerData({ xroom });
  };

  const dropDownCurrency = [
    { key: "Select Currency", value: "" },
    { key: "AFN - Afghan Afghani - ؋", value: "AFN" },
    { key: "ALL - Albanian Lek - Lek", value: "ALL" },
    { key: "DZD - Algerian Dinar - دج", value: "DZD" },
    { key: "AOA - Angolan Kwanza - Kz", value: "AOA" },
    { key: "ARS - Argentine Peso - $", value: "ARS" },
    { key: "AMD - Armenian Dram - ֏", value: "AMD" },
    { key: "AWG - Aruban Florin - ƒ", value: "AWG" },
    { key: "AUD - Australian Dollar - $", value: "AUD" },
    { key: "AZN - Azerbaijani Manat - m", value: "AZN" },
    { key: "BSD - Bahamian Dollar - B$", value: "BSD" },
    { key: "BHD - Bahraini Dinar - .د.ب", value: "BHD" },
    { key: "BDT - Bangladeshi Taka - ৳", value: "BDT" },
    { key: "BBD - Barbadian Dollar - Bds$", value: "BBD" },
    { key: "BYR - Belarusian Ruble - Br", value: "BYR" },
    { key: "BEF - Belgian Franc - fr", value: "BEF" },
    { key: "BZD - Belize Dollar - $", value: "BZD" },
    { key: "BMD - Bermudan Dollar - $", value: "BMD" },
    { key: "BTN - Bhutanese Ngultrum - Nu.", value: "BTN" },
    { key: "GBP - British Pound Sterling - £", value: "GBP" },
    { key: "KYD - Cayman Islands Dollar - $", value: "KYD" },
    { key: "CNY - Chinese Yuan - ¥", value: "CNY" },
    { key: "CZK - Czech Republic Koruna - Kč", value: "CZK" },
    { key: "XCD - East Caribbean Dollar - $", value: "XCD" },
    { key: "EUR - Euro - €", value: "EUR" },
    { key: "GEL - Georgian Lari - ლ", value: "GEL" },
    { key: "HKD - Hong Kong Dollar - $", value: "HKD" },
    { key: "INR - Indian Rupee - ₹", value: "INR" },
    { key: "IDR - Indonesian Rupiah - Rp", value: "IDR" },
    { key: "IRR - Iranian Rial - ﷼", value: "IRR" },
    { key: "IQD - Iraqi Dinar - د.ع", value: "IQD" },
    { key: "ILS - Israeli New Sheqel - ₪", value: "ILS" },
    { key: "JPY - Japanese Yen - ¥", value: "JPY" },
    { key: "JOD - Jordanian Dinar - ا.د", value: "JOD" },
    { key: "KZT - Kazakhstani Tenge - лв", value: "KZT" },
    { key: "KWD - Kuwaiti Dinar - ك.د", value: "KWD" },
    { key: "KGS - Kyrgystani Som - лв", value: "KGS" },
    { key: "LAK - Laotian Kip - ₭", value: "LAK" },
    { key: "LBP - Lebanese Pound - £", value: "LBP" },
    { key: "MOP - Macanese Pataca - $", value: "MOP" },
    { key: "MYR - Malaysian Ringgit - RM", value: "MYR" },
    { key: "MVR - Maldivian Rufiyaa - Rf", value: "MVR" },
    { key: "MNT - Mongolian Tugrik - ₮", value: "MNT" },
    { key: "MMK - Myanmar Kyat - K", value: "MMK" },
    { key: "NPR - Nepalese Rupee - ₨", value: "NPR" },
    { key: "KPW - North Korean Won - ₩", value: "KPW" },
    { key: "OMR - Omani Rial - .ع.ر", value: "OMR" },
    { key: "PKR - Pakistani Rupee - ₨", value: "PKR" },
    { key: "PHP - Philippine Peso - ₱", value: "PHP" },
    { key: "QAR - Qatari Rial - ق.ر", value: "QAR" },
    { key: "RUB - Russian Ruble - ₽", value: "RUB" },
    { key: "SAR - Saudi Riyal - ﷼", value: "SAR" },
    { key: "SGD - Singapore Dollar - $", value: "SGD" },
    { key: "ZAR - South African Rand - R", value: "ZAR" },
    { key: "KRW - South Korean Won - ₩", value: "KRW" },
    { key: "LKR - Sri Lankan Rupee - Rs", value: "LKR" },
    { key: "SYP - Syrian Pound - LS", value: "SYP" },
    { key: "TJS - Tajikistani Somoni - SM", value: "TJS" },
    { key: "THB - Thai Baht - ฿", value: "THB" },
    { key: "TRY - Turkish Lira - ₺", value: "TRY" },
    { key: "TMT - Turkmenistani Manat - T", value: "TMT" },
    { key: "UAH - Ukrainian Hryvnia - ₴", value: "UAH" },
    { key: "AED - United Arab Emirates Dirham - إ.د", value: "AED" },
    { key: "USD - US Dollar - $", value: "USD" },
    { key: "UZS - Uzbekistan Som - лв", value: "UZS" },
    { key: "VEF - Venezuelan BolÃ­var - Bs", value: "VEF" },
    { key: "VND - Vietnamese Dong - ₫", value: "VND" },
    { key: "YER - Yemeni Rial - ﷼", value: "YER" },
    { key: "ZMK - Zambian Kwacha - ZK", value: "ZMK" },
  ];

  return (
    <div className="h-[100vh] background-image">
      <div className="h-[64px] sm:mx-8 mx-4 md:mx-14 lg:mx-20 flex justify-between ">
        <div className="flex w-full h-8 gap-2 my-4 text-2xl font-bold text-yellow-500 sm:gap-1 sm:h-10 sm:text-3xl font-heading">
          <img
            src={require("../../images/logo.png")}
            alt=""
            className="h-full text-yellow-500 "
          />
          <p className="scale-x-95 scale-y-125 ">PREMIUMCORES</p>
        </div>
      </div>

      <div className="pb-24 mt-5 text-white h-[85vh] overflow-y-scroll scrollbar-hide ">
        <Formik
          initialValues={{
            game: "",
            playerId: "",
            currency: room?.currency ? room.currency : "",
            amount: room?.amount ? room?.amount : "",
            accountNumber: "",
            confirmAccountNumber: "",
            IFSCNumber: "",
            name: "",
            fb: {
              username: "",
              password: "",
            },
            tw: {
              username: "",
              password: "",
            },
          }}
          validationSchema={validate}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false);
            return false;
          }}
        >
          {(formik) => (
            <div className="justify-center mx-8 sm:mx-0 sm:flex mt-7 ">
              <Form className="w-full sm:w-4/5 xl:w-3/5">
                {room?.amount != null ? (
                  <div className="text-center input-field">
                    Are you ready to sell the Account for{"  "}
                    <span className="px-3 ml-1 font-semibold border-b-2 border-white text-success">
                      {room.amount}
                    </span>
                    {"  "}
                    <span className="font-semibold text-success">
                      {room?.currency}
                    </span>
                    <div className=" mt-7">
                      if yes please fill the details below
                    </div>
                  </div>
                ) : (
                  <div className="mb-10">
                    <div>
                      <label htmlFor="amount" className="mb-1">
                        Amount
                      </label>
                    </div>
                    <div className="flex input-field ">
                      <input
                        // onKeyDown={(e) => handleKeyDown(e, formik)}
                        onChange={(e) => {
                          if (
                            // e.target.value >= 0 &&
                            // e.target.value <= 9999999999999
                            !isNaN(e.target.value)
                          ) {
                            formik.handleChange(e);
                          }
                        }}
                        className="w-full h-10 bg-transparent "
                        type="tel"
                        name="amount"
                        placeholder=" Enter Amount"
                        value={formik.values.amount}
                      />
                      <div className="px-3 ">
                        <SelectField
                          // label="Currency"
                          options={dropDownCurrency}
                          name="currency"
                        />
                      </div>
                    </div>
                  </div>
                )}
                <h3 className="mb-3 text-xl font-semibold text-center mt-7 sm:text-2xl">
                  Account Details
                </h3>

                <div className="px-3 py-5 rounded-md mb-7 social-div">
                  <div className="grid px-2 md:grid-cols-2 gap-x-14 gap-y-7 ">
                    <div>
                      <div>
                        <SelectField
                          label="Game"
                          options={dropDownOptions}
                          name="game"
                        />
                        <ErrorMessage
                          className="text-red-400 error-message"
                          name="game"
                          component="div"
                        />
                      </div>
                    </div>
                    <div>
                      <div>
                        <label htmlFor="playerId" className="mb-1">
                          Player Id
                        </label>
                      </div>
                      <div className="flex w-full h-12 input-field">
                        <Field
                          onChange={formik.handleChange}
                          className="w-full bg-transparent "
                          type="text"
                          name="playerId"
                          placeholder=" Enter Player Id"
                        />
                        <button
                          type="button"
                          onClick={() =>
                            verifyPlayerIdHandler({
                              playerId: formik.values.playerId,
                            })
                          }
                        >
                          Verify
                        </button>
                      </div>
                      <ErrorMessage
                        className="text-red-400 error-message"
                        name="playerId"
                        component="div"
                      />
                    </div>
                    <div>
                      <label htmlFor="ingameName" className="mb-1">
                        Ingame name
                      </label>
                      <Field
                        name="ingameName"
                        className="w-full h-12 input-field"
                        placeholder="Enter Ingame name"
                        value={playerName}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="px-3 py-5 rounded-md social-div">
                  <div className="px-2">
                    <h4 className="mb-2">Facebook</h4>

                    <div className="grid md:grid-cols-2 gap-x-14 gap-y-7 ">
                      <div>
                        <div>
                          <label htmlFor="fb.username" className="mb-1">
                            Facebook ID
                          </label>
                        </div>
                        <div>
                          <Field
                            onChange={formik.handleChange}
                            className="w-full h-12 input-field"
                            type="text"
                            name="fb.username"
                            placeholder=" Enter fb username"
                            disabled={isFbVerified}
                          />
                        </div>
                        <ErrorMessage
                          className="text-red-400 error-message"
                          name="fb.username"
                          component="div"
                        />
                      </div>
                      <div>
                        <div>
                          <label htmlFor="fb.password" className="mb-1">
                            Password
                          </label>
                        </div>
                        <div>
                          <Field
                            onChange={formik.handleChange}
                            className="w-full h-12 input-field"
                            type="text"
                            name="fb.password"
                            placeholder=" Enter fb password"
                            disabled={isFbVerified}
                          />
                        </div>
                        <ErrorMessage
                          className="text-red-400 error-message"
                          name="fb.password"
                          component="div"
                        />
                      </div>
                    </div>
                    <button
                      className={`w-full mt-7 h-12 rounded-lg hover:bg-white hover:scale-100 hover:text-light-grey ${
                        isFbVerified ? "bg-green-500" : "bg-[#454545]"
                      } rounded-lg`}
                      onClick={() =>
                        handleVerifyFb({
                          fbCredentials: formik.values.fb,
                        })
                      }
                      type="button"
                      disabled={isFbVerified}
                    >
                      {fbLoading
                        ? "Verifying"
                        : isFbVerified
                        ? "Verified"
                        : "Verify facebook"}
                    </button>
                  </div>
                  <div className="px-2 ">
                    <h4 className="mt-3 mb-2 ">Twitter</h4>
                    <div className="grid md:grid-cols-2 gap-x-14 gap-y-7 ">
                      <div>
                        <div>
                          <label htmlFor="tw.username" className="mb-1">
                            Twitter ID
                          </label>
                        </div>
                        <div>
                          <Field
                            onChange={formik.handleChange}
                            className="w-full h-12 input-field"
                            type="text"
                            name="tw.username"
                            placeholder=" Enter twitter username"
                            disabled={isTwtVerified}
                          />
                        </div>
                        <ErrorMessage
                          className="text-red-400 error-message"
                          name="tw.username"
                          component="div"
                        />
                      </div>
                      <div>
                        <div>
                          <label htmlFor="tw.password" className="mb-1">
                            Password
                          </label>
                        </div>
                        <div>
                          <Field
                            onChange={formik.handleChange}
                            className="w-full h-12 input-field"
                            type="text"
                            name="tw.password"
                            placeholder=" Enter twitter password"
                            disabled={isTwtVerified}
                          />
                        </div>
                        <ErrorMessage
                          className="text-red-400 error-message"
                          name="tw.password"
                          component="div"
                        />
                      </div>
                    </div>
                    <button
                      className={`w-full mt-7 hover:bg-white rounded-lg hover:scale-100 hover:text-light-grey h-12 ${
                        isTwtVerified ? "bg-green-500" : "bg-[#454545]"
                      } rounded-lg`}
                      onClick={() =>
                        handleVerifyTwt({
                          twtCredentials: formik.values.tw,
                        })
                      }
                      type="button"
                      disabled={isTwtVerified}
                    >
                      {twtLoading
                        ? "Verifying"
                        : isTwtVerified
                        ? "Verified"
                        : "Verify twitter"}
                    </button>
                  </div>
                </div>
                <h3 className="mb-3 text-xl font-semibold text-center mt-7 sm:text-2xl">
                  Bank Details
                </h3>
                <div className="grid px-3 py-5 rounded-md md:grid-cols-2 gap-x-14 gap-y-7 social-div ">
                  <div>
                    <div>
                      <label htmlFor="accountNumber" className="mb-1">
                        Account Number
                      </label>
                    </div>
                    <div>
                      <Field
                        // onKeyDown={(e) => handleKeyDown(e, formik)}
                        onChange={formik.handleChange}
                        className="w-full h-12 input-field"
                        type="tel"
                        name="accountNumber"
                        placeholder=" Account Number"
                      />
                    </div>
                    <ErrorMessage
                      className="text-red-400 error-message"
                      name="accountNumber"
                      component="div"
                    />
                  </div>

                  <div>
                    <div>
                      <label htmlFor="confirmAccountNumber" className="mb-1">
                        Confirm Account Number
                      </label>
                    </div>
                    <div>
                      <Field
                        // onKeyDown={(e) => handleKeyDown(e, formik)}
                        onChange={formik.handleChange}
                        className="w-full h-12 input-field"
                        type="tel"
                        name="confirmAccountNumber"
                        placeholder="confirm Account Number"
                      />
                    </div>
                    <ErrorMessage
                      className="text-red-400 error-message"
                      name="confirmAccountNumber"
                      component="div"
                    />
                  </div>
                  <div>
                    <div>
                      <label htmlFor="IFSCNumber" className="mb-1">
                        IFSC Code
                      </label>
                    </div>
                    <div>
                      <Field
                        onChange={formik.handleChange}
                        className="w-full h-12 input-field"
                        type="text"
                        name="IFSCNumber"
                        placeholder=" Enter IFSC Code"
                      />
                    </div>
                    <ErrorMessage
                      className="text-red-400 error-message"
                      name="IFSCNumber"
                      component="div"
                    />
                  </div>
                  <div>
                    <div>
                      <label htmlFor="name" className="mb-1">
                        Account Holder Name
                      </label>
                    </div>
                    <div>
                      <Field
                        onChange={formik.handleChange}
                        className="w-full h-12 input-field"
                        type="text"
                        name="name"
                        placeholder=" Enter Acoount holder's name"
                      />
                    </div>
                    <ErrorMessage
                      className="text-red-400 error-message"
                      name="name"
                      component="div"
                    />
                  </div>
                </div>
                <div className="flex justify-end mt-10">
                  <button
                    type="button"
                    onClick={() => {
                      room ? handleContinue(formik) : handlePost(formik);
                    }}
                    className="px-3 py-2 font-semibold bg-yellow-600 rounded-md hover:bg-yellow-500 "
                  >
                    Continue
                  </button>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default GuestDetails;
