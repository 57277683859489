import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { HiOutlineCalendar, HiOutlineClock } from "react-icons/hi";
import { MdOutlineArrowBackIos, MdOutlineClose } from "react-icons/md";
import { Link } from "react-router-dom";
import accountApi from "../../api/account";
import scheduleApi from "../../api/schedule";
import useApi from "../../hooks/useApi";
import EventCanceled from "./cards/buyer/EventCanceled";
import Payment from "./cards/buyer/Payment";
import ReSchedule from "./cards/buyer/ReSchedule";
import Approved from "./cards/seller/Approved";
import Confirmed from "./cards/seller/Confirmed";
import Waiting from "./cards/seller/Waiting";

const ScheduleEvent = ({
  date,
  setDate,
  time,
  setTime,
  datepickerRef,
  handleClickDatepickerIcon,
  scheduleData,
  AM,
  PM,
  isSeller,
  submitRescheduleHandler,
  getSchedule,
}) => {
  const [modal, setModal] = useState(false);

  const {
    data: withdrawData,
    error,
    loading,
    request: rejectSchedule,
  } = useApi(scheduleApi.withdrawSchedule);

  const {
    data: acceptInviteData,
    error: err,
    loading: acceptScheduleLoading,
    request: acceptInvite,
  } = useApi(scheduleApi.acceptSchedule);

  if (!loading && error)
    alert("Something went wrong. Couldn't reject schedule.");

  const rejectHandler = (scheduleId) => {
    rejectSchedule(scheduleId, isSeller);
    if (!error) getSchedule(isSeller);
  };

  const acceptInviteHandler = (scheduleId) => {
    acceptInvite(scheduleId);
    console.log(scheduleId);
    if (!err) getSchedule(isSeller);
  };

  // const now = new Date();
  // console.log(now);

  // let currHr = now.getHours();
  // let currMin = now.getMinutes();
  // let schdHr = scheduleData._doc.proposedSchedule.time.hours;
  // let schdMin = scheduleData._doc.proposedSchedule.time.minute;

  // // console.log(scheduleData._doc.proposedSchedule.time.AM);

  // if (scheduleData._doc.proposedSchedule.time.PM) schdHr = +12;

  // console.log(currHr);
  // console.log(schdHr);

  // console.log("Hours  ", currHr >= schdHr);

  // console.log(
  //   now.toDateString() ===
  //     new Date(scheduleData._doc.proposedSchedule.date).toDateString()
  // );
  return (
    <>
      {modal && (
        <div className="fixed top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center w-full h-screen transition duration-300 modalBackground backdrop-blur ">
          <div className="px-3 mx-3 text-white border-2 sm:mx-auto sm:px-6 schedule-div solid border-neutral-700 rounded-2xl py-7">
            <div className="flex flex-row justify-between font-semibold ">
              <p className="text-2xl sm:text-3xl">Reschedule event</p>
              <button
                type="button"
                onClick={() => {
                  setTime({ hours: null, minute: null });
                  setDate(null);
                  setModal(false);
                }}
              >
                <span className="text-2xl font-bold ">
                  <MdOutlineClose />
                </span>
              </button>
            </div>
            <div>
              <div className="flex flex-row justify-between mt-3 text-lg font-semibold sm:mt-6">
                <p>{scheduleData.game}</p>
                <p>₹{scheduleData.price}</p>
              </div>
              <p className="mt-2 text-lg font-semibold sm:mt-4">
                Account id - {scheduleData.gameId}
              </p>
              <div className="grid mt-3 sm:grid-cols-2 gap-x-7">
                <div>
                  <label htmlFor="dob" className=" text-[16px] h-4 mb-[2px]">
                    Select date
                  </label>
                  <div className="flex mt-1 input-field h-[52px] p-0">
                    <DatePicker
                      placeholderText=" Select date"
                      selected={date}
                      onChange={(date) => setDate(date)}
                      className="bg-transparent h-[52px]  w-[100%] date-picker p-2"
                      showYearDropdown
                      dateFormat="dd/MM/yyyy"
                      scrollableYearDropdown
                      yearDropdownItemNumber={50}
                      ref={datepickerRef}
                      minDate={new Date()}
                      disabledKeyboardNavigation
                      onFocus={(e) => e.target.blur()}
                    />

                    <button
                      className="p-3 text-xl"
                      type="button"
                      onClick={() => handleClickDatepickerIcon()}
                    >
                      <HiOutlineCalendar />
                    </button>
                  </div>
                </div>
                <div>
                  <p className=" text-[16px] h-4 mb-[2px] sm:px-2">
                    Select time
                  </p>
                  <div className="flex flex-row py-2 sm:p-2 gap-x-1 sm:gap-x-2">
                    <div>
                      {/* <input
                        // type="number"
                        min={1}
                        max={12}
                        name="time.hours"
                        placeholder="--"
                        className="w-12 overflow-y-hidden input-field"
                        value={time.hours}
                        onChange={(e) => {
                          if (
                            e.target.value > 12 ||
                            e.target.value < 0 ||
                            e.target.value.length > 2
                          )
                            return;
                          setTime({
                            hours: e.target.value,
                            minute: time.minute,
                            AM: time.AM,
                            PM: time.PM,
                          });
                        }}
                      /> */}
                      <select
                        name="time.hours"
                        id="time.hours"
                        className="w-12 bg-black input-field"
                        onChange={(e) =>
                          setTime({
                            hours: e.target.value,
                            minute: time.minute,
                            AM: time.AM,
                            PM: time.PM,
                          })
                        }
                      >
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                      </select>
                      <label htmlFor="time.hours" className="mx-1 ">
                        hours
                      </label>
                      {isNaN(+time.hours) && (
                        <div className="text-red-400 error-message">
                          please enter hours in range 1 to 12
                        </div>
                      )}
                      {(time.hours < 1 || time.hours > 12) &&
                        time.hours !== 0 && (
                          <div className="text-red-400 error-message">
                            please enter hours in range 1 to 12
                          </div>
                        )}
                    </div>
                    <div>
                      {/* <input
                        // type="number"
                        min={0}
                        max={59}
                        name="time.mimute"
                        placeholder="--"
                        className="w-12 input-field"
                        value={time.minute}
                        onChange={(e) => {
                          if (
                            e.target.value > 60 ||
                            e.target.value < 0 ||
                            e.target.value.length > 2
                          )
                            return;
                          setTime({
                            hours: time.hours,
                            minute: e.target.value,
                            AM: time.AM,
                            PM: time.PM,
                          });
                        }}
                      /> */}
                      <select
                        name="time.minute"
                        id="time.minute"
                        className="w-12 bg-black input-field"
                        onChange={(e) =>
                          setTime({
                            hours: time.hours,
                            minute: e.target.value,
                            AM: time.AM,
                            PM: time.PM,
                          })
                        }
                      >
                        <option value="0">00</option>
                        <option value="15">15</option>
                        <option value="30">30</option>
                        <option value="45">45</option>
                      </select>
                      <label htmlFor="time.minute" className="mx-1 ">
                        minute
                      </label>
                      {isNaN(+time.minute) && (
                        <div className="text-red-400 error-message">
                          please enter minutes in range 0 to 59
                        </div>
                      )}
                      {(time.minute < 0 || time.minute > 59) &&
                        time.minute !== 0 && (
                          <div className="text-red-400 error-message">
                            please enter minutes in range 0 to 59
                          </div>
                        )}
                    </div>
                    <div className="flex h-12 gap-2 input-field">
                      <button
                        type="button"
                        className={`${time.PM ? "" : " bg-neutral-700"}`}
                        onClick={() => {
                          setTime({
                            hours: time.hours,
                            minute: time.minute,
                            AM: true,
                            PM: false,
                          });
                        }}
                      >
                        AM
                      </button>
                      <button
                        type="button"
                        className={` ${time.PM ? " bg-neutral-700" : ""}`}
                        onClick={() => {
                          setTime({
                            hours: time.hours,
                            minute: time.minute,
                            AM: false,
                            PM: true,
                          });
                        }}
                      >
                        PM
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <p className="mt-4 text-center text-grey-text">
                purchase schedule on date{" "}
                {date !== null ? date.toLocaleDateString() : " "} and time{" "}
                {time.hours}:{time.minute < 10 && "0"}
                {time.minute} <span>{time.PM ? PM : AM}</span>
              </p>
              <div className="flex flex-col-reverse gap-3 px-1 mt-4 sm:grid sm:grid-cols-2">
                <button
                  type="button"
                  className="flex justify-center gap-2 py-3 border-2 rounded-lg solid border-neutral-700"
                  onClick={() => {
                    setTime({ hours: null, minute: null });
                    setDate(null);
                    setModal(false);
                  }}
                >
                  <span className="py-1">
                    <MdOutlineArrowBackIos />
                  </span>
                  Back
                </button>
                <button
                  type="button"
                  className="flex justify-center gap-2 py-3 bg-yellow-500 rounded-lg"
                  onClick={() => {
                    if (date === null) return alert("Please enter a date.");
                    else if (
                      time.hours === 0 ||
                      isNaN(+time.minute) ||
                      time.minute < 0 ||
                      time.minute > 59 ||
                      time.hours < 1 ||
                      time.hours > 12 ||
                      isNaN(+time.hours)
                    )
                      return alert("Please enter a time.");
                    submitRescheduleHandler(scheduleData._doc._id);
                    setModal(false);
                  }}
                >
                  Reschedule event{" "}
                  <span className="py-1 text-xl">
                    {" "}
                    <HiOutlineClock />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div>
        <div>
          {!isSeller && scheduleData._doc.status === "pending" && (
            <Waiting
              data={scheduleData}
              isSeller={isSeller}
              getSchedule={getSchedule}
            />
          )}
          {/* {scheduleData._doc.status === "approved" && (
            <Confirmed data={scheduleData} isSeller={isSeller} />
          )} */}
          {scheduleData._doc.status === "approved" && (
            <ReSchedule
              data={scheduleData}
              isSeller={isSeller}
              getSchedule={getSchedule}
            />
          )}

          {/* <Approved isSeller={isSeller} />
          <Payment isSeller={isSeller} />
          <EventCanceled isSeller={isSeller} /> */}

          {isSeller && scheduleData._doc.status === "pending" && (
            <div
              key={scheduleData._id}
              className="mb-3 duration-200 xs:mb-6 md:mb-0 sm:flex md:block lg:flex sm:flex-row schedule-div md:hover:scale-105"
            >
              <div className="w-full sm:w-1/3 md:w-full lg:w-1/3 sm:rounded-l-xl sm:rounded-r-none rounded-t-xl md:rounded-t-xl md:rounded-b-none lg:rounded-r-none lg:rounded-l-xl h-52 sm:h-auto md:h-52 lg:h-auto bg-dark-100">
                <div className="h-full">
                  <img
                    src={scheduleData._doc.Thumbnail}
                    // src={require("../../images/pubgBg.png")}
                    className="self-center w-full h-full sm:rounded-l-xl sm:rounded-r-none rounded-t-xl md:rounded-t-xl md:rounded-b-none lg:rounded-r-none lg:rounded-l-xl"
                  />
                </div>
              </div>
              <div className="p-3 sm:w-2/3 md:w-full lg:w-2/3">
                <div className="flex flex-row justify-between font-semibold ">
                  <div className="flex flex-row gap-x-2 sm:gap-x-4 ">
                    <p className="text-lg ">{scheduleData.game}</p>
                    <Link
                      to={`/schedule/account/${scheduleData._doc.gameAccountId}`}
                    >
                      <button className="px-2 font-normal rounded-full bg-neutral-700 h-fit">
                        View Details
                      </button>
                    </Link>
                  </div>
                  <p className="text-lg ">₹{scheduleData.price}</p>
                </div>
                <p className="py-2 sm:py-5">
                  XRoom event is sheduled on{" "}
                  {new Date(
                    scheduleData._doc.proposedSchedule.date
                  ).toDateString()}{" "}
                  at {scheduleData._doc.proposedSchedule.time.hours}:
                  {scheduleData._doc.proposedSchedule.time.minute < 10 && "0"}
                  {scheduleData._doc.proposedSchedule.time.minute}{" "}
                  <span>
                    {scheduleData._doc.proposedSchedule.time.PM ? "PM" : "AM"}
                  </span>{" "}
                  by Premium cores{" "}
                </p>
                <div className="grid grid-cols-2 gap-5 font-semibold">
                  <button
                    className="py-2 border-2 border-yellow-500 rounded-sm bg-neutral-700 solid px-7"
                    onClick={() => {
                      acceptInviteHandler(scheduleData._doc._id);
                      window.location.reload();
                    }}
                  >
                    Accept invite
                  </button>
                  <button
                    className="px-3 py-2 bg-yellow-500 rounded-sm"
                    type="button"
                    onClick={() => setModal(true)}
                  >
                    Reschedule event
                  </button>
                </div>
                <div className="float-right p-2 font-semibold text-error-500">
                  <button
                    className="flex flex-row gap-x-2"
                    onClick={() => rejectHandler(scheduleData._doc._id)}
                  >
                    <img src={require("../../images/Reject.png")} alt="" />{" "}
                    Reject
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ScheduleEvent;
