import React, { useEffect } from "react";
import { useState } from "react";
import { io } from "socket.io-client";

import ChatWindow from "./ChatWindow";
import useApi from "../../hooks/useApi";
import chatApi from "../../api/chat";
import Conversation from "./Conversation";
import { useRef } from "react";
import authApi from "../../api/auth";
import BidWindow from "./BidWindow";
import { useLocation } from "react-router-dom";
import Loader from "../components/Loader";

const Chat = ({ isSeller }) => {
  const [chats, setChats] = useState([]);

  // const { userData } = useContext(AuthContext);

  const [currentChat, setCurrentChat] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState();
  const [sendMessage, setSendMessage] = useState(null);
  const [receiveMessage, setReceiveMessage] = useState();
  const [sendBidProposal, setSendBidProposal] = useState(null);
  const [receiveBidProposal, setReceiveBidProposal] = useState();
  const [chatFound, setChatFound] = useState(true);

  const [showChat, setShowChat] = useState(false);

  const socket = useRef();

  const { state } = useLocation();

  const { data: userData, request: getUser } = useApi(authApi.getUser);
  const { data, error, loading, request: getChats } = useApi(chatApi.getChats);
  const {
    data: newChatData,
    error: newChatError,
    loading: newChatLoading,
    request: postNewChat,
  } = useApi(chatApi.postNewChat);

  useEffect(() => {
    getChats();
    getUser();
  }, []);

  const __DEV__ = process.env.NODE_ENV === "development";

  useEffect(() => {
    if (userData) {
      socket.current = io(
        __DEV__ ? "http://localhost:8000" : "https://pc-web-server.onrender.com"
      );
      // socket.current = io("https://pc-web-server.onrender.com");
      // socket.current.on("connect", () =>
      //   console.log("Connected to " + socket.current.id)
      // );

      socket.current.emit("new-user-add", userData._id);
      socket.current.on("get-users", (users) => {
        setOnlineUsers(users);
      });
      socket.current.on("receive-message", (data) => {
        setReceiveMessage(data);
      });
      socket.current.on("receive-bid-proposal", (data) => {
        setReceiveBidProposal(data);
      });
    }
  }, [userData]);

  // send message to socket server
  useEffect(() => {
    if (sendMessage !== null) {
      socket.current.emit("send-message", sendMessage);
    }
  }, [sendMessage]);

  // send bid to socket server
  useEffect(() => {
    if (sendBidProposal !== null) {
      socket.current.emit("propose-bid", sendBidProposal);
    }
  }, [sendBidProposal]);

  // const { data: secondUser, request: getSecondUser } = useApi(
  //   userDetailsApi.getUserDetails
  // );

  useEffect(() => {
    if (error) return console.log(data);

    // setCurrentChat(secondUser)
    // console.log(state);

    if (data && state?.receiverId) {
      const chat = data.find((chat) => {
        const selectedChat = chat.members.find((id) => id === state.receiverId);
        if (selectedChat === state.receiverId) return chat;
      });

      setCurrentChat(chat);
      setChatFound(chat);
    }

    if (data) setChats(data);
  }, [data]);

  useEffect(() => {
    if (!chatFound) {
      postNewChat(state.receiverId);
      setChatFound(true);
      // setChats([...chats, newChatData]);
    }
    if (newChatData) {
      setCurrentChat(newChatData);
      setChats([newChatData]);
    }
  }, [chatFound, newChatData]);

  // const sessionID = localStorage.getItem("sessionID");

  // useEffect(() => {
  //   if (sessionID) {
  //     this.usernameAlreadySelected = true;
  //     socket.auth = { sessionID };
  //     socket.connect();
  //   }

  //   socket.on("session", ({ sessionID, userID }) => {
  //     // attach the session ID to the next reconnection attempts
  //     socket.auth = { sessionID };
  //     // store it in the localStorage
  //     localStorage.setItem("sessionID", sessionID);
  //     // save the ID of the user
  //     socket.userID = userID;
  //   });

  //   socket.on("connect_error", (err) => {
  //     if (err.message === "invalid username") {
  //       this.usernameAlreadySelected = false;
  //     }
  //   });
  // }, []);
  const checkOnlineStatus = (chat) => {
    const chatMember = chat?.members.find((member) => member !== userData._id);

    const online = onlineUsers?.find((user) => user.userId === chatMember);

    return online ? true : false;
  };

  if (!userData) return <Loader />;

  return (
    <>
      {userData && (
        <div className=" bg-black-900">
          {/* -----------------Inbox list --------------------*/}

          <div className="relative flex-row m-3 xs:mt-8 xs:mx-8 gap-x-4 md:flex sm:mx-14 lg:mx-20 lg:gap-x-7 ">
            {(window.screen.width >= 768 || !showChat) && (
              <div className={`  xl:w-[27%] w-full md:w-[50%] messages`}>
                <div className="text-2xl chat-heading">
                  <p className="p-4 font-semibold text-white">Messages</p>
                </div>
                <div className="px-4 h-[67vh] xs:h-[70vh] overflow-y-scroll scrollbar-hide mt-7 ">
                  {chats?.map((chat, index) => {
                    return (
                      <Conversation
                        key={index}
                        data={chat}
                        setCurrentChat={setCurrentChat}
                        currentUserId={userData._id}
                        online={checkOnlineStatus(chat)}
                        setShowChat={setShowChat}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            {(showChat || window.screen.width >= 768) && currentChat ? (
              <ChatWindow
                chat={currentChat}
                setSendMessage={setSendMessage}
                receiveMessage={receiveMessage}
                currentUserId={userData._id}
                online={checkOnlineStatus(currentChat)}
                setShowchat={setShowChat}
              />
            ) : (
              <div className="w-[50%] h-[80px] hidden md:block">
                <div className="my-24 text-5xl text-center text-white ">
                  Welcome...
                </div>
              </div>
            )}
            {currentChat && state?.gameId && (
              <div className="absolute top-0 right-0 xl:relative">
                {
                  <BidWindow
                    gameId={state?.gameId}
                    chat={currentChat}
                    setSendBidProposal={setSendBidProposal}
                    receiveBidProposal={receiveBidProposal}
                    currentUserId={userData._id}
                  />
                }
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Chat;
