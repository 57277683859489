import React from "react";
import { useState } from "react";

const ValidateSocials = ({ currentStep, setCurrentStep, setShowSecure }) => {
  const [data, setData] = useState({ email: "", password: "" });
  const [revealed, setReveal] = useState(false);
  const [valTwitter, setValTwittter] = useState(false);
  return (
    <div className="font-semibold ">
      <h3 className="hidden text-2xl text-center sm:block">
        Validating social accounts
      </h3>
      <div className="xs:px-4 px-2 py-3 mt-1 overflow-y-scroll rounded sm:h-80 h-[354px] scrollbar bg-dark-700">
        <div className="p-2 rounded bg-dark-600">
          <p className="text-lg ">
            {valTwitter ? "Validate twitter" : "Validate facebook"}
          </p>
          <p className="mt-1">Get ID and password</p>
          <div className="flex-row mt-2 sm:flex gap-x-7">
            <div className="grid w-full xs:w-9/12 gap-y-2 sm:grid-cols-2 gap-x-7">
              <input
                type="email"
                name="email"
                placeholder="Email"
                className="w-full h-12 px-1 rounded xs:px-3 bg-light-grey"
                onChange={(e) => {
                  setData({ email: e.target.value, password: data.password });
                }}
                value={data.email}
              />

              <input
                type="password"
                name="password"
                placeholder="Password"
                className="w-full h-12 px-1 rounded xs:px-3 bg-light-grey"
                onChange={(e) => {
                  setData({ email: data.email, password: e.target.value });
                }}
                value={data.password}
              />
            </div>
            <button
              className={`px-4 mt-2 sm:mt-0 py-1 rounded  ${
                revealed ? "bg-light-silver px-14" : "bg-yellow-500"
              }`}
              onClick={() => {
                setReveal(!revealed);
              }}
            >
              {revealed ? "Revealed" : "Reveal ID & password"}
            </button>
          </div>

          <div className="mt-2 text-center">
            <button
              className={` px-6 py-2 rounded  ${
                revealed ? "bg-yellow-500 " : "bg-light-silver"
              }`}
              onClick={() => {
                valTwitter
                  ? setCurrentStep(currentStep + 1)
                  : setData({ email: "", password: "" });
                setValTwittter(true);
              }}
            >
              {valTwitter
                ? "Click here on validating twitter"
                : "Click here on validating facebook"}
            </button>
            {valTwitter ? (
              revealed ? (
                <div className="mt-2 text-sm font-normal">
                  <p>
                    **Try this ID password with the account you are buying. Let
                    us{" "}
                  </p>
                  <p>know the results</p>
                </div>
              ) : (
                <div className="mt-2 text-sm font-normal">
                  <p>
                    **Validate the twitter account connected to the gaming
                    account{" "}
                  </p>
                  <p> you are buying let us know the result.</p>
                </div>
              )
            ) : (
              <div className="mt-2 text-sm font-normal">
                <p>**Validate the facebook account connected to the gaming </p>
                <p>account you are buying let us know the result.</p>
              </div>
            )}
          </div>
        </div>
        <div className="mt-3 text-center ">
          {revealed ? (
            <button
              className={`py-2 mt-2 border-2 rounded px-7 solid border-error-500`}
              onClick={() => {}}
            >
              Problem logging in
            </button>
          ) : (
            <button
              className="py-2 mt-2 rounded px-14 bg-light-silver"
              type="button"
              onClick={() => {
                setCurrentStep(currentStep + 1);
              }}
            >
              Proceed
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ValidateSocials;
