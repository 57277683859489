import React from "react";
import { useField, ErrorMessage } from "formik";

const TextInput = ({ className, textarea, label, type, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <div className={`${className ? className : " mb-1 sm:mb-6"}`}>
        <label htmlFor={field.name} className="text-white ">
          {label}
        </label>
        <div className="flex flex-row input-field rounded-[2px]">
          {textarea ? (
            <textarea
              type={type ? type : "text"}
              className={`w-full bg-transparent text-[#F7F8F9] outline-none ${
                meta.error && "is-invalid"
              }`}
              {...field}
              {...props}
            />
          ) : (
            <input
              type={type ? type : "text"}
              className={`w-full bg-transparent text-[#F7F8F9] outline-none ${
                meta.error && "is-invalid"
              }`}
              {...field}
              {...props}
            />
          )}
        </div>
        <ErrorMessage
          component="div"
          name={field.name}
          className="text-red-400  error-message"
        />
      </div>
    </>
  );
};

export default TextInput;
