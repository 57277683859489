import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import useApi from "../../hooks/useApi";
// import roomApi from "../../api/room";
import { baseURL } from "../../shared/baseURL";

const BuyerPage = ({ room, updateGuest, setGuestId }) => {
  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState("");

  const navigate = useNavigate();

  // const { data: room, request: createRoom } = useApi(roomApi.createRoom);

  const __DEV__ = process.env.NODE_ENV === "development";

  const Continue = async () => {
    if (!amount) {
      return alert(" please enter amount");
    }
    if (!currency) {
      return alert(" please select currency");
    }
    const response = await fetch(baseURL + "/xroom/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ amount: amount, currency: currency }),
    });
    if (response.status !== 200) {
      alert("something went wrong !");
    }
    const room = await response.json();
    if (room && response.status === 200) {
      const link = __DEV__
        ? `http://localhost:3000/sxroom/${room._id}`
        : `https://pc-website.onrender.com/sxroom/${room._id}`;
      navigate(`/bxroom/${room._id}`, {
        state: { room: room, guest: true, link: link },
      });
    }
  };
  const dropDownOptions = [
    { key: "Select Currency", value: "" },
    { key: "AFN - Afghan Afghani - ؋", value: "AFN" },
    { key: "ALL - Albanian Lek - Lek", value: "ALL" },
    { key: "DZD - Algerian Dinar - دج", value: "DZD" },
    { key: "AOA - Angolan Kwanza - Kz", value: "AOA" },
    { key: "ARS - Argentine Peso - $", value: "ARS" },
    { key: "AMD - Armenian Dram - ֏", value: "AMD" },
    { key: "AWG - Aruban Florin - ƒ", value: "AWG" },
    { key: "AUD - Australian Dollar - $", value: "AUD" },
    { key: "AZN - Azerbaijani Manat - m", value: "AZN" },
    { key: "BSD - Bahamian Dollar - B$", value: "BSD" },
    { key: "BHD - Bahraini Dinar - .د.ب", value: "BHD" },
    { key: "BDT - Bangladeshi Taka - ৳", value: "BDT" },
    { key: "BBD - Barbadian Dollar - Bds$", value: "BBD" },
    { key: "BYR - Belarusian Ruble - Br", value: "BYR" },
    { key: "BEF - Belgian Franc - fr", value: "BEF" },
    { key: "BZD - Belize Dollar - $", value: "BZD" },
    { key: "BMD - Bermudan Dollar - $", value: "BMD" },
    { key: "BTN - Bhutanese Ngultrum - Nu.", value: "BTN" },
    { key: "GBP - British Pound Sterling - £", value: "GBP" },
    { key: "KYD - Cayman Islands Dollar - $", value: "KYD" },
    { key: "CNY - Chinese Yuan - ¥", value: "CNY" },
    { key: "CZK - Czech Republic Koruna - Kč", value: "CZK" },
    { key: "XCD - East Caribbean Dollar - $", value: "XCD" },
    { key: "EUR - Euro - €", value: "EUR" },
    { key: "GEL - Georgian Lari - ლ", value: "GEL" },
    { key: "HKD - Hong Kong Dollar - $", value: "HKD" },
    { key: "INR - Indian Rupee - ₹", value: "INR" },
    { key: "IDR - Indonesian Rupiah - Rp", value: "IDR" },
    { key: "IRR - Iranian Rial - ﷼", value: "IRR" },
    { key: "IQD - Iraqi Dinar - د.ع", value: "IQD" },
    { key: "ILS - Israeli New Sheqel - ₪", value: "ILS" },
    { key: "JPY - Japanese Yen - ¥", value: "JPY" },
    { key: "JOD - Jordanian Dinar - ا.د", value: "JOD" },
    { key: "KZT - Kazakhstani Tenge - лв", value: "KZT" },
    { key: "KWD - Kuwaiti Dinar - ك.د", value: "KWD" },
    { key: "KGS - Kyrgystani Som - лв", value: "KGS" },
    { key: "LAK - Laotian Kip - ₭", value: "LAK" },
    { key: "LBP - Lebanese Pound - £", value: "LBP" },
    { key: "MOP - Macanese Pataca - $", value: "MOP" },
    { key: "MYR - Malaysian Ringgit - RM", value: "MYR" },
    { key: "MVR - Maldivian Rufiyaa - Rf", value: "MVR" },
    { key: "MNT - Mongolian Tugrik - ₮", value: "MNT" },
    { key: "MMK - Myanmar Kyat - K", value: "MMK" },
    { key: "NPR - Nepalese Rupee - ₨", value: "NPR" },
    { key: "KPW - North Korean Won - ₩", value: "KPW" },
    { key: "OMR - Omani Rial - .ع.ر", value: "OMR" },
    { key: "PKR - Pakistani Rupee - ₨", value: "PKR" },
    { key: "PHP - Philippine Peso - ₱", value: "PHP" },
    { key: "QAR - Qatari Rial - ق.ر", value: "QAR" },
    { key: "RUB - Russian Ruble - ₽", value: "RUB" },
    { key: "SAR - Saudi Riyal - ﷼", value: "SAR" },
    { key: "SGD - Singapore Dollar - $", value: "SGD" },
    { key: "ZAR - South African Rand - R", value: "ZAR" },
    { key: "KRW - South Korean Won - ₩", value: "KRW" },
    { key: "LKR - Sri Lankan Rupee - Rs", value: "LKR" },
    { key: "SYP - Syrian Pound - LS", value: "SYP" },
    { key: "TJS - Tajikistani Somoni - SM", value: "TJS" },
    { key: "THB - Thai Baht - ฿", value: "THB" },
    { key: "TRY - Turkish Lira - ₺", value: "TRY" },
    { key: "TMT - Turkmenistani Manat - T", value: "TMT" },
    { key: "UAH - Ukrainian Hryvnia - ₴", value: "UAH" },
    { key: "AED - United Arab Emirates Dirham - إ.د", value: "AED" },
    { key: "USD - US Dollar - $", value: "USD" },
    { key: "UZS - Uzbekistan Som - лв", value: "UZS" },
    { key: "VEF - Venezuelan BolÃ­var - Bs", value: "VEF" },
    { key: "VND - Vietnamese Dong - ₫", value: "VND" },
    { key: "YER - Yemeni Rial - ﷼", value: "YER" },
    { key: "ZMK - Zambian Kwacha - ZK", value: "ZMK" },
  ];
  return (
    <div className="h-screen background-image">
      <div className="h-[64px] sm:mx-8 mx-4 md:mx-14 lg:mx-20 flex justify-between">
        <div className="flex w-full h-8 gap-2 my-4 text-2xl font-bold text-yellow-500 sm:gap-1 sm:h-10 sm:text-3xl font-heading">
          <img
            src={require("../../images/logo.png")}
            alt=""
            className="h-full text-yellow-500 "
          />
          <p className="scale-x-95 scale-y-125 ">PREMIUMCORES</p>
        </div>
      </div>

      <div className="justify-center mx-8 my-32 text-white sm:mx-0 sm:flex">
        <div className="w-full sm:w-4/5 xl:w-3/5">
          {room?.amount && (
            <div className="text-center input-field">
              Are you ready to buy the Account for{"  "}
              <span className="px-3 ml-1 font-semibold border-b-2 border-white text-success">
                {room?.amount}
              </span>
              {"  "}
              <span className="font-semibold  text-success">
                {room?.currency}
              </span>
            </div>
          )}
          {!room?.amount && (
            <div>
              <div>
                <label htmlFor="amount" className="mb-1">
                  Amount
                </label>
              </div>
              <div className="flex input-field ">
                <input
                  onChange={(e) => {
                    if (!isNaN(e.target.value)) {
                      setAmount(e.target.value);
                    }
                  }}
                  className="w-full h-8 bg-transparent "
                  type="text"
                  name="amount"
                  placeholder=" Enter Amount"
                  value={amount}
                />
                <div className="px-3 py-1 ">
                  <select
                    value={currency}
                    onChange={(e) => setCurrency(e.target.value)}
                    className="bg-dark-600"
                  >
                    {dropDownOptions.map((option) => (
                      <option value={option.value} key={option.key}>
                        {option.key}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          )}
          <div className="flex justify-end mt-7">
            <button
              type="button"
              onClick={
                room?.amount
                  ? room.guestId
                    ? () => setGuestId(room.guestId)
                    : updateGuest
                  : Continue
              }
              className="px-3 py-2 font-semibold bg-yellow-600 rounded-md hover:bg-yellow-500 "
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyerPage;
